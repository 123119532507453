import { FunctionComponent, useEffect } from 'react';
import sessionStorage from '@/core/storage/sessionStorage';
import { useHistory } from '@/core/routerLib';
import ModalClosingScreen from '../../2storybook/components/ModalClosingScreen';
import EmailSentOTPContainer from '../containers/EmailSentOTP';

const EmailSentOTP: FunctionComponent<{
  email: string;
  roomID: number;
}> = ({ email, roomID }) => {
  const history = useHistory();

  useEffect(() => {
    const isFromProvider = sessionStorage.getItem('oauthQuery') !== null;
    if (isFromProvider) {
      history.replace('/email-verification/sent');
    }
  });

  return (
    <ModalClosingScreen showCloseButton={false}>
      <EmailSentOTPContainer email={email} roomID={roomID} />
    </ModalClosingScreen>
  );
};

export default EmailSentOTP;
