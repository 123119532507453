import sessionStorage from '@/core/storage/sessionStorage';

const STORAGE_KEY_NO_AVAILABILITY = 'switchWizardNoAvailability';

interface StorageNoAvailabilityRecord {
  therapistID?: number;
}

export const storageSetNoAvailability = (therapistID: number) => {
  sessionStorage.setItem(STORAGE_KEY_NO_AVAILABILITY, JSON.stringify({ therapistID }));
};

export const storageGetNoAvailabilityTherapistID = (): number | undefined => {
  try {
    const noAvailabilityParsed: StorageNoAvailabilityRecord = JSON.parse(
      sessionStorage.getItem(STORAGE_KEY_NO_AVAILABILITY) || '{}'
    );
    return noAvailabilityParsed.therapistID;
  } catch (error) {
    return undefined;
  }
};

export const storageRemoveNoAvailability = () => {
  sessionStorage.removeItem('switchWizardNoAvailability');
};
