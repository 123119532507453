import { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import { useWizardActions, useWizardState } from 'stepWizard';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { trackWizardEvent } from '@/utils/analytics/events';
import SharingPreferencesActionView from '../../clientChat/components/SharingPreferencesAction/SharingPreferencesActionView';
import SharingPreferencesV2 from '../components/SharingPreferencesV2';
import { State } from '../reducers/switchWizardState';

interface SharingPreferencesContainerOptions
  extends RouteComponentProps<{}, {}, { isBookingSwitch?: boolean; roomID?: number }> {
  isV2: boolean;
}

const SharingPreferencesContainer: FunctionComponent<SharingPreferencesContainerOptions> = ({
  history,
  isV2,
}) => {
  const [confirmDate, setConfirmDate] = useState('');
  const [isSharing, setIsSharing] = useState<boolean | null>(true);
  const { setState, setStepResponse } = useWizardActions();
  const { roomID, clientUserID, eventCategory, responses } = useWizardState<State>();
  const DATE_FORMAT_STRING = 'MMM DD, YYYY';

  const hasSessionWithProvider = true;

  const { isBookingSwitch } = history.location.state;
  useEffect(() => {
    if (isBookingSwitch !== undefined) {
      setState({
        isBookingSwitch: !!isBookingSwitch,
        screenTitle: isBookingSwitch ? 'Booking with new provider' : 'Change provider',
      });
    } else {
      setState({
        screenTitle: 'Change provider',
      });
    }
  }, [setState, isBookingSwitch]);

  const handleOnAgreePress = () => {
    trackWizardEvent('Share Message History', eventCategory, {
      'User ID': clientUserID,
      Private: !!isSharing,
      label: 'Share Message History',
      Application: eventCategory,
      eventProperty: isSharing ? 'true' : 'false',
      eventPropertyValue: 0.0,
    });
    setState({ isMessageHistoryShared: isSharing });
    const currentStepResponses = responses;
    currentStepResponses.lastStepName = 'Sharing preferences';
    setState({
      isMessageHistoryShared: isSharing,
      responses: currentStepResponses,
    });
    setConfirmDate(moment().format(DATE_FORMAT_STRING));
    if (isV2) {
      setStepResponse({
        selectedValue: isSharing ? 'true' : 'false',
        stepName: 'Sharing preferences',
        stateUpdate: {},
        additionalData: {},
      });
      if (hasSessionWithProvider) {
        history.push('/switch-provider/switch-reason', {
          roomID,
        });
      } else if (isBookingSwitch) {
        history.push(`/switch-provider/booking/${roomID}/confirm-booking`, {
          roomID,
        });
      } else {
        history.push('/switch-provider/therapist-confirmation', {
          roomID,
        });
      }
    } else {
      history.push('/switch-provider/therapist-confirmation', {
        roomID,
      });
    }
  };

  return isV2 ? (
    <SharingPreferencesV2
      onPress={handleOnAgreePress}
      isSharing={isSharing}
      setIsSharing={setIsSharing}
    />
  ) : (
    <>
      <SharingPreferencesActionView
        isSharing={isSharing}
        setIsSharing={setIsSharing}
        confirmDate={confirmDate}
        onAgreePress={handleOnAgreePress}
        error=""
      />
    </>
  );
};

export default withRouter(SharingPreferencesContainer);
