import { useMutation, useQueryClient } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { FeatureName } from '../../types';
import { featureViewsQueryKey } from './queryKeys';

export interface MarkFeatureAsViewedVariables {
  featureName: FeatureName;
  userID: string;
}

const markFeatureAsViewed = async (params: MarkFeatureAsViewedVariables): Promise<void> => {
  const response = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/users/${params.userID}/feature-views`,
    { featureName: params.featureName }
  );
  return response.data.data;
};

const useMutationFeatureViews = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, MarkFeatureAsViewedVariables>(markFeatureAsViewed, {
    onSuccess: (_, { userID }) => {
      queryClient.refetchQueries(featureViewsQueryKey(userID));
    },
  });
};

export default useMutationFeatureViews;
