import { FunctionComponent, useCallback } from 'react';
import { useWizardState } from 'stepWizard';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import SwitchSuccess from '../components/SwitchSuccess';
import { State } from '../reducers/switchWizardState';

interface SwitchSuccessContainerProps extends RouteComponentProps {
  handleExitModal: () => void;
}

const SwitchSuccessContainer: FunctionComponent<SwitchSuccessContainerProps> = ({
  history,
  handleExitModal,
}) => {
  const { roomID } = useWizardState<State>();
  // will be set based on client usage stats when that api is fixed
  const hasSessionWithProvider = true;
  const handleOnContinuePress = useCallback(() => {
    if (hasSessionWithProvider) {
      history.push('/switch-provider/review', { roomID });
    } else {
      handleExitModal();
    }
  }, [roomID, history, hasSessionWithProvider, handleExitModal]);

  return <SwitchSuccess onPress={handleOnContinuePress} />;
};

export default withRouter(SwitchSuccessContainer);
