import { FunctionComponent, useEffect } from 'react';
import getParamByName from '../../utils/queryString';
import useEmailVerification from '../hooks/useEmailVerification';
import useVerifiedEmailRedirect from '../hooks/useVerifiedEmailRedirect';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import ValidateEmail from '../components/ValidateEmail';

const ValidateEmailContainer: FunctionComponent<RouteComponentProps<{}>> = () => {
  const tlt = getParamByName('tlt');
  const token = getParamByName('verificationCode');
  const firstNameParam = decodeURIComponent(getParamByName('firstName') || '');
  const firstName = firstNameParam && firstNameParam !== 'null' ? firstNameParam : undefined;

  const [state, { checkEmailVerificationTokenAction, sendEmailVerificationAction }] =
    useEmailVerification();

  useEffect(() => {
    if (token) {
      checkEmailVerificationTokenAction(token, tlt);
    }
  }, [checkEmailVerificationTokenAction, tlt, token]);

  useEffect(() => {
    if (state.expiredTokenEmail) {
      sendEmailVerificationAction(state.expiredTokenEmail);
    }
  }, [sendEmailVerificationAction, state.expiredTokenEmail]);

  useVerifiedEmailRedirect(state);

  return (
    <ValidateEmail
      token={token}
      emailVerifiedRedirect={state.emailVerifiedRedirect}
      isLoading={state.isLoading}
      error={state.error}
      firstName={firstName}
    />
  );
};

export default withRouter(ValidateEmailContainer);
