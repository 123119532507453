import {
  View,
  TextDS,
  Button,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import moment from 'moment';
import { PageName } from 'ts-analytics/types';
import styled from '@/core/styled';
import { trackCTAClick } from '@/utils/analytics/events';
import { MatchResultDetails } from '../../therapistMatches/matchingTypes';

const CardBottomWrapper = styled(View)<{ maxWidth?: number }>(
  ({ maxWidth, theme: { spacing, colorRoles } }) => {
    return {
      paddingTop: spacing('space200'),
      borderTop: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
      maxWidth,
      width: '90%',
    };
  }
);

const ViewTimeslotsButton = styled(Button)(
  ({
    theme: {
      spacing,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? 'auto' : 'fit-content',
      padding: `${spacing('space150', true)} ${spacing('space300', true)}`,
    };
  }
);

const formatTimeSlot = (nextAvailableTimeslot: string, t: Function) => {
  if (moment(nextAvailableTimeslot).isSame(moment().add(1, 'day'), 'day')) {
    return t('switchWizard.tomorrow', 'Tomorrow');
  }
  return moment(nextAvailableTimeslot).format('ddd, MMM D');
};

interface StickyFooterButtonProps {
  nextAvailable?: string;
  isMessaging?: boolean;
  isBooking?: boolean;
  onPress?: () => void;
  details?: MatchResultDetails;
  pageName: PageName;
  maxWidth?: number;
}

const StickyFooterButton = ({
  nextAvailable,
  isMessaging,
  isBooking,
  onPress,
  details,
  pageName,
  maxWidth,
}: StickyFooterButtonProps) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { spacing } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();

  return (
    <CardBottomWrapper maxWidth={maxWidth} flex={1}>
      <View row justify="space-between" flex={1} style={{ gap: spacing('space100') }}>
        {isMessaging && !isMobile && <View flex={1} justify="center" />}
        {!isMessaging && !!nextAvailable && (
          <View justify="center">
            <TextDS variant="headingSm">
              {tSwitchWizard('MatchResults.nextAvailable', 'Next available')}
            </TextDS>
            <TextDS variant="bodySm">{formatTimeSlot(nextAvailable, tSwitchWizard)}</TextDS>
          </View>
        )}
        {!isMessaging && !nextAvailable && (
          <View justify="center" style={{ width: '50%' }}>
            <TextDS variant="headingSm">
              {tSwitchWizard('MatchResults.scheduleUnavailable', 'Schedule unavailable')}
            </TextDS>
            <TextDS variant="bodySm">
              {tSwitchWizard(
                'MatchResults.chooseNowBookLater',
                'Choose provider now and book with them later'
              )}
            </TextDS>
          </View>
        )}
        <View flex={1} justify={isMobile ? undefined : 'end'} align={isMobile ? undefined : 'end'}>
          {isMessaging && (
            <ViewTimeslotsButton
              onPress={() => {
                trackCTAClick('Continue with', pageName, { therapistID: details?.id });
                onPress?.();
              }}
              text={tSwitchWizard(
                'MatchResults.continueWith',
                `Continue with ${details?.firstName}`,
                {
                  firstName: details?.firstName,
                }
              )}
              dataQa="TherapistMatchDirectoryCardButton"
            />
          )}
          {!isMessaging && isBooking && nextAvailable && (
            <ViewTimeslotsButton
              onPress={() => {
                trackCTAClick('View Availability', pageName, { therapistID: details?.id });
                onPress?.();
              }}
              text={tSwitchWizard('MatchResults.viewAvailability', 'View Availability')}
              dataQa="TherapistMatchDirectoryCardButton"
            />
          )}
          {!isMessaging && (!isBooking || !nextAvailable) && (
            <ViewTimeslotsButton
              onPress={() => {
                trackCTAClick('Continue', pageName, { therapistID: details?.id });
                onPress?.();
              }}
              text={tSwitchWizard('MatchResults.continue', 'Continue')}
              dataQa="TherapistMatchDirectoryCardButton"
            />
          )}
        </View>
      </View>
    </CardBottomWrapper>
  );
};

export default StickyFooterButton;
