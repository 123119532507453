import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';
import { featureViewsQueryKey } from './queryKeys';
import { FeatureName } from '../../types/FeatureViews';

type SeenAt = Date;

export type UserFeatureViews = Record<FeatureName, SeenAt[]>;

const fetchFeatureViews = (userID: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/users/${userID}/feature-views`
  );
  return data.data;
};

const useQueryFeatureViews = (userID: string) =>
  useQuery<UserFeatureViews, Error>({
    queryFn: fetchFeatureViews(userID),
    enabled: !!userID,
    queryKey: featureViewsQueryKey(userID),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryFeatureViews;
