import { useQuery } from 'react-query';

import { Availability } from '../entities/Therapist';
import { BusinessDays, Expertise, Info, License } from '../types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { therapistDetailsQueryKey } from './queryKeys';

export interface TherapistDetailsResponse {
  additionalLanguages?: string[];
  availability: Availability;
  bestMatch: boolean;
  businessDays: BusinessDays;
  expertise: Expertise;
  id: number;
  imageURL: string;
  info: Info;
  licenses: License[];
  videoURL: string;
  therapistId: number;
}

const fetchTherapistDetails = (therapistID?: number | string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/info`
  );
  return data.data;
};

const useQueryTherapistDetails = (therapistID?: number | string) =>
  useQuery<TherapistDetailsResponse, Error>({
    queryKey: therapistDetailsQueryKey(therapistID),
    queryFn: fetchTherapistDetails(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(therapistID),
  });

export default useQueryTherapistDetails;
