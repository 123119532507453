import { useEffect } from 'react';
import { isApple, isAndroid } from 'ts-frontend/utils/device';
import { getFirebaseDeepLink, getUniversalDeepLink } from '@/utils/deeplinksHelper';
import { trackEmailVerifiedEventWithOTP } from '@/utils/analytics/events';
import { setTrackerUserID } from '@/utils/analytics/eventTracker';
import { useHistory } from '@/core/routerLib';
import { State } from '../reducers/emailVerification';

interface UseVerifiedEmailRedirectOptions {
  disabled: boolean;
  usedOTP: boolean;
}

const useVerifiedEmailRedirect = (
  state: State,
  options: UseVerifiedEmailRedirectOptions = { disabled: false, usedOTP: false }
) => {
  const history = useHistory();
  const { disabled, usedOTP } = options;
  const { location } = history;
  const { emailSent, emailVerifiedRedirect, expiredTokenEmail, skipDeeplink, userID } = state;
  useEffect(() => {
    let timeout;
    if (!disabled && emailVerifiedRedirect) {
      if (userID) {
        setTrackerUserID(userID).then(() => trackEmailVerifiedEventWithOTP({ usedOTP }));
      }

      timeout = setTimeout(() => {
        if ((isAndroid || isApple) && !skipDeeplink) {
          // we need to construct the deeplink manually and redirect the browser in order to trigger the app
          // this is because the tlt token returned by the api cannot be configured in the firebase dashboard
          const redirectDeeplink = isApple
            ? getUniversalDeepLink(emailVerifiedRedirect)
            : getFirebaseDeepLink(emailVerifiedRedirect);
          window.location.href = redirectDeeplink;
        } else {
          window.location.href = emailVerifiedRedirect;
        }
      }, 3200);
    }

    if (!disabled && emailSent && expiredTokenEmail) {
      history.push(`/email-verification/expired#email=${expiredTokenEmail}`);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    history,
    location.hash,
    emailSent,
    emailVerifiedRedirect,
    expiredTokenEmail,
    skipDeeplink,
    userID,
    disabled,
    usedOTP,
  ]);
};

export default useVerifiedEmailRedirect;
