import { ThemedFlagsProvider } from 'launchDarkly';
import { Route, Switch } from '../core/routerLib';
import EmailSent from './screens/EmailSent';
import EmailSentOTP from './screens/EmailSentOTP';
import LinkExpired from './screens/LinkExpired';
import ValidateEmail from './screens/ValidateEmail';
import ChangeEmail from './screens/ChangeEmail';

const Routes = () => (
  <ThemedFlagsProvider versionKey="emailVerificationOtp">
    <Switch>
      <Route path="/email-verification/expired" component={LinkExpired} />
      <Route path="/email-verification/sent" component={EmailSent} />
      <Route path="/email-verification/otp" component={EmailSentOTP} />
      <Route path="/email-verification/change-email" component={ChangeEmail} />
      <Route path="/email-verification" component={ValidateEmail} />
    </Switch>
  </ThemedFlagsProvider>
);

export default Routes;
