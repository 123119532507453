import { EmotionThemeProvider, useWindowWidthState, View } from '@talkspace/react-toolkit';
import {
  SelectCredit,
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from 'inRoomScheduling';
import { useCallback, useEffect } from 'react';
import { useWizardActions } from 'stepWizard';
import { VideoCreditOffer } from 'ts-frontend/types/InRoomScheduling';
import { useCloseModal } from '@/utils/ModalsContextProvider';

const SelectSessionLength = () => {
  const { isMobile } = useWindowWidthState();
  const { creditOptions } = useInRoomSchedulingState();
  const { dispatchSetSelectedBookingDuration, dispatchSetSelectedCreditOption } =
    useInRoomSchedulingActions();

  const { setStepValue, setDefaultStepValue } = useWizardActions();
  const closeModal = useCloseModal();

  const handleChooseCredit = useCallback(
    (selectedCredit: VideoCreditOffer) => {
      const bookingDuration = selectedCredit.creditMinutes;
      dispatchSetSelectedBookingDuration(bookingDuration);
      dispatchSetSelectedCreditOption(selectedCredit);

      setStepValue(selectedCredit.displayName);
    },
    [dispatchSetSelectedBookingDuration, dispatchSetSelectedCreditOption, setStepValue]
  );

  useEffect(() => {
    if (creditOptions && creditOptions.length) {
      setDefaultStepValue<string>(creditOptions[0].displayName);
      dispatchSetSelectedBookingDuration(creditOptions[0].creditMinutes);
      dispatchSetSelectedCreditOption(creditOptions[0]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <EmotionThemeProvider version="1.0.0">
      <View flex={1} align="center" justify="center">
        <View align="stretch" style={isMobile ? { paddingLeft: 20, paddingRight: 20 } : undefined}>
          <SelectCredit
            onClose={closeModal}
            handleChooseCredit={handleChooseCredit}
            onSkipBookNextSession={() => {}}
            isTherapist={false}
            isFromPostLVSCheckInWizard={false}
            isFromCheckInWizard={false}
            shouldRenderSelectCredit
            shouldHideHeading
            shouldHideButtonContainer
            shouldHidePriceText
          />
        </View>
      </View>
    </EmotionThemeProvider>
  );
};

export default SelectSessionLength;
