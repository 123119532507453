import { useFlags } from 'launchDarkly/FlagsProvider';

import SessionsLeftBanner from './SessionsLeftBanner';

const AnnouncementsBanner = ({ userID, isMobile }) => {
  const { eapToBhActivationFf } = useFlags();

  if (!eapToBhActivationFf || !eapToBhActivationFf.variant) {
    return null;
  }

  return <SessionsLeftBanner isMobile={isMobile} userID={userID} />;
};

export default AnnouncementsBanner;
