import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import * as React from 'react';
import {
  View,
  Grid,
  Row,
  Col,
  Card,
  TextDS,
  spacing,
  languagesList,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import SearchSpyglass from '@talkspace/react-toolkit/src/designSystems/illustrations/SearchSpyglass';
import { ClientMatchPresentingProblem, SessionModality } from 'ts-frontend/types';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { MatchPreferenceReason } from '../../../types';
import FullscreenWrapper from '../../../components/FullscreenWrapper';
import TherapistMatchDirectoryCard from '../../../components/TherapistMatchDirectoryCard';
import useMatchResults from '../../hooks/useMatchResults';
import { MatchResult, MatchPayload } from '../../matchingTypes';
import { trackEvent } from '../../../utils/analytics/eventTracker';
import { trackCTAClick, trackPageView, trackWizardEvent } from '@/utils/analytics/events';
import OtherAvailableProviders from '../OtherAvailableProviders';
import TherapistDetails from '../../../containers/TherapistDetails';

const getIDForLanguage = (langName: string): number | null => {
  const foundOption = languagesList.find(
    (langOption) => langOption.name.toLowerCase() === langName.toLowerCase()
  );
  return foundOption ? foundOption.id : null;
};

const getNameForLanguageID = (langID: number): string | null => {
  const foundOption = languagesList.find((langOption) => langOption.id === langID);
  return foundOption ? foundOption.name : null;
};

const getIsGenderMatch = (genderString: string, genderNumber: number) =>
  (genderString === 'male' && genderNumber === 1) ||
  (genderString === 'female' && genderNumber === 2);

const getMatchReasons = ({
  matchPayload,
  therapistMatch,
  selectedPresentingProblemsAsExpertise,
}: {
  matchPayload: MatchPayload;
  therapistMatch?: MatchResult | null;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
}): MatchPreferenceReason[] => {
  if (!therapistMatch) {
    return [];
  }

  const matchReasons: MatchPreferenceReason[] = [];

  const matchedExpertise = selectedPresentingProblemsAsExpertise.filter((pProblem) =>
    therapistMatch.details.allFieldsOfExpertise.find(
      (expertise) => expertise === pProblem.expertiseName
    )
  );

  if (matchedExpertise.length) {
    const uniqueExpNames = [
      ...new Set(matchedExpertise.slice(0, 3).map((exp) => exp.expertiseName)),
    ];
    matchReasons.push({
      value: uniqueExpNames.join(', '),
      matchPreferenceName: 'presentingProblem',
    });
  }

  if (matchPayload.languages?.length || matchPayload.languagesSoft?.length) {
    const allRequestedLangs = (matchPayload.languages || []).concat(
      matchPayload.languagesSoft || []
    );
    const providerLangIDs = therapistMatch.details.additionalLanguages
      .map(getIDForLanguage)
      .filter(Boolean);
    const matchedLangs = allRequestedLangs.filter((langID) => providerLangIDs.includes(langID));
    if (matchedLangs.length) {
      const matchedLangNames = matchedLangs.map(getNameForLanguageID).filter(Boolean);
      matchReasons.push({ value: matchedLangNames.join(', '), matchPreferenceName: 'language' });
    }
  }
  if (
    matchPayload.therapistGender &&
    getIsGenderMatch(matchPayload.therapistGender, therapistMatch.details.gender)
  ) {
    matchReasons.push({
      value: therapistMatch.details.gender === 1 ? 'Male' : 'Female',
      matchPreferenceName: 'gender',
    });
  }
  if (therapistMatch.details.yearsExperience > 2) {
    matchReasons.push({
      value: `${therapistMatch.details.yearsExperience} years`,
      matchPreferenceName: 'yearsOfExperience',
    });
  }

  if (matchReasons.length === 0) {
    const expertiseReasons: MatchPreferenceReason[] = therapistMatch.details.expertise.mostRelevant
      .slice(0, 3)
      .map((expertise) => {
        return {
          value: expertise,
          matchPreferenceName: 'presentingProblem',
        };
      });
    expertiseReasons.forEach((reason) => matchReasons.push(reason));
  }

  return matchReasons;
};

const getAnalyticsEventProperties = (matchResults?: MatchResult[]) => {
  if (matchResults && matchResults.length > 0) {
    return {
      matchesFound: 'true',
      therapistIDs: matchResults.map((result) => `${result.details.id}`).join(','),
    };
  }
  return {
    matchesFound: 'false',
    therapistIDs: '',
  };
};

interface MatchResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  roomID: number;
  matchPayload: MatchPayload;
  selectedPresentingProblemAsString: string;
  selectedPresentingProblemsAsExpertise: ClientMatchPresentingProblem[];
  skipPayment: boolean;
  handleSwitchWizardSelectedTherapist?: (
    therapistID: number,
    therapistFirstName: string,
    therapistImage: string,
    therapistType: TherapistType,
    noAvailability?: boolean
  ) => void;
  eventCategory?: string;
  clientUserID?: number;
  formerTherapistID?: number;
  isB2B?: boolean;
  isBooking?: boolean;
  modality?: SessionModality;
  setScreenTitle?: (title: string) => void;
}

const MatchResultsV2: FunctionComponent<MatchResultsProps> = ({
  roomID,
  matchPayload,
  selectedPresentingProblemAsString,
  selectedPresentingProblemsAsExpertise,
  skipPayment,
  handleSwitchWizardSelectedTherapist,
  eventCategory,
  clientUserID,
  formerTherapistID,
  isB2B,
  isBooking,
  setScreenTitle,
  modality,
  ...otherProps
}) => {
  const { matchResultsState, setMatchResultsState } = useMatchResults();
  const [showList, setShowList] = useState(false);
  const [providerId, setProviderId] = useState(-Infinity);
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { isProviderProfileOpen } = matchResultsState;

  const matchCount = matchResultsState?.matchResults?.length;
  const matchCountAvailble = matchResultsState?.matchResults?.filter(
    (match) => !!match.details.nextAvailableTimeslot
  ).length;
  const isMessaging = modality === 'messaging';

  useEffect(() => {
    if (showList && setScreenTitle) {
      setScreenTitle('Available providers');
    }
  }, [setScreenTitle, showList]);

  useEffect(() => {
    trackEvent('Meet Matched Therapists', {
      eventCategory: 'Reactivation',
      eventCategoryKey: 'Funnel Name',
    });
  }, []);

  useEffect(() => {
    const eventProps = {
      roomID,
      numberOfProvidersShown: matchCount || -1,
      providersWithAvailability: matchCountAvailble || -1,
    };
    if (showList) {
      trackPageView('available-providers', eventProps);
    } else {
      trackPageView('meet-recommended-match', eventProps);
    }
  }, [showList, matchCount, matchCountAvailble, roomID]);

  useEffect(() => {
    if (eventCategory === 'Self Serve Switch') {
      const eventProps = getAnalyticsEventProperties(matchResultsState.matchResults);
      trackWizardEvent('Meet Matched Therapists', eventCategory, {
        'Match Found': eventProps.matchesFound,
        'Therapist ID': eventProps.therapistIDs,
        'User Id': clientUserID ? clientUserID.toString() : '',
        label: 'Matches Shown',
        Application: eventCategory,
        eventProperty: eventProps.therapistIDs,
        eventPropertyValue: formerTherapistID || 0.0,
      });
    }
  }, [clientUserID, formerTherapistID, matchResultsState.matchResults, eventCategory]);
  const topMatch = matchResultsState?.matchResults?.length
    ? matchResultsState?.matchResults[0]
    : null;

  const onPressMoreMatches = useCallback(() => {
    trackCTAClick('See all', 'meet-recommended-match');
    setShowList(true);
  }, []);

  const toggleProviderProfile = useCallback(
    () => setMatchResultsState({ isProviderProfileOpen: !isProviderProfileOpen }),
    [isProviderProfileOpen, setMatchResultsState]
  );

  if (!matchResultsState?.matchResults || !matchResultsState?.matchResults?.length) {
    return null;
  }

  const showMoreProviders = matchResultsState?.matchResults?.length > 1;
  const matchResultObj = matchResultsState?.matchResults.find(
    (matchResult) => matchResult.userId === providerId
  ) as MatchResult;

  return isProviderProfileOpen ? (
    <TherapistDetails
      toggleProviderProfile={toggleProviderProfile}
      isChangeTherapistFlow={isProviderProfileOpen}
      isChatHidden
      isMobile={false}
      isModal
      providerId={providerId}
      therapistType={matchResultObj.details.therapistType}
      isMessaging={isMessaging}
      therapistDetailsProps={{
        nextAvailable: matchResultObj.details.nextAvailableTimeslot,
        isBooking,
        onPress: () => {
          if (handleSwitchWizardSelectedTherapist) {
            handleSwitchWizardSelectedTherapist(
              matchResultObj.userId,
              matchResultObj.details.firstName,
              matchResultObj.details.image || '',
              matchResultObj.details.therapistType,
              !matchResultObj.details.nextAvailableTimeslot
            );
          }
          if (isProviderProfileOpen) {
            toggleProviderProfile();
          }
        },
        details: matchResultObj.details,
      }}
    />
  ) : (
    <FullscreenWrapper
      removeMargin
      style={{
        justifyContent: 'start',
        marginBottom: 60,
        marginTop: 13,
        width: '100%',
      }}
      {...otherProps}
    >
      <Grid>
        {topMatch && !showList && (
          <Row style={{ paddingBottom: spacing.space300 }}>
            <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
              <TherapistMatchDirectoryCard
                matchResult={topMatch}
                matchPreferenceReasons={getMatchReasons({
                  matchPayload,
                  therapistMatch: topMatch,
                  selectedPresentingProblemsAsExpertise,
                })}
                peopleHelped={0}
                topSpecialties={topMatch.details.expertise.mostRelevant}
                nextAvailable={topMatch.details.nextAvailableTimeslot}
                isRecommended
                isHighlighted
                isMessaging={isMessaging}
                isBooking={isBooking}
                onPress={() => {
                  if (handleSwitchWizardSelectedTherapist) {
                    handleSwitchWizardSelectedTherapist(
                      topMatch.userId,
                      topMatch.details.firstName,
                      topMatch.details.image || '',
                      topMatch.details.therapistType,
                      !topMatch.details.nextAvailableTimeslot
                    );
                  }
                  if (isProviderProfileOpen) {
                    toggleProviderProfile();
                  }
                }}
                toggleProviderProfile={toggleProviderProfile}
                setProviderId={setProviderId}
                pageName="meet-recommended-match"
              />
            </Col>
          </Row>
        )}

        {!showList && showMoreProviders && (
          <Row style={{ paddingBottom: spacing.space300 }}>
            <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
              <OtherAvailableProviders
                matchResults={matchResultsState.matchResults}
                onPress={onPressMoreMatches}
              />
            </Col>
          </Row>
        )}

        {showList &&
          matchResultsState.matchResults.map((matchResult, index) => (
            <Row style={{ paddingBottom: spacing.space300 }}>
              <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
                <TherapistMatchDirectoryCard
                  matchResult={matchResult}
                  matchPreferenceReasons={getMatchReasons({
                    matchPayload,
                    therapistMatch: matchResult,
                    selectedPresentingProblemsAsExpertise,
                  })}
                  peopleHelped={0}
                  isRecommended={index === 0}
                  topSpecialties={matchResult.details.expertise.mostRelevant}
                  nextAvailable={matchResult.details.nextAvailableTimeslot}
                  isBooking={isBooking}
                  isMessaging={isMessaging}
                  onPress={() => {
                    if (handleSwitchWizardSelectedTherapist) {
                      handleSwitchWizardSelectedTherapist(
                        matchResult.userId,
                        matchResult.details.firstName,
                        matchResult.details.image || '',
                        matchResult.details.therapistType,
                        !matchResult.details.nextAvailableTimeslot
                      );
                    }
                    if (isProviderProfileOpen) {
                      toggleProviderProfile();
                    }
                  }}
                  isMoreMatchesView={showList}
                  toggleProviderProfile={toggleProviderProfile}
                  setProviderId={setProviderId}
                  pageName="available-providers"
                />
              </Col>
            </Row>
          ))}
        <Row>
          <Col xs={4} sm={8} md={8} lgOffset={2} lg={8} xlOffset={2} xl={8}>
            <Card surfaceRoleVariantName="subtle" variant="noBorder" isDisabled>
              <View align="center" justify="center">
                <View>
                  <SearchSpyglass />
                </View>
                <TextDS variant="headingMd">
                  {tSwitchWizard('MatchResults.notSure', 'Not sure you’ve found the right fit?')}
                </TextDS>
                <TextDS variant="bodySm" style={{ textAlign: 'center' }}>
                  {tSwitchWizard(
                    'MatchResults.bestWayToKnow',
                    'The best way to know is by trying a session. We make it easy to change therapists at any time.'
                  )}
                </TextDS>
              </View>
            </Card>
          </Col>
        </Row>
      </Grid>
    </FullscreenWrapper>
  );
};

export default MatchResultsV2;
