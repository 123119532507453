import { OptionType, shuffleArray } from '@talkspace/react-toolkit';
import { therapistGenderPreference, ethnicity, switchReasons } from '@talkspace/configs';
import { ValueType } from 'react-select';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';
import { TherapistTimeslot } from 'ts-frontend/types';
import { MatchingPreferences } from '@/entities/MatchingPreferences';
import { MatchResult } from '../../therapistMatches/matchingTypes';
import { TherapistInfo } from '../../therapistReview/utils/therapistReviewApiHelper';

export type PreferredLanguageFilterType = 'languages' | 'languagesSoft';

export interface State extends BaseWizardState {
  isV2: boolean;
  roomID: number;
  clientUserID: number;
  switchReason: string;
  newTherapistFocus: number[];
  countryState?: string;
  therapistGender?: string;
  therapistEthnicity?: string;
  newTherapistMatch: {
    therapistID: number;
    therapistFirstName: string;
    therapistImage: string;
    therapistType: string;
  } | null;
  informedConsentGiven: boolean;
  isMessageHistoryShared: boolean;

  // we need this so we don't override existing preferences (when sending back)
  // which we don't allow editing in the wizard - such as planGroup
  existingPreferences?: MatchingPreferences;

  // input values
  therapistGenderOptions: ValueType<OptionType, true>;
  ethnicityOptions: ValueType<OptionType, true>;
  presentingProblemsOptions: ValueType<OptionType<number>, true>;
  reasonForSwitch: ValueType<OptionType, true>;
  displayTherapistReview: boolean;
  displayReasonsForSwitching: boolean;
  eventCategory: string;
  preferredLanguage: number | null;
  preferredLanguageOptions: ValueType<OptionType, true>;
  preferredLanguageFilter: PreferredLanguageFilterType | null;
  preferredLanguageFilterOptions: ValueType<OptionType<PreferredLanguageFilterType>, true>;
  displayPreferredLanguageFilter: boolean;
  timeslots?: TherapistTimeslot[];
  matches?: MatchResult[];
  isLiveModality: null | boolean;
  isBookingSwitch: boolean;
  therapistInfo?: TherapistInfo;
  screenTitle: string;
  setDisplayPreferredLanguageFilter?: (value: string, context: any) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const getInitialState = ({
  clientUserID,
  roomID,
  source,
  contextID,
  isV2,
}: {
  clientUserID: number;
  roomID: number;
  source: string;
  contextID: string;
  isV2: boolean;
}): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    isV2,
    roomID,
    clientUserID,
    switchReason: '',
    newTherapistFocus: [],
    countryState: 'US',
    therapistGender: undefined,
    therapistEthnicity: undefined,
    newTherapistMatch: null,
    informedConsentGiven: false,
    isMessageHistoryShared: false,
    existingPreferences: undefined,
    therapistGenderOptions: keyValueToValueType(therapistGenderPreference).filter((option) => {
      const castOption = option as OptionType;
      return castOption && castOption.label !== 'No preference';
    }),
    ethnicityOptions: keyValueToValueType(ethnicity),
    reasonForSwitch: shuffleArray(keyValueToValueType(switchReasons)),
    presentingProblemsOptions: [],
    displayTherapistReview: ![
      'cancellation',
      'b2b-cancellation',
      'cancellation-v2',
      'b2b-cancellation-v2',
    ].includes(source),
    displayReasonsForSwitching: !['cancellation-v2', 'b2b-cancellation-v2'].includes(source),
    eventCategory: 'Self Serve Switch',
    preferredLanguage: 15,
    preferredLanguageOptions: [],
    preferredLanguageFilter: null,
    preferredLanguageFilterOptions: [
      {
        value: 'languages',
        label: 'Yes, I’ll wait for my preferred language',
      },
      {
        label: 'No, I want to connect with a provider sooner, even if they speak English',
        value: 'languagesSoft',
      },
    ],
    displayPreferredLanguageFilter: false,
    isLiveModality: true, // TODO when loading preferences, if user prefers messaging set this to false
    isBookingSwitch: false,
    screenTitle: 'Change provider',
    responsesManualOrder: [
      'Focus on',
      'State country',
      'Therapist gender',
      'Therapist language',
      'Therapist language filter type',
      'Select Modality',
      'Select Session Length',
      'Sharing preferences',
      'Reason for switching',
    ],
  };
};
