import { Currency, SessionModality, VideoCreditOffer, TimeslotByDay } from 'ts-frontend/types';
import { Preferences, NotificationType } from 'ts-frontend/types/Notifications';
import {
  getVisitTherapistSchedulerTrackingData,
  mixpanelSessionModalities,
  ScheduledBy,
} from 'ts-analytics/mixpanel/helpers';
import {
  trackCTAClick as sharedTrackCTAClick,
  TrackCTAClickOptions,
} from 'ts-analytics/mixpanel/events';
import { PageName } from 'ts-analytics/types';

import { getGDPRProps } from 'ts-frontend/helpers/gdpr';
import {
  FunnelName,
  EventNameTypes,
  UserSettingsEventCTA,
  UserSettingsEventSource,
} from './trackerTypes';
import { trackEvent as internalTrackEvent, setAlias, VWO, setPeople } from './eventTracker';
import { getPlatform } from './helpers';
import anonymizePath from './anonymizePath';

export interface RegisterSendToTrackers {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  userID: number;
  roomID: number;
  therapistID: number;
  roomFunnelVariation: string;
  /**
   * Only optional when the registration was done
   * through this React App
   */
  registrationFullUrl?: string;
}

export interface AnalyticsData {
  sendToTrackers: RegisterSendToTrackers;
}

export const trackAliasUserEvent = (userID: string) => {
  setAlias(userID, ['mixpanel']);
};

export const trackRegisterUserEvent = ({
  roomID,
  therapistID,
  roomFunnelVariation,
  registrationFullUrl = window.location.origin,
  ...otherData
}: RegisterSendToTrackers) => {
  internalTrackEvent(
    'Register',
    {
      eventCategory: 'Registration',
      eventProperty: 'room_funnel_variation',
      eventPropertyValue: roomFunnelVariation,
      room_id: roomID,
      therapist_id: therapistID,
      registration_full_url: registrationFullUrl,
      ...otherData,
    },
    ['mixpanel']
  );

  VWO.trackRegisterGoal();
};

// eslint-disable-next-line import/prefer-default-export
export const trackPurchaseEvent = (
  roomID: number,
  planID: number,
  offerID: number,
  requestedOfferID: number,
  eventCategory: string,
  currency: string,
  discountPercent: number,
  revenue: number,
  price: number,
  planDisplayName: string,
  billingCycle: number,
  isTestUser: boolean,
  couponCode?: string,
  partner?: string,
  experiment?: object,
  experimentID?: string,
  isFirstPurchase = false,
  successful = true,
  errors?: string[]
) => {
  let action: EventNameTypes = 'Complete Purchase';

  if (successful === false) {
    // Only Mixpanel reports failed purchase events
    action = 'Failed to Purchase';
    internalTrackEvent(
      action,
      {
        eventCategory,
        eventCategoryKey: 'Funnel Name',
        eventProperty: 'planId',
        eventPropertyValue: planID,
        offerId: offerID,
        requestedOfferID,
        partner: partner || null,
        'Subscription Plan': planDisplayName,
        'Subscription Value': price,
        'Billing Frequency': billingCycle,
        'Discount %': discountPercent,
        'Promo Code': couponCode,
        'Promo Value': price - revenue,
        'Error Codes': errors || [],
        isFirstPurchase,
        isTestUser,
        Successful: successful,
        Platform: getPlatform(),
        experiment: experiment || null,
        experimentID: experimentID || null,
        type: 'B2C',
        Currency: currency,
      },
      ['mixpanel']
    );
    return;
  }
  VWO.trackPurchaseGoal();
};

export const trackPlanSelectedEvent = (
  eventCategory: string,
  planDisplayName: string,
  price: number,
  billingCycle: number,
  discountPercent: number,
  experiment?: object,
  experimentID?: string
) => {
  internalTrackEvent(
    'Select Subscription Plan',
    {
      eventCategory,
      eventCategoryKey: 'Funnel Name',
      'Subscription Plan': planDisplayName,
      'Subscription Value ($)': price,
      'Billing Frequency': billingCycle,
      'Discount %': discountPercent,
      Platform: getPlatform(),
      experiment: experiment || null,
      experimentID: experimentID || null,
    },
    ['mixpanel']
  );
};

export const trackSeeAvailableOffersEvent = (
  eventCategory: string,
  offerID: number,
  requestedOfferID: number,
  currency: Currency,
  planPrices: string[],
  topMostPlanPrice: string,
  Successful: boolean,
  errorMessage?: string,
  experimentID?: string,
  experiment?: object
) => {
  internalTrackEvent(
    'See Available Offers',
    {
      eventCategory,
      eventCategoryKey: 'Funnel Name',
      offerID,
      Successful,
      Currency: currency,
      'Plan Prices': planPrices,
      'Top Most Plan Price': topMostPlanPrice,
      errorMessage: errorMessage || null,
      experimentID: experimentID || null,
      experiment: experiment || null,
      requestedOfferID,
    },
    ['mixpanel']
  );
};

export const trackPlanExpendEvent = (
  eventCategory: string,
  offerID: number,
  planDisplayName: string
) => {
  internalTrackEvent(
    'See Available Billing Frequency',
    {
      eventCategory,
      eventCategoryKey: 'Funnel Name',
      offerID,
      'Subscription Plan': planDisplayName,
    },
    ['mixpanel']
  );
};

export const trackContinueToCheckout = (
  eventCategory: string,
  planDisplayName: string,
  price: number,
  billingCycle: number,
  discountPercent: number,
  experiment?: object,
  experimentID?: string
) => {
  internalTrackEvent(
    'Continue To Checkout',
    {
      eventCategory,
      eventCategoryKey: 'Funnel Name',
      'Subscription Plan': planDisplayName,
      'Subscription Value ($)': price,
      'Billing Frequency': billingCycle,
      'Discount %': discountPercent,
      Platform: getPlatform(),
      experiment: experiment || null,
      experimentID: experimentID || null,
    },
    ['mixpanel']
  );
};

export const trackTalkspacePageView = (
  pageName: string,
  funnelName: string,
  eventProperties = {},
  anonymousUser = false
) => {
  const { isGDPR } = getGDPRProps();
  internalTrackEvent(
    'Talkspace Page View',
    {
      Page: pageName,
      eventCategory: funnelName,
      ...eventProperties,
      eventCategoryKey: 'Funnel Name',
      Platform: getPlatform(),
      isGDPR,
    },
    ['mixpanel'],
    anonymousUser
  );
};

export const trackLandingPageCTAFlowCT = () => {
  internalTrackEvent('Landing Page CTA Click', { Flow: 'CT' }, ['mixpanel'], true);
};

export const trackWizardEvent = (
  action: EventNameTypes,
  eventCategory: string,
  eventProperties = {}
) => {
  const eventProps = eventProperties;
  if (
    // eslint-disable-next-line no-prototype-builtins
    eventProps.hasOwnProperty('eventProperty') &&
    // eslint-disable-next-line no-prototype-builtins
    eventProps.hasOwnProperty('eventPropertyValue')
  ) {
    // @ts-ignore
    delete eventProps.eventProperty;
    // @ts-ignore
    delete eventProps.eventPropertyValue;
  }
  internalTrackEvent(
    action,
    {
      eventCategory,
      ...eventProperties,
    },
    ['mixpanel']
  );
};

export const trackWizardExitEvent = (wizardName: string = '', clientID: number | null = null) => {
  internalTrackEvent(
    `Exit ${wizardName} Wizard` as EventNameTypes,
    {
      eventCategory: 'Exit Wizard',
      'User ID': clientID,
      label: 'Exit',
      eventPropertyValue: 0,
    },
    ['mixpanel']
  );
};

export const trackStartEligibilityWidget = () => {
  internalTrackEvent(
    'Begin Eligibility Wizard',
    {
      Platform: getPlatform(),
    },
    ['mixpanel']
  );
};

export const trackOpenEligibilityWidget = (source: string) => {
  let locationText = 'Other';
  switch (source) {
    case 'dropDownMenu':
      locationText = 'Dropdown Menu';
      break;
    case 'chat':
      locationText = 'Chat';
      break;
    case 'ctReactivation':
      locationText = 'CT Reactivation';
      break;
    default:
      return;
  }
  internalTrackEvent(
    `Open Eligibility Wizard`,
    {
      Location: locationText,
      Platform: getPlatform(),
    },
    ['mixpanel']
  );
};

export const trackVirtualPageView = (url: string) => {
  const anonymizedURL = anonymizePath(url);
  VWO.trackVWOPageView(anonymizedURL);
};

export const setPeopleLogin = (userID: number) => {
  setPeople({
    'Last Login Platform': getPlatform(),
    'Uses Web': true,
    'Talkspace User ID': userID,
  });
};

export const trackUserLogin = () => {
  internalTrackEvent(
    'User Login',
    {
      Platform: getPlatform(),
    },
    ['mixpanel']
  );
};

export const trackUserSuccessLogin = () => {
  internalTrackEvent(
    'User/password successfully authenticated',
    {
      Platform: getPlatform(),
    },
    ['mixpanel']
  );
};

export const trackVisitTherapistScheduler = (
  funnelName: FunnelName,
  therapistID: number,
  roomID: number,
  creditType: VideoCreditOffer['type'] | undefined,
  sessionModality,
  isTherapist: boolean,
  noAvailability: boolean,
  timeSlotsByDay: TimeslotByDay[],
  roomStatus?: number,
  match?: boolean
) => {
  internalTrackEvent(
    'Visit Therapist Scheduler',
    getVisitTherapistSchedulerTrackingData(
      funnelName,
      therapistID,
      roomID,
      creditType,
      sessionModality,
      isTherapist,
      noAvailability,
      timeSlotsByDay,
      roomStatus,
      match
    )
  );
};

export const trackSelectScheduleModality = (therapistID?: number, roomID?: number) => {
  internalTrackEvent(
    'Select Schedule Modality',
    {
      actionName: 'schedulerStep',
      therapistID,
      roomID,
      scheduledBy: ScheduledBy.client,
    },
    ['tsAnalytics']
  );
};

export const trackSelectScheduleDuration = (
  modality: SessionModality,
  selectScheduleDurationMinutes?: number,
  roomID?: number,
  creditType?: VideoCreditOffer['type'],
  planID?: number | null
) => {
  internalTrackEvent(
    'Select Schedule Duration',
    {
      actionName: 'schedulerStep',
      modality: mixpanelSessionModalities[modality],
      roomID,
      selectScheduleDurationMinutes,
      scheduledBy: ScheduledBy.client,
      creditType,
    },
    ['tsAnalytics']
  );
};

interface TrackFirstSessionPrompt {
  roomID: number;
  providerID?: number;
  modalityNudge: 'Generic' | 'Messaging' | 'Match Based on Availability';
}

export const trackBookFirstSessionPrompt = ({
  roomID,
  providerID,
  modalityNudge,
}: TrackFirstSessionPrompt) => {
  internalTrackEvent(
    'Book First Session Prompt',
    {
      actionName: 'onboardingStep',
      roomID,
      modalityNudge,
      ...(providerID && { providerID }),
    },
    ['tsAnalytics']
  );
};

// QM mocks
export const trackEvent = async (eventName, eventProperties) => {};

export const trackGTMEvent = async (eventName, eventProperties) => {};

export const trackAsyncSessionStartedPrompt = (params: {
  userID: number;
  roomID: number;
  daysSinceStartOfSession: number;
}) => {
  internalTrackEvent('Async Session Started Prompt', params);
};

export const trackUserSettingsEvent = (
  userID: number,
  CTA: UserSettingsEventCTA,
  source: UserSettingsEventSource
) => {
  internalTrackEvent(
    CTA,
    {
      'User ID': userID,
      Source: source,
    },
    ['mixpanel']
  );
};

interface PaymentPlanProps {
  accountType: string;
  roomStatus: number;
}

export const trackPaymentPlanEvent = (
  userID: number,
  CTA: 'View Superbills' | 'Pause Therapy' | 'Stop Renewal',
  props?: Partial<PaymentPlanProps>
) => {
  internalTrackEvent(
    CTA,
    {
      eventCategory: 'Payment Plan',
      Source: 'Payment Plan',
      'User ID': userID,
      ...props,
    },
    ['mixpanel']
  );
};

interface UpdateCoverageEventProps {
  providerAvailable: boolean;
  hasRemainingSessions: boolean;
}

export const trackUpdateCoverageEvent = (
  roomID: number,
  eventName: 'Continue Update Coverage' | 'Update Coverage Room' | 'Confirm Update Coverage',
  props?: Partial<UpdateCoverageEventProps>
) => {
  internalTrackEvent(
    eventName,
    {
      actionName: 'clientUpdateCoverage',
      roomID,
      ...props,
    },
    ['tsAnalytics']
  );
};

export const trackEmailVerifiedEventWithOTP = ({ usedOTP }: { usedOTP: boolean }) =>
  internalTrackEvent(
    'Email verification success',
    { usedOTP, variant: 'treatment' },
    ['mixpanel'],
    true
  );

interface TrackNavPageViewOptions extends Record<string, unknown> {
  roomID?: number | string;
}

export const trackPageView = (pageName: PageName, options?: TrackNavPageViewOptions) => {
  internalTrackEvent(
    'Page View',
    {
      Page: pageName,
      ...options,
      Platform: getPlatform(),
    },
    ['mixpanel'],
    false
  );
};

export const trackCTAClick = (
  buttonText: string,
  pageName: PageName,
  options: TrackCTAClickOptions = {}
) => sharedTrackCTAClick(buttonText, pageName, { ...options, Platform: getPlatform() });

export const trackInAppReview = () => {
  internalTrackEvent(
    'App Review Triggered',
    {
      Platform: getPlatform(),
    },
    ['mixpanel'],
    false
  );
};

export const trackNotificationsSettingChanged = ({
  notificationName,
  notificationType,
  value,
}: {
  notificationName: Preferences;
  notificationType: NotificationType;
  value: boolean;
}) => {
  internalTrackEvent(
    'Notifications Setting Changed',
    { notificationName, notificationType, value },
    ['mixpanel']
  );
};
