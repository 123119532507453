import {
  Button,
  Huge,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  Large,
  View,
  useEmotionTheme,
  useWindowWidthState,
  TextDS,
  spacing,
} from '@talkspace/react-toolkit';
import { isTestAccount } from 'ts-frontend/helpers';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { useMainState } from '../../hooks/mainContext';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import ProgressBar from './ProgressBar';
import {
  setIsOnboardingDismissedTestAccount,
  setIsOnboardingDismissed,
} from '../util/onboardingStorage';
import useNavHelpers from '../../hooks/useNavHelpers';

const { space100, space200, space300 } = spacing;

interface Props extends RouteComponentProps<{ roomID: number }> {
  illustration: JSX.Element;
  title: string;
  description: string;
  timeEstimate?: string;
  totalSteps: number;
  currentStep: number;
  ctaText: string;
  onPress: () => void;
  allowClosing?: boolean;
  preventReopening?: boolean;
  renderCopyUpdate?: boolean;
}

const OnboardingPage = ({
  history,
  match: {
    params: { roomID },
  },
  illustration,
  totalSteps,
  currentStep,
  title,
  description,
  timeEstimate,
  ctaText,
  onPress,
  allowClosing,
  preventReopening,
  renderCopyUpdate,
}: Props) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { me } = useMainState();
  const { navigateToDefault } = useNavHelpers({ history, roomID });

  const isDismissible = allowClosing || isTestAccount(me?.email);
  const onDismiss = isDismissible
    ? () => {
        if (isTestAccount(me?.email)) {
          setIsOnboardingDismissedTestAccount(roomID);
        } else if (preventReopening) {
          setIsOnboardingDismissed(roomID);
        }
        if (ReactFrameService.isMobile()) {
          ReactFrameService.instance().closePopup();
        } else {
          navigateToDefault();
        }
      }
    : undefined;
  return (
    <ResponsiveLayoutWithHeader
      safeAreaVariant="setHeightAndAddTop"
      shouldScroll={false}
      renderHeader={() => (
        <PanelHeader isMobile={isMobile} onRightPress={onDismiss} dataQa="dismissOnboarding" />
      )}
    >
      <View align="center" style={{ margin: '0 20px' }}>
        <View style={{ marginTop: 16 }}>{illustration}</View>
        <ProgressBar
          totalSteps={totalSteps}
          currentStep={currentStep}
          wrapperStyle={{ marginTop: 45 }}
        />
        <View style={{ maxWidth: 308, textAlign: 'center' }}>
          {renderCopyUpdate ? (
            <>
              <TextDS variant="headingXl" style={{ marginTop: space300, marginBottom: space100 }}>
                {title}
              </TextDS>
              {timeEstimate && (
                <TextDS
                  variant="bodySm"
                  colorRole="textSubtlest"
                  style={{ marginBottom: space200 }}
                >
                  {timeEstimate}
                </TextDS>
              )}
              <TextDS variant="body" colorRole="textSubtle">
                {description}
              </TextDS>
            </>
          ) : (
            <>
              <Huge style={{ color: colors.black, marginTop: 16 }}>{title}</Huge>
              <Large style={{ marginTop: 16, color: colors.permaFiord }}>{description}</Large>
            </>
          )}
        </View>
        <Button
          text={ctaText}
          style={{ marginTop: 32, backgroundColor: colors.permaTalkspaceDarkGreen }}
          onPress={onPress}
          dataQa="primaryButton"
        />
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default withRouter(OnboardingPage);
