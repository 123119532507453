import { getFunnelName as getFunnelNameFromStorage } from 'ts-frontend/hooks/funnelName';
import { FunnelName } from '@/utils/analytics/trackerTypes';

const getFunnelName = ({
  isFromCheckInWizard,
  isFromPostLVSCheckInWizard,
  isSwitchWizard,
}: {
  isFromCheckInWizard?: boolean;
  isFromPostLVSCheckInWizard?: boolean;
  isSwitchWizard?: boolean;
}): FunnelName => {
  const isBookFirstSession = getFunnelNameFromStorage() === 'Onboarding';
  if (isBookFirstSession) {
    return 'Onboarding';
  }
  if (isSwitchWizard) {
    return 'Switch Provider';
  }
  if (isFromCheckInWizard || isFromPostLVSCheckInWizard) {
    return 'Book Next Session';
  }
  return 'In Room';
};

export default getFunnelName;
