import Lottie from 'react-lottie';
import { View, SuccessCheckmark } from '@talkspace/react-toolkit';
import loadingSpinner from '../../assets/animations/loadingSpinner.json';

interface OTPRequestStatusProps {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}
const OTPRequestStatus = ({ isError, isLoading, isSuccess }: OTPRequestStatusProps) => {
  if (isLoading) {
    return (
      <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingSpinner,
          }}
          height={65}
          width={65}
        />
      </View>
    );
  }
  if (isSuccess) {
    return (
      <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
        <SuccessCheckmark width={65} height={65} />
      </View>
    );
  }
  return null;
};
export default OTPRequestStatus;
