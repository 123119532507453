import React from 'react';
import { Trans, useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { GreenCircleCheckMark, TextDS, Link } from '@talkspace/react-toolkit';
import { teenTherapyGuideForParentsLink } from 'ts-frontend/constants';

const textStyles = { marginBottom: 20 };
const ConsentReceived = ({ isConsentAlreadyGranted }: { isConsentAlreadyGranted: boolean }) => {
  const { localizationParentalConsent } = useFlags();
  const { t: tParentalConsent } = useTranslation('parentalConsentV2', {
    lng: localizationParentalConsent ? undefined : 'en',
  });
  return (
    <>
      <GreenCircleCheckMark style={{ marginBottom: 26 }} />
      {isConsentAlreadyGranted ? (
        <TextDS
          variant="headingLg"
          colorRole="textDefault"
          dataQa="parentalConsentFormHasAlreadyBeenSignedLabel"
          style={textStyles}
        >
          <Trans t={tParentalConsent} i18nKey="consentConfirmation.alreadyBeenSigned">
            The consent form has already been signed
          </Trans>
        </TextDS>
      ) : (
        <TextDS
          variant="heading2xl"
          colorRole="textDefault"
          dataQa="parentalConsentFormHasBeenReceivedLabel"
          style={textStyles}
        >
          <Trans t={tParentalConsent} i18nKey="consentConfirmation.headline">
            The consent form has been received
          </Trans>
        </TextDS>
      )}
      {!isConsentAlreadyGranted && (
        <>
          <TextDS
            dataQa="parentalConsentThankYouLabel"
            variant="body"
            colorRole="textDefault"
            style={textStyles}
          >
            <Trans t={tParentalConsent} i18nKey="consentConfirmation.body">
              Thank you!
            </Trans>
          </TextDS>
          <TextDS dataQa="parentalConsentNextStepsLabel" variant="body" colorRole="textDefault">
            <Trans t={tParentalConsent} i18nKey="consentConfirmation.bodyAlt">
              What happens next? Your teen is able to begin therapy with Talkspace. Read the
            </Trans>
          </TextDS>{' '}
          <Link href={teenTherapyGuideForParentsLink}>
            <TextDS
              dataQa="parentalConsentNextStepsLabelLink"
              variant="linkMd"
              colorRole="textBrandDefault"
            >
              <Trans t={tParentalConsent} i18nKey="consentConfirmation.link">
                Parent/Guardian Guide to Therapy
              </Trans>
            </TextDS>
          </Link>
        </>
      )}
    </>
  );
};

export default ConsentReceived;
