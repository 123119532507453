import { FunctionComponent, useState, useCallback, useEffect } from 'react';
import {
  View,
  TextDS,
  Button,
  SpacingView,
  StickyDrawer,
  StickyDrawerContextProvider,
  SelectRounded,
  useEmotionTheme,
  OptionType,
  Grid,
  Col,
  Row,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';
import { ValueType } from 'react-select';
import { useTranslation } from '@talkspace/i18n';
import { SwitchProvider } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import styled from '@/core/styled';
import { trackPageView } from '@/utils/analytics/events';

const FormWrapper = styled(View)(({ theme: { spacing: spacingFunc } }) => {
  return {
    padding: `${spacingFunc('space300')}px 0`,
  };
});

const getDropdownStyles = (colors) => {
  return {
    clearIndicator: (provider) => {
      return {
        ...provider,
        color: colors.permaLividBlueNew,
      };
    },
    control: (provider) => {
      return {
        ...provider,
        border: `1px solid ${colors.permaLividBlueNew}`,
      };
    },
    dropdownIndicator: (provider) => {
      return {
        ...provider,
        color: colors.permaLividBlueNew,
      };
    },
    menu: (provider) => {
      return {
        ...provider,
        borderColor: colors.permaLividBlueNew,
      };
    },
  };
};

const SwitchReason: FunctionComponent<{
  onPress: (selectedValue?: string | null) => void;
  selectOptions: ValueType<OptionType, true>;
}> = ({ onPress, selectOptions }) => {
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const { isSmall } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const [selected, setSelected] = useState<ValueType<OptionType, false> | null>(
    selectOptions ? selectOptions[0] : null
  );

  useEffect(() => {
    trackPageView('switch-wizard-switch-reason');
  }, []);

  const onSubmit = useCallback(() => {
    onPress(selected?.value);
  }, [selected?.value, onPress]);

  const handleDropdownChange = (val) => {
    setSelected(val);
  };
  const dropdownStyles = getDropdownStyles(colors);
  if (!selectOptions) {
    return null;
  }
  return (
    <StickyDrawerContextProvider>
      <Grid style={{ marginTop: spacing.space400 }}>
        <Row>
          {!isSmall && (
            <Col xs={2} sm={1} md={2} lg={4}>
              <></>
            </Col>
          )}
          <Col xs={4} sm={6} md={4} lg={4}>
            <SpacingView itemSpacing="space150" centerAlign>
              <CircleUnderlay size={136} colorRole="decorativeBalanceBlueTint">
                <SwitchProvider />
              </CircleUnderlay>
              <TextDS variant="headingXl">
                {tSwitchWizard('SwitchReason.title', 'Before changing providers')}
              </TextDS>
              <TextDS colorRole="textSubtle">
                {tSwitchWizard(
                  'SwitchReason.subtitle',
                  'Select a reason why you’d like to try a new provider'
                )}
              </TextDS>
            </SpacingView>
          </Col>
        </Row>
        <Row>
          {!isSmall && (
            <Col xs={2} sm={1} md={2} lg={4}>
              <></>
            </Col>
          )}
          <Col xs={4} sm={6} md={4} lg={4}>
            <FormWrapper justify="center" align="center">
              <SelectRounded
                aria-labelledby="relationship"
                options={selectOptions}
                value={selected}
                onChange={handleDropdownChange}
                isMulti={false}
                isInputReadOnly
                isCreatable={false}
                wrapperStyle={{
                  margin: 0,
                }}
                styles={dropdownStyles}
                dataQa="selectRelationshipDropdown"
              />
            </FormWrapper>
          </Col>
        </Row>
      </Grid>
      <StickyDrawer>
        <Button
          text="Continue"
          onPress={onSubmit}
          dataQa="switchReasonContinue"
          fixedWidth
          sizeDS="large"
        />
      </StickyDrawer>
    </StickyDrawerContextProvider>
  );
};

export default SwitchReason;
