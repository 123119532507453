import { FunctionComponent, useState, useEffect, useRef } from 'react';
import {
  View,
  TouchableView,
  Large,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  Spinner,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { prepareDataByTimezone } from 'ts-frontend/helpers';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useTranslation } from '@talkspace/i18n';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';
import useQueryTherapistDetails, {
  TherapistDetailsResponse,
} from 'ts-frontend/hooks/useQueryTherapistDetails';
import { useMainState, useMainActions } from '@/hooks/mainContext';
import { ImplicitBusinessHoursByDay } from '@/clientChat/reducers/inPlatformMatchingReducer';
import { ID_PROVIDER_DETAILS_PANEL } from '@/utils/IDConstants';
import { allowReview } from '@/switchWizard/utils/switchWizardApiHelper';
import { getUserData } from '../auth/helpers/token';
import { withRouter, RouteComponentProps } from '../core/routerLib';
import PanelTherapistBlock from '../components/PanelTherapistBlock/index';
import extractRoomEntities from '../utils/extractRoomEntitiesFromState';
import ApiHelper from '../utils/ApiHelper';
import useObjectState from '../myAccount/hooks/useObjectState';
import styled from '../core/styled';
import TherapistDetailsViewPT from '../components/TherapistDetailsViewPT/index';
import useA11y from './TherapistDetails.a11y';
import { useCloseModal } from '../utils/ModalsContextProvider';
import { MatchResultDetails } from '../therapistMatches/matchingTypes';
import StickyFooterButton from '../components/TherapistMatchDirectoryCard/StickyFooterButton';

const LightButton = styled(View)({
  boxShadow: '0 3px 11px -3px rgba(16,109,149,0.30)',
  borderRadius: 22,
  height: 44,
  width: 170,
  alignItems: 'center',
  justifyContent: 'center',
});

const ReviewProviderButtonText = styled(Large)(({ theme: { colors } }) => {
  return {
    color: colors.greenText,
  };
});

type Props = RouteComponentProps<{ roomID: string }> & {
  isChatHidden?: boolean;
  isMobile?: boolean;
  isModal?: boolean;
  toggleProviderProfile?: () => void;
  isChangeTherapistFlow?: boolean;
  providerId?: number;
  therapistType?: string;
  therapistDetailsProps?: TherapistDetailsProps;
  isMessaging?: boolean;
};
interface TherapistDetailsProps {
  nextAvailable?: string;
  isMessaging?: boolean;
  isBooking?: boolean;
  onPress?: () => void;
  details?: MatchResultDetails;
}

interface TherapistDetailsContentProps {
  therapist: ETherapistInfo;
  room: ERoom;
  reviewAllowed: boolean;
  handleOnLeaveAReviewClick: () => void;
  businessHoursByDay: ImplicitBusinessHoursByDay | undefined;
  timeOffText: string;
  error: string | null;
  therapistDetailsProps?: TherapistDetailsProps;
  providerId?: number;
  isMessaging?: boolean;
}

const therapistMatchDetailsFormatter = (
  therapistMatchDetails?: TherapistDetailsResponse | undefined,
  supplementalTherapistInfo?: {
    therapistType?: string;
    providerId?: number;
  }
): ETherapistInfo | null => {
  const { therapistType, providerId } = supplementalTherapistInfo || {};
  if (therapistMatchDetails) {
    const {
      therapistId,
      imageURL,
      licenses,
      availability,
      expertise,
      info: {
        public_details: publicDetails = '',
        psychologytoday_profile_id: psychologytodayProfileID = '',
        first_clinical_license_date: firstClinicalLicenseDate = '',
        first_name: firstName = '',
        last_name: lastName = '',
        joinedYearsAgo = -1,
        yearsInPractice = -1,
        businessDays = {},
      },
    } = therapistMatchDetails;

    return {
      id: therapistId || providerId,
      title: '',
      firstName,
      lastName,
      licenses: licenses && licenses.map((license) => license.typeDescription),
      type: therapistType,
      imageURL,
      publicDetails,
      psychologytodayProfileID,
      firstClinicalLicenseDate,
      businessHoursTimezone: '',
      availability,
      unavailabilityPeriod: { startDate: '', endDate: '' },
      businessDays,
      expertise,
      licenseDetails:
        licenses &&
        licenses.map((license) => {
          return {
            state: license.stateAbb,
            number: license.number,
            name: license.type,
            description: license.typeDescription,
          };
        }),
      yearsInPractice,
      joinedYearsAgo,
      phase: 1,
    } as ETherapistInfo;
  }
  return null;
};

const TherapistDetailsContent = ({
  therapist,
  room,
  reviewAllowed,
  handleOnLeaveAReviewClick,
  businessHoursByDay,
  timeOffText,
  error,
  therapistDetailsProps,
  isMessaging,
}: TherapistDetailsContentProps) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const maxWidth = 523;

  return (
    <View
      align="center"
      style={{
        paddingBottom: 20,
        overflowX: 'hidden',
      }}
    >
      <View
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          alignItems: 'center',
          maxWidth,
        }}
      >
        <View style={{ marginTop: 30, marginBottom: 15 }}>
          <PanelTherapistBlock
            therapist={therapist}
            room={room}
            avatarSize={135}
            playButton
            licenses
          />
        </View>
        {reviewAllowed && (
          <TouchableView onPress={handleOnLeaveAReviewClick} aria-label="Open therapist review">
            <LightButton>
              <ReviewProviderButtonText variant="largeBoldWideGreen">
                {tnewMemberNav('providerProfile.leave', 'Leave a review')}
              </ReviewProviderButtonText>
            </LightButton>
          </TouchableView>
        )}
        <Large style={{ marginTop: 23, wordBreak: 'break-word' }}>{therapist.publicDetails}</Large>

        <TherapistDetailsViewPT
          therapist={therapist}
          businessHoursByDay={businessHoursByDay}
          timeOffText={timeOffText}
          error={error}
        />
      </View>
      {therapistDetailsProps && (
        <StickyFooterButton
          {...therapistDetailsProps}
          pageName="provider-profile"
          isMessaging={isMessaging}
          maxWidth={maxWidth}
        />
      )}
    </View>
  );
};

const TherapistDetailsContainer: FunctionComponent<Props> = ({
  history,
  match,
  isChatHidden,
  isMobile,
  isModal,
  isChangeTherapistFlow,
  providerId,
  therapistType,
  therapistDetailsProps,
  isMessaging,
}) => {
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const [error, setError] = useState<string | null>(null);
  const [reviewAllowed, setReviewAllowed] = useState(false);
  const closeModal = useCloseModal();
  const useNewNav = useNewMemberNav();

  const handleOnRoomPanelClosePress = () => {
    if (isModal) {
      closeModal();
    } else {
      history.push(`/room/${match.params.roomID}`);
    }
  };

  const { getAllRooms } = useMainActions();
  const { id: userID } = getUserData();
  const { therapist: therapistFoundByRoomId, room } = extractRoomEntities(
    useMainState(),
    Number(match.params.roomID) || (history.location.state as { roomID: number }).roomID
  );

  const { data: therapistDetailsData, isLoading } = useQueryTherapistDetails(
    isChangeTherapistFlow ? providerId : undefined
  );

  const therapistFoundById = therapistMatchDetailsFormatter(therapistDetailsData, {
    therapistType,
    providerId,
  });
  const therapist = therapistFoundById || therapistFoundByRoomId;

  const [businessHoursByDay, setBusinessHoursByDay] = useObjectState<
    ImplicitBusinessHoursByDay | undefined
  >(undefined);

  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;

  useEffect(() => {
    (async () => {
      const allowReviewResponse = await allowReview(Number(match.params.roomID));

      setReviewAllowed(allowReviewResponse.allow || false);
    })();
  }, [match.params.roomID]);

  useEffect(
    () => () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiRef.current.cancelAll();
    },
    []
  );

  useEffect(() => {
    if (therapist?.id) {
      api
        .getImplicitBusinessHours(therapist.id)
        .then((res) => {
          if (res.businessHours.length) {
            setBusinessHoursByDay(prepareDataByTimezone(res.businessHours));
          }
        })
        .catch((err) => {
          setError(err);
        });
    } else if (userID) {
      getAllRooms(userID);
    }
  }, [api, setBusinessHoursByDay, setError, therapist?.id, getAllRooms, userID]);

  const { skipNavs } = useA11y(history, match);

  if (!therapist?.id || !room?.roomID || isLoading) {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
        }}
      >
        <Spinner />
      </View>
    );
  }

  const { startDate, endDate } = (therapist as ETherapistInfo)?.unavailabilityPeriod || {};
  const timeOffText =
    startDate && endDate
      ? `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`
      : tnewMemberNav('providerProfile.no', 'No time off scheduled');

  const handleOnLeaveAReviewClick = () => {
    history.push({
      pathname: `/room/${match.params.roomID}/review-therapist`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const titleText = `${therapist.title} ${therapist.firstName} ${therapist.lastName}'s profile`;

  const therapistDetailsContentProps = {
    therapist: therapist as ETherapistInfo,
    room,
    reviewAllowed,
    handleOnLeaveAReviewClick,
    businessHoursByDay,
    timeOffText,
    error,
    therapistDetailsProps,
  };

  return !isChangeTherapistFlow ? (
    <ResponsiveLayoutWithHeader
      title={titleText}
      panelId={ID_PROVIDER_DETAILS_PANEL}
      renderHeader={() => (
        <PanelHeader
          title={tnewMemberNav('providerProfile.provider', 'Provider Profile')}
          onRightPress={handleOnRoomPanelClosePress}
          skipNavs={skipNavs}
          isChatHidden={isChatHidden}
          isMobile={isMobile}
          useNewNavHeader={useNewNav}
        />
      )}
    >
      <TherapistDetailsContent {...therapistDetailsContentProps} isMessaging={isMessaging} />
    </ResponsiveLayoutWithHeader>
  ) : (
    <TherapistDetailsContent {...therapistDetailsContentProps} isMessaging={isMessaging} />
  );
};

export default withRouter(TherapistDetailsContainer);
