import { FunctionComponent, useEffect } from 'react';
import { View, Big, ResponsiveLayoutWithHeader, PanelHeader } from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslation } from '@talkspace/i18n';
import UpcomingSurveysContainer from '../containers/UpcomingSurveysContainer';
import SymptomTrackerContainer from '../containers/SymptomTrackerContainer';
import GoalsContainer from '../containers/GoalsContainer';
import ObjectivesContainer from '../containers/ObjectivesContainer';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import { trackPageView } from '../../utils/analytics/events';
import useNavShellWindowHeight from '../../hooks/useNavShellWindowHeight';

const ClinicalProgress: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }>
> = ({ history, match }) => {
  const useNewNav = useNewMemberNav();
  const { memberNewNav } = useFlags();
  const { outsideHeaderHeight, outsideFooterHeight } = useNavShellWindowHeight();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');

  useEffect(() => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackPageView('clinical-progress', { roomID: match.params.roomID });
    }
  }, [memberNewNav, match.params.roomID]);

  return (
    <ResponsiveLayoutWithHeader
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
      safeAreaVariant="none"
      noHeaderLine={useNewNav}
      contentWrapperStyle={{ height: useNewNav ? '100%' : undefined }}
      renderHeader={
        useNewNav
          ? null
          : () => (
              <PanelHeader
                renderLeft={() => (
                  <View row align="center">
                    <Big as="h1">{tnewMemberNav('journey.clinical', 'Clinical Progress')}</Big>
                  </View>
                )}
                onRightPress={history.goBack}
              />
            )
      }
    >
      <UpcomingSurveysContainer />
      <SymptomTrackerContainer />
      <GoalsContainer />
      <ObjectivesContainer />
    </ResponsiveLayoutWithHeader>
  );
};

export default ClinicalProgress;
