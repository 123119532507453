import { useCallback, useEffect } from 'react';
import { History } from 'history';
import { useQueryClient } from 'react-query';
import { subscriptionsQueryKey } from 'ts-frontend/hooks/queryKeys';
import {
  WizardScreen,
  useWizardActions,
  useWizardState,
  STEP_NUMBER_WELCOME_SCREEN,
} from 'stepWizard';
import { Screen } from '@talkspace/react-toolkit';

import { usePostLvsSwitchPrompt } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useIsModal } from '@/utils/ModalsContextProvider';
import { Route, Switch } from '../core/routerLib';
import MatchResultsContainer from './containers/MatchResultsContainer';
import TherapistConfirmationContainer from './containers/TherapistConfirmationContainer';
import SharingPreferencesContainer from './containers/SharingPreferencesContainer';
import SwitchReasonContainer from './containers/SwitchReasonContainer';
import SwitchReviewContainer from './containers/SwitchReviewContainer';
import SwitchSuccessContainer from './containers/SwitchSuccessContainer';
import SwitchConfirmBookingContainer from './containers/SwitchConfirmBookingContainer';
import useWindowWidth from '../clientChat/hooks/useWindowWidth';
import switchWizardScheme from './switchWizardScheme';
import switchWizardSchemeV2 from './switchWizardSchemeV2';
import { onboardingQueryKey, onboardingV3QueryKey, getAllRoomsQueryKey } from '@/utils/queryKeys';
import useSwitchWizard from './hooks/useSwitchWizard';
import { getUserData } from '@/auth/helpers/token';
import { State } from './reducers/switchWizardState';
import useMatchResults from '../therapistMatches/hooks/useMatchResults';

const exitBackButtonSources = [
  'home',
  'my-account',
  'b2b-cancellation',
  'cancellation',
  'post-lvs-web',
  'post-lvs-check-in',
];

const hideCloseButtonSources = ['post-lvs-web', 'post-lvs-check-in'];

const getTitle = (currentStepNumber: number, path: string) => {
  // Welcome screen
  if (path === '/switch-provider/review') return 'Feedback';
  if (path.includes('/booking')) return 'Booking with new provider';
  if (currentStepNumber === -1 && path === '/switch-provider') return 'Review preferences';
  return undefined;
};

const Routes = ({ history }: { history: History }) => {
  const {
    location: {
      pathname,
      state: { source },
    },
  } = history;
  const isPostLvsSwitchPrompt = usePostLvsSwitchPrompt();
  const { onExitClick, setState, closeWizard } = useWizardActions();
  const { matchResultsState, setMatchResultsState } = useMatchResults();
  const { isProviderProfileOpen } = matchResultsState;
  const { roomID, currentStepNumber, screenTitle } = useWizardState<State>();
  const { isMobile } = useWindowWidth();
  const isModal = useIsModal();

  const { availabilitySwitch } = useFlags();
  const { id: userID } = getUserData();

  const showBackButton =
    !isProviderProfileOpen &&
    !hideCloseButtonSources.includes(source) &&
    ((availabilitySwitch && currentStepNumber === STEP_NUMBER_WELCOME_SCREEN) ||
      (pathname.includes('switch-provider/') && !pathname.includes('select-timeslot')) ||
      (isPostLvsSwitchPrompt && source === 'postLvsCheckinSwitchProvider'));

  const { exitAction } = useSwitchWizard()[1];
  const queryClient = useQueryClient();

  const handleExitModal = useCallback(() => {
    const queryKeys = [
      onboardingQueryKey({ roomID, userID }),
      onboardingV3QueryKey({ roomID, userID }),
      getAllRoomsQueryKey({ clientUserID: userID }),
      subscriptionsQueryKey({
        clientUserID: userID,
        roomID: Number(roomID),
      }),
    ];
    queryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries(queryKey, { refetchActive: false });
    });
    exitAction();
    closeWizard(roomID, true);
  }, [exitAction, closeWizard, roomID, userID, queryClient]);

  useEffect(() => {
    if (availabilitySwitch) {
      const newTitle = getTitle(currentStepNumber, pathname);
      if (newTitle) {
        setState({ screenTitle: newTitle });
      }
    }
  }, [currentStepNumber, availabilitySwitch, pathname, setState]);

  const title = availabilitySwitch ? screenTitle : 'Change provider';

  const closeProviderProfile = useCallback(() => {
    setMatchResultsState({ isProviderProfileOpen: false });
  }, [setMatchResultsState]);

  return (
    <Switch>
      <Screen
        safeAreaVariant={isModal ? 'setHeight' : 'setHeightAndAddTop'}
        contentWrapperStyle={{ paddingBottom: 0 }}
        showBackButton={showBackButton}
        onCloseButtonClick={isProviderProfileOpen ? closeProviderProfile : onExitClick}
        backButtonDataQa="switchWizardScreenBackButton"
        backButtonStyles={{ width: 30, padding: 5 }}
        onBackButtonClick={() => {
          if (
            currentStepNumber === STEP_NUMBER_WELCOME_SCREEN &&
            exitBackButtonSources.includes(source)
          ) {
            onExitClick();
          }
          setState({ redirectParams: null });
          history.goBack();
        }}
        title={isMobile ? title : ''}
      >
        <Route exact path="/switch-provider/therapist-matches" component={MatchResultsContainer} />
        <Route
          path="/switch-provider/therapist-confirmation"
          render={() => (
            <TherapistConfirmationContainer
              isV2={!!availabilitySwitch}
              handleExitModal={handleExitModal}
            />
          )}
        />
        <Route path="/switch-provider/booking/:roomID" component={SwitchConfirmBookingContainer} />

        <Route
          exact
          path="/switch-provider/sharing-preferences"
          render={(props) => <SharingPreferencesContainer isV2={!!availabilitySwitch} {...props} />}
        />
        <Route exact path="/switch-provider/switch-reason" component={SwitchReasonContainer} />
        <Route
          exact
          path="/switch-provider/success"
          render={(props) => (
            <SwitchSuccessContainer handleExitModal={handleExitModal} {...props} />
          )}
        />
        <Route
          exact
          path="/switch-provider/review"
          render={(props) => <SwitchReviewContainer handleExitModal={handleExitModal} {...props} />}
        />
        <Route
          exact
          path="/switch-provider"
          render={() => (
            <WizardScreen
              wizardScheme={availabilitySwitch ? switchWizardSchemeV2 : switchWizardScheme}
            />
          )}
        />
      </Screen>
    </Switch>
  );
};

export default Routes;
