import {
  useRef,
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  FunctionComponent,
} from 'react';
import { ThemedFlagsProvider } from 'launchDarkly';
import { useQueryClient, QueryClient } from 'react-query';
import { routePromise } from 'ts-frontend/helpers';
import { setIonicStorage, OPEN_APP_REVIEW } from 'ts-ionic/plugins/secureStorage';
import { getIsIonic } from 'ts-ionic';
import camelCase from 'lodash/camelCase';
import { ClosePopupAction, AdditionalMetaData } from '@/auth/reactFrame/ReactFrameTypes';
import {
  Router,
  createMemoryHistory,
  MemoryHistory,
  Switch,
  Route,
  getBrowserHistory,
} from '../core/routerLib';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';
import { isCommercialRoute } from './analytics/helpers';
import sessionStorage from '../core/storage/sessionStorage';

export type OpenModal = (
  url: string,
  state?: object,
  fullScreen?: boolean,
  transparent?: boolean,
  onClose?: () => void
) => void;

export type CloseModal = (data?: ClosePopupAction, queryClient?: QueryClient) => void;

interface OuterContextInterface {
  isModalOpen: boolean;
  openModal: OpenModal;
}

interface InnerContextInterface {
  focusTrapPaused: boolean;
  closeModal: CloseModal;
  pauseModalFocusTrap: () => void;
  resumeModalFocusTrap: () => void;
  isModal: boolean;
}

const reactFrameService: ReactFrameService = ReactFrameService.instance();

const prefixSessionStorageKey = (key: string) => {
  const isInFrame = reactFrameService.isInFrame();
  /**
   * This is made to avoid conflicts in session storage keys (like returnTo) between two iFrames
   * of the same app (for example, Eligibility Widget). Another alternative is to use the document.title
   */
  const prefixOrigin = isInFrame ? 'iFrame_' : '';
  return `${prefixOrigin}${key}`;
};

const getCancelSubscriptionURL = (data) => {
  // if (data.param === 'sessions-to-cancel') {
  //   return `/cancel-subscription/room/${data.metadata.roomID}/source/my-account/sessions-to-cancel`;
  // }
  const suffixRoute = data.metadata.param ? `/${data.metadata.param}` : '';
  const baseRoute =
    data.metadata.cancellationRoute === 'cancel-non-subscription'
      ? 'cancel-non-subscription'
      : 'cancel-subscription';
  return `/${baseRoute}/room/${data.metadata.roomID}/source/my-account${suffixRoute}`;
};

const getClosePopupURL = (data: ClosePopupAction): string => {
  switch (data.navigateTo) {
    case 'home':
      return '/home';
    case 'teenspace':
      return '/home/teenspace';
    case 'createTeenspacePost':
      return '/community/teenspace/create-post';
    case 'dashboard':
      return '/rooms';
    case 'room':
      return data.metadata.source
        ? `/room/${data.metadata.roomID}?source=${camelCase(data.metadata.source)}`
        : `/room/${data.metadata.roomID}`;
    case 'roomDetails':
      return `/room/${data.metadata.roomID}/room-details`;
    case 'reactivateRoom':
      return `/room-reactivation/${data.metadata.roomID}`;
    case 'clinicalProgress':
      return `/room/${data.metadata.roomID}/clinical-progress`;
    case 'manageSubscription':
      return `/room/${data.metadata.roomID}/my-account/manage-subscription`;
    case 'switchProvider':
      return `/room/${data.metadata.roomID}/my-account/manage-subscription?action=cancellation-switch-exit&source=${data.metadata.source}&contextID=${data.metadata.contextID}`;
    case 'personalInformation':
      return `/room/${data.metadata.roomID}/my-account/personal-information?action=${data.action}`;
    case 'cancelBooking':
      return `/in-room-scheduling/room/${data.metadata.roomID}/cancel-booking/booking/${data.metadata.bookingID}`;
    case 'changePlan':
      return `/room-offer/${data.metadata.roomID}/offer/${data.metadata.offerID}`;
    case 'passcode':
      return `/passcode`;
    case 'passcodeEdit':
      return `/passcode/edit`;
    case 'cancelSubscription':
      return getCancelSubscriptionURL(data);
    case 'updatePaymentMethod':
      return `/payment-wizard/room/${data.metadata.roomID}?source=updatePaymentMethod`;
    case 'bookSession':
      return data.forceWebRedirect
        ? `/in-room-scheduling/room/${data.metadata.roomID}?redirectToRoom=true`
        : `/in-room-scheduling/room/${data.metadata.roomID}`;
    case 'refundPurchase':
      return `/refund-purchase/source/payment-and-plan`;
    case 'emailVerificationSent':
      return `/email-verification/sent#email=${encodeURIComponent(data.metadata.email)}&token=${
        data.metadata.token
      }`;
    case 'eligibilityWidget':
      return data.metadata.queryString
        ? `/eligibility-widget/room/${data.metadata.roomID}?${data.metadata.queryString}`
        : `/eligibility-widget/room/${data.metadata.roomID}`;
    case 'addNewService':
      return `/add-new-service/room/${data.metadata.roomID}`;
    default:
      return '/rooms';
  }
};

const getAdditionalMetadata = (data: ClosePopupAction | undefined): AdditionalMetaData | null => {
  if (!data) {
    return null;
  }
  switch (data.navigateTo) {
    case 'room':
    case 'dashboard':
      return data.metadata?.additionalMetaData || null;
    default:
      return null;
  }
};

const OuterContext = createContext<OuterContextInterface | undefined>(undefined);

const InnerContext = createContext<InnerContextInterface>({
  focusTrapPaused: false,
  closeModal: (data?, queryClient?) => {
    if (queryClient) {
      queryClient.resetQueries(undefined, undefined, { cancelRefetch: true });
    }
    const additionalMetadata = getAdditionalMetadata(data);
    if (getIsIonic() && additionalMetadata && additionalMetadata.actionType === 'openAppReview') {
      setIonicStorage({ key: OPEN_APP_REVIEW, value: 'true' });
    }
    const browserHistory = getBrowserHistory();
    let returnTo = sessionStorage.getItem(prefixSessionStorageKey('returnTo'));
    if (data?.forceWebRedirect) {
      if (returnTo) {
        sessionStorage.removeItem(prefixSessionStorageKey('returnTo'));
      }
      if (data?.navigateTo) returnTo = getClosePopupURL(data);
      // browser redirect
      routePromise(returnTo || '/rooms');
    } else if (!returnTo && reactFrameService.isInFrame()) {
      reactFrameService.closePopup(data);
    } else {
      sessionStorage.removeItem(prefixSessionStorageKey('returnTo'));
      if (data?.navigateTo) {
        returnTo = getClosePopupURL(data);
      }

      if (data?.ignoreReturnTo && reactFrameService.isInFrame()) {
        // send close event to mobile
        reactFrameService.closePopup(data);
      } else {
        // browser redirect
        browserHistory.push(returnTo || '/rooms');
      }
    }
  },
  pauseModalFocusTrap: () => {
    // eslint-disable-next-line no-console
    console.log('pauseModalFocusTrap was called with wrong context');
  },
  resumeModalFocusTrap: () => {
    // eslint-disable-next-line no-console
    console.log('resumeModalFocusTrap was called with wrong context');
  },
  isModal: false,
});

/**
 * @param {boolean} safeMode - when true it ensures a modal that is already open can render
 * a component that calls useOpenModal. The returned null signifies an open modal
 */
export function useOpenModal(): OpenModal;
export function useOpenModal(safeMode: boolean): OpenModal | null;
export function useOpenModal(safeMode?: boolean) {
  const context = useContext(OuterContext);

  if (context === undefined) {
    if (safeMode) return null;
    throw new Error('OuterContext must be used within a ContextProvider');
  }
  return context.openModal;
}

export function useCloseModal(): CloseModal {
  const queryClient = useQueryClient();

  const { closeModal } = useContext(InnerContext);
  return useCallback((data) => closeModal(data, queryClient), [closeModal, queryClient]);
}

export function useModalFocusTrap() {
  const { focusTrapPaused, pauseModalFocusTrap, resumeModalFocusTrap } = useContext(InnerContext);
  return { focusTrapPaused, pauseModalFocusTrap, resumeModalFocusTrap };
}

/** Returns whether the current screen is a modal */
export function useIsModal() {
  return useContext(InnerContext).isModal;
}

let memoryHistory: MemoryHistory | null;

export const getMemoryHistory = () => memoryHistory;

function useMemoryHistory() {
  if (!memoryHistory) {
    memoryHistory = createMemoryHistory();
  }
  const history = memoryHistory;

  const setHistoryUpdated = useState({})[1];

  useEffect(() => {
    const unlisten = history.listen(() => setHistoryUpdated({}));
    return unlisten;
  }, [history, setHistoryUpdated]);

  return history;
}

const ModalsProvider: FunctionComponent<{
  ModalRoutes: FunctionComponent<any>;
}> = ({ children, ModalRoutes }) => {
  const [focusTrapPaused, setFocusTrapPaused] = useState(false);
  const fullScreenRef = useRef<boolean>(true);
  const transparentRef = useRef<boolean>(false);
  const isModalOpen = useRef<boolean>(false);
  const [isModalOpenState, setIsModalOpenState] = useState<boolean>(false);
  const onCloseModal = useRef<Function | undefined>(undefined);
  const queryClient = useQueryClient();

  const modalHistory = useMemoryHistory();
  useEffect(() => {
    const modalIndex = window.location.pathname.indexOf('/modal');
    if (modalIndex > -1 && !isModalOpen.current) {
      // browser refreshed on modal route
      const browserHistory = getBrowserHistory();
      browserHistory.push(`${browserHistory.location.pathname.slice(0, modalIndex)}`);
    }
  }, []);

  useEffect(() => {
    if (modalHistory.location.pathname === '/') {
      isModalOpen.current = false;
      setIsModalOpenState(false);
    }
  }, [modalHistory.location.pathname]);

  useEffect(
    () =>
      getBrowserHistory().listen((location) => {
        const pathnameIncludesModal =
          getBrowserHistory().location.pathname.includes('/modal') ||
          location.pathname.includes('/modal');
        if (
          !pathnameIncludesModal &&
          isModalOpen.current // modal is opened
        ) {
          // MODAL was closed from a back event
          modalHistory.go(-1 * modalHistory.length);
          isModalOpen.current = false;
          setIsModalOpenState(false);
          // Modal was opened by another ModalsContextProvider
        } else if (pathnameIncludesModal && !isModalOpen.current) {
          isModalOpen.current = true;
          setIsModalOpenState(true);
        }
      }),
    [modalHistory]
  );

  const outerContext = {
    isModalOpen: isModalOpen.current,
    openModal: useCallback(
      (url: string, state = {}, fullScreen = true, transparent = false, onClose?: Function) => {
        const browserHistory = getBrowserHistory();
        if (isCommercialRoute(url)) {
          sessionStorage.setItem(prefixSessionStorageKey('returnTo'), window.location.pathname);

          if (url === '/room-offer') {
            // When opening from chat, params are passed through location state
            if (!state) return;

            const { offerID, planID, roomID } = state;
            let path = `/room-offer/${roomID}/offer/${offerID}`;
            if (planID) {
              path += `/plan/${planID}`;
            }
            browserHistory.push(path || '/rooms');
            return;
          }

          if (url === '/insurance-eligibility') {
            // When opening from chat, params are passed through location state
            if (!state) return;
            browserHistory.push(`/insurance-eligibility/room/${state.roomID}/checkout`);
            return;
          }
          browserHistory.push(url, state);
          return;
        }

        setFocusTrapPaused(false); // Reset focus trap status
        // Mark modal is open

        if (!isModalOpen.current) browserHistory.push(`${browserHistory.location.pathname}/modal`);
        isModalOpen.current = true;
        setIsModalOpenState(true);

        // Set the modal design
        fullScreenRef.current = fullScreen;
        transparentRef.current = transparent;

        // Set other modal properties
        onCloseModal.current = onClose;

        // Open the modal
        modalHistory.push(url, state);
      },
      [modalHistory]
    ),
  };

  const innerContext: InnerContextInterface = {
    focusTrapPaused,
    closeModal: useCallback(
      (data?: ClosePopupAction, queryClientParam?: QueryClient) => {
        if (queryClientParam) {
          queryClientParam.resetQueries(undefined, undefined, { cancelRefetch: true });
        } else {
          queryClient.resetQueries(undefined, undefined, { cancelRefetch: true });
        }

        const additionalMetadata = getAdditionalMetadata(data);
        if (
          getIsIonic() &&
          additionalMetadata &&
          additionalMetadata.actionType === 'openAppReview'
        ) {
          setIonicStorage({ key: OPEN_APP_REVIEW, value: 'true' });
        }
        const browserHistory = getBrowserHistory();
        // reset memory history
        modalHistory.go(-1 * modalHistory.length);
        isModalOpen.current = false;
        setIsModalOpenState(false);

        if (data && data.navigateTo) {
          // listen to browserHistory.goBack() and when it happens execute navigateTo
          const cleanup = browserHistory.listen(() => {
            if (browserHistory.action === 'POP') {
              cleanup();
              if ('from' in data) {
                browserHistory.push({
                  pathname: getClosePopupURL(data),
                  state: {
                    from: data.from,
                  },
                });
              } else {
                browserHistory.push(getClosePopupURL(data));
              }
              sessionStorage.removeItem('isModalClosing');
            }
          });
          // prevent race condition wherein a child component routes before modal closes
          sessionStorage.setItem('isModalClosing', 'true');
        }

        browserHistory.goBack(); // remove the /modal from the browser
        if (onCloseModal.current) {
          onCloseModal.current();
          onCloseModal.current = undefined;
        }
      },
      [modalHistory, queryClient]
    ),
    pauseModalFocusTrap: useCallback(() => setFocusTrapPaused(true), []),
    resumeModalFocusTrap: useCallback(() => setFocusTrapPaused(false), []),
    isModal: isModalOpenState,
  };

  return (
    <>
      <OuterContext.Provider value={outerContext}>{children}</OuterContext.Provider>
      <InnerContext.Provider value={innerContext}>
        <ThemedFlagsProvider versionKey="default">
          <Router history={modalHistory}>
            <Switch>
              <Route exact path="/" component={() => null} />
              <ModalRoutes
                fullScreen={fullScreenRef.current}
                transparent={transparentRef.current}
              />
            </Switch>
          </Router>
        </ThemedFlagsProvider>
      </InnerContext.Provider>
    </>
  );
};

export function useOuterModalState(): OuterContextInterface {
  const context = useContext(OuterContext);
  if (context === undefined) {
    throw new Error('outer modal state context must be used within ModalsProvider');
  }
  return context;
}

export default ModalsProvider;
