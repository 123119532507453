import capitalize from 'lodash/capitalize';

import { ExtraHuge, SimpleQuestions, View } from '@talkspace/react-toolkit';

import { useWizardState } from 'stepWizard';
import { CheckInSource } from 'chat/screens/VideoCallScreen';
import { useHistory } from '@/core/routerLib';

import ClientChoosingProviders from '../assets/ClientChoosingProviders';

const ChangeProvider = () => {
  const history = useHistory();
  const {
    checkInSource,
    roomID,
    contextID,
    source,
    therapistInfo: { firstName },
  } = useWizardState<{
    checkInSource: CheckInSource;
    roomID: string;
    contextID: string;
    source: string;
    therapistInfo: { firstName: string };
  }>();
  return (
    <View align="center" style={{ padding: '0 20px' }}>
      <ClientChoosingProviders />
      <ExtraHuge style={{ marginTop: 24, textAlign: 'center' }}>
        Would you like to change
        <br /> your provider?
      </ExtraHuge>
      <SimpleQuestions
        wrapperStyle={{ marginTop: 24, maxWidth: 430 }}
        questions={[
          {
            label: 'Yes, I’d like to try someone new',
            value: 'changeProvider',
            description:
              'It’s common to try a new provider. They understand this and want what’s best for you.',
          },
          {
            label: `No, I’ll stay with ${capitalize(firstName)}`,
            value: 'keepProvider',
            description:
              'It can take a few sessions to know whether your provider is a good fit for you.',
          },
        ]}
        onPress={(selection) => {
          if (selection instanceof Array) return; // Used for single selection only, narrowing it down for Typescript

          switch (selection.value) {
            case 'keepProvider':
              history.push(`/v3/check-in/done/${checkInSource}`);
              break;
            case 'changeProvider':
              history.push(`/switch-provider/room/${roomID}`, {
                source,
                contextID,
              });
              break;
            default:
              history.push('/');
          }
        }}
      />
    </View>
  );
};

export default ChangeProvider;
