import { useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ClientChatContainer from '../containers/ClientChatContainer';
import { ChatBannerContextProvider } from '../hooks/chatBannerContext';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import useIsTeenFunnelUser from '../../TwoFactorAuthentication/hooks/useIsTeenFunnelUser';
import { getUserData } from '../../auth/helpers/token';
import { trackPageView } from '../../utils/analytics/events';

const ClientChatScreen = ({
  match,
  ...otherProps
}: RouteComponentProps<{
  roomID: number;
}>) => {
  const { memberNewNav } = useFlags();
  useEffect(() => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackPageView('messages', { roomID: match.params.roomID });
    }
  }, [memberNewNav, match.params.roomID]);

  const { id: currentUserID } = getUserData();
  const { isTeenFunnelUser } = useIsTeenFunnelUser(currentUserID);

  return (
    <ChatBannerContextProvider isTeenFunnelUser={isTeenFunnelUser}>
      <ClientChatContainer match={match} {...otherProps} />
    </ChatBannerContextProvider>
  );
};

export default ClientChatScreen;
