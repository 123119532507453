import { useCallback, useMemo, useEffect } from 'react';
import moment from 'moment';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';
import {
  View,
  SpacingView,
  getSpacing,
  spacing,
  TextDS,
  Grid,
  Row,
  Col,
  GridMarginView,
  useEmotionTheme,
  Spinner,
} from '@talkspace/react-toolkit';
import { promptPushNotificationAndRegister } from 'ts-ionic/plugins/pushNotifications';
import { useIonicEffect } from 'ts-ionic/hooks';
import { isVideoMessagingWorkshopsTherapy } from 'offer/utils/constants';
import { NAV_SHELL_MOBILE_HEADER_HEIGHT } from 'ts-frontend/constants';
import { useTranslation } from '@talkspace/i18n';
import { useHistory } from '@/core/routerLib';
import styled from '@/core/styled';
import AddPsychCard from '../components/AddPsychCard';
import YourSupportTeamListController from '../controllers/YourSupportTeamListController';
import useNavShellData, { AggregatedRoomData } from '../../hooks/useNavShellData';
import useRedirectOnboarding from '../../hooks/useRedirectOnboarding';
import SwoopBackground from '../../containers/ClientNavShell/SwoopBackground';
import ForYouSection from '../components/ForYouSection';
import { trackPageView, trackCTAClick, trackInAppReview } from '../../utils/analytics/events';
import { useCommunityState } from '../../community/hooks/CommunityContext';
import useQueryDailyPrompt from '../../community/hooks/useQueryDailyPrompt';
import DailyPromptView from '../../community/components/DailyPromptView';
import { hasTeenspaceCommunity } from '../../community/utils/helpers';
import AnnouncementsBanner from './Announcements/AnnouncementsBanner';
import { DeepLinkAction } from '../../hooks/useDeepLink';

const { space500 } = spacing;

const PaddedGridMarginView = styled(GridMarginView)(({ theme: { safeAreaInsets } }) => {
  return {
    marginTop: safeAreaInsets.top,
    padding: `${getSpacing('space400', true)} 0`,
  };
});

const HomeScreen = () => {
  const history = useHistory();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  const {
    window: { isMobile, isTablet },
  } = useEmotionTheme();
  const urlParams = new URLSearchParams(history.location.search);
  const action = urlParams.get('action');

  useEffect(() => {
    trackPageView('home');
  }, []);

  useIonicEffect(() => {
    promptPushNotificationAndRegister();
  }, []);

  const onPressAddPsych = useCallback(() => {
    trackCTAClick('Find a medication manager', 'home');
    history.push('/add-new-service?serviceType=psychiatry');
  }, [history]);

  useRedirectOnboarding();
  const appReviewCallback = useCallback(() => {
    trackInAppReview();
  }, []);
  useAppReviewCheckStorage(appReviewCallback);

  const {
    queries: { isLoadingHomePage, isLoadingRoomsList, isLoadingOnboarding },
    dataByRoomID,
    clientInfo,
    subscriptions,
    userID,
  } = useNavShellData();
  const { firstName } = clientInfo || {};

  const { replyCount, isLoading: isCommunityStateLoading } = useCommunityState();
  const shouldShowTeenspaceFeatures = subscriptions && hasTeenspaceCommunity(subscriptions);
  const { data: dailyPromptData, isLoading: isLoadingDailyPrompt } = useQueryDailyPrompt({
    clientUserID: userID,
    disabled: !shouldShowTeenspaceFeatures,
  });
  const dailyPromptSentence = dailyPromptData?.text;
  const showLiveWorkshopsCard = useMemo(
    () =>
      Object.values(dataByRoomID || {})
        .filter(Boolean)
        .some((roomData: AggregatedRoomData) => {
          const planID = roomData.subscription?.subscription?.planID;
          return (
            roomData.room?.accountType === 'dte' ||
            (planID && isVideoMessagingWorkshopsTherapy(planID))
          );
        }),
    [dataByRoomID]
  );

  const showAddPsychCard = useMemo(() => {
    const { dateOfBirth } = clientInfo || {};
    const isOlderThan18 =
      dateOfBirth && moment().subtract(18, 'years').isAfter(clientInfo?.dateOfBirth);

    const hasPsych = Object.values(dataByRoomID || {})
      .filter(Boolean)
      .some((roomData: AggregatedRoomData) => roomData.room?.roomType === 'psychiatry_room');
    return isOlderThan18 && !hasPsych;
  }, [clientInfo, dataByRoomID]);
  const isLoading = isLoadingHomePage || isLoadingRoomsList || isLoadingOnboarding;

  if (action === DeepLinkAction.AFTER_LOGIN || isLoadingOnboarding) {
    return (
      <View align="center" justify="center" style={{ height: '100vh' }}>
        <Spinner />
      </View>
    );
  }

  return (
    <View>
      <SwoopBackground isHeader={isMobile}>
        <PaddedGridMarginView>
          <SpacingView>
            <TextDS variant="heading2xl" tabIndex={0}>
              {tnewMemberNav('home.welcome', 'Welcome')}
              {isLoadingHomePage ? '' : `, ${firstName}`}
            </TextDS>
            <TextDS colorRole="textSubtle" variant="body">
              {tnewMemberNav('home.were', 'We’re so glad you’re here')}
            </TextDS>
          </SpacingView>
        </PaddedGridMarginView>
      </SwoopBackground>
      <Grid fluid rowSpacing="space400">
        <Row
          style={{
            marginBottom: space500,
            ...(isMobile && { marginTop: -NAV_SHELL_MOBILE_HEADER_HEIGHT }),
          }}
        >
          {!isCommunityStateLoading && shouldShowTeenspaceFeatures && !isLoadingDailyPrompt && (
            <Col sm={8} lg={8}>
              <SpacingView itemSpacing="space150">
                <TextDS variant="headingLg" tabIndex={0}>
                  Teenspace community
                </TextDS>
                <DailyPromptView sentence={dailyPromptSentence} replyCount={replyCount} />
              </SpacingView>
            </Col>
          )}
          {!isMobile && !isTablet && shouldShowTeenspaceFeatures ? (
            <ForYouSection showLiveWorkshopsCard={showLiveWorkshopsCard} isLoading={isLoading} />
          ) : null}
          <AnnouncementsBanner userID={userID} isMobile={isMobile} />
          <Col sm={8} lg={8}>
            <SpacingView itemSpacing="space200">
              <TextDS variant="headingLg" tabIndex={0}>
                {tnewMemberNav('home.your', 'Your support team')}
              </TextDS>
              <YourSupportTeamListController />
              {!isLoading && showAddPsychCard && (
                <AddPsychCard onPress={onPressAddPsych} dataQa="homePageCardLiveAddPsych" />
              )}
            </SpacingView>
          </Col>
          {!isMobile && !isTablet && !shouldShowTeenspaceFeatures ? (
            <ForYouSection showLiveWorkshopsCard={showLiveWorkshopsCard} isLoading={isLoading} />
          ) : null}
          {isMobile || isTablet ? (
            <ForYouSection showLiveWorkshopsCard={showLiveWorkshopsCard} isLoading={isLoading} />
          ) : null}
        </Row>
      </Grid>
    </View>
  );
};
export default HomeScreen;
