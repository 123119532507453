import { Dispatch, SetStateAction, useState } from 'react';
import {
  Card,
  View,
  TextDS,
  SpacingView,
  StatusTag,
  UnorderedListDS,
  ColorRolesVersion,
  TSAvatar,
  useEmotionTheme,
  BaseButton,
  RecommendedBadge,
  TouchableView,
} from '@talkspace/react-toolkit';
import { PageName } from 'ts-analytics/types';
import { useTranslation } from '@talkspace/i18n';
import styled from '@/core/styled';
import { RoomType } from '@/entities/Room';
import { MatchResult } from '../../therapistMatches/matchingTypes';
import MatchPreferenceRow from './MatchPreferenceRow';
import { MatchPreferenceReason } from '@/types';
import StickyFooterButton from './StickyFooterButton';
import { trackCTAClick } from '@/utils/analytics/events';

const TrimmedText = styled(TextDS)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 80px;
  -webkit-line-clamp: 2;
`;

const ClickableCardTopWrapper = styled(TouchableView)(() => {
  return {
    width: '100%',
  };
});

const BioTextWrapper = styled(View)(({ theme: { spacing } }) => {
  return {
    paddingTop: spacing('space050'),
  };
});

const getAvatarProps = (colorRoles: ColorRolesVersion, roomType?: RoomType) => {
  if (roomType === 'psychiatry_room')
    return {
      backgroundColor: colorRoles.surfaces.decorativePsych,
    };
  if (roomType === 'couples_room')
    return {
      backgroundColor: colorRoles.surfaces.decorativeCouples,
    };
  return { backgroundColor: colorRoles.surfaces.brandSubtleDefault };
};
interface TherapistMatchDirectoryCardProps {
  matchResult: MatchResult;
  matchPreferenceReasons?: MatchPreferenceReason[];
  nextAvailable?: string;
  peopleHelped?: number;
  topSpecialties: string[];
  isRecommended?: boolean;
  isHighlighted?: boolean;
  onPress?: () => void;
  isBooking?: boolean;
  isMessaging?: boolean;
  isMoreMatchesView?: boolean;
  toggleProviderProfile?: () => void;
  setProviderId?: Dispatch<SetStateAction<number>>;
  pageName: PageName;
}

const TherapistMatchDirectoryCard = (props: TherapistMatchDirectoryCardProps) => {
  const { colorRoles, spacing } = useEmotionTheme();
  const { t: tSwitchWizard } = useTranslation('switchWizard');
  const {
    matchResult: { userId, details },
    matchPreferenceReasons,
    nextAvailable,
    peopleHelped,
    topSpecialties,
    isRecommended,
    isHighlighted,
    onPress,
    isBooking,
    isMessaging,
    isMoreMatchesView,
    toggleProviderProfile,
    setProviderId,
    pageName,
  } = props;
  const therapistDetailsProps = {
    nextAvailable,
    isMessaging,
    isBooking,
    onPress,
    details,
  };
  const { image } = details;

  const providerName = [details.title, details.firstName, details.lastName]
    .filter(Boolean)
    .join(' ');

  const avatarProps = getAvatarProps(colorRoles, 'private_room');

  const [isBioOpen, setIsBioOpen] = useState(false);

  const RecommendReasonWrapper = isHighlighted ? Card : View;
  return (
    <Card surfaceRoleVariantName={isHighlighted ? 'brandSubtle' : 'subtle'}>
      <SpacingView fullWidth flex={1} itemSpacing="space200">
        <ClickableCardTopWrapper
          onPress={() => {
            setProviderId && setProviderId(userId);
            trackCTAClick('View Provider Profile', pageName, {
              providerHasAvailability: !!nextAvailable,
            });
            toggleProviderProfile && toggleProviderProfile();
          }}
        >
          <View row flex={1} style={{ paddingBottom: spacing('space200') }}>
            <View>
              <TSAvatar
                avatarColor={colorRoles.element.iconNonInteractiveDefault}
                borderColor="none"
                {...avatarProps}
                size={64}
                image={image}
                titleText={providerName}
                alt={providerName}
                badge={
                  isRecommended ? (
                    <RecommendedBadge
                      color={!isMoreMatchesView ? avatarProps.backgroundColor : ''}
                      style={{ position: 'absolute', left: 50, top: 60 }}
                    />
                  ) : undefined
                }
              />
            </View>
            <View
              style={{ paddingLeft: spacing('space200'), width: '80%', overflowWrap: 'anywhere' }}
            >
              {isRecommended && <StatusTag variant="recommendedMatch" />}
              <TextDS variant="decorative/headingLg">{providerName}</TextDS>
              {peopleHelped && peopleHelped > 30 ? (
                <TextDS variant="bodyXs">
                  {peopleHelped} {tSwitchWizard('MatchResults.peopleHelped', 'people helped')}
                </TextDS>
              ) : null}
            </View>
          </View>
          {matchPreferenceReasons && matchPreferenceReasons?.length ? (
            <View row flex={1}>
              <RecommendReasonWrapper
                surfaceRoleVariantName={isHighlighted ? 'subtle' : undefined}
                flex={1}
              >
                <TextDS variant="headingSm">
                  {tSwitchWizard(
                    'MatchResults.weRecommend',
                    `We recommend ${details.firstName} because they:`,
                    { firstName: details.firstName }
                  )}
                </TextDS>
                <UnorderedListDS>
                  {matchPreferenceReasons.map((reason) => (
                    <MatchPreferenceRow
                      preferenceName={reason.matchPreferenceName}
                      value={reason.value}
                    />
                  ))}
                </UnorderedListDS>
              </RecommendReasonWrapper>
            </View>
          ) : (
            <View row flex={1}>
              <RecommendReasonWrapper surfaceRoleVariantName={isHighlighted ? 'subtle' : undefined}>
                <TextDS variant="headingSm">
                  {tSwitchWizard('MatchResults.topSpecialties', `Top specialties:`)}
                </TextDS>
                <UnorderedListDS>
                  {topSpecialties.map((specialtyText) => (
                    <TextDS variant="bodySm">{specialtyText}</TextDS>
                  ))}
                </UnorderedListDS>
              </RecommendReasonWrapper>
            </View>
          )}
        </ClickableCardTopWrapper>
        <View row flex={1}>
          <View flex={1}>
            <TextDS variant="headingSm">
              {tSwitchWizard('MatchResults.aNoteFor', `A note for ${details.firstName}:`, {
                firstName: details.firstName,
              })}
            </TextDS>
            <BioTextWrapper>
              {isBioOpen ? (
                <TextDS variant="bodySm">{details.bio}</TextDS>
              ) : (
                <TrimmedText variant="bodySm">{details.bio}</TrimmedText>
              )}
            </BioTextWrapper>
            <View
              align="end"
              style={{ padding: `${spacing('space075', true)} ${spacing('space100', true)}` }}
            >
              <BaseButton
                dataQa="therapistMatchDirectoryCardBioOpen"
                onPress={() => {
                  setIsBioOpen(!isBioOpen);
                }}
              >
                <TextDS variant="headingSm" colorRole="buttonBrandSecondary">
                  {isBioOpen ? 'Show less' : 'Read more'}
                </TextDS>
              </BaseButton>
            </View>
          </View>
        </View>
        <StickyFooterButton {...therapistDetailsProps} pageName={pageName} />
      </SpacingView>
    </Card>
  );
};
export default TherapistMatchDirectoryCard;
