import { FunctionComponent } from 'react';
import Lottie from 'react-lottie';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { View } from '@talkspace/react-toolkit';
import loadingSpinner from '../../assets/animations/loadingSpinner.json';
import ValidateEmailError from './ValidateEmailError';
import EmailVerified from './EmailVerified';

const ValidateEmail: FunctionComponent<{
  token: string | null;
  isLoading: boolean;
  error: string;
  emailVerifiedRedirect: string;
  firstName?: string;
}> = ({ token, isLoading, error, emailVerifiedRedirect, firstName }) => {
  const { emailVerificationUiCopyChanges } = useFlags();
  return (
    <>
      {token && !error && isLoading && !emailVerificationUiCopyChanges && (
        <View justify="center" align="center" style={{ paddingTop: 68, textAlign: 'center' }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingSpinner,
            }}
            height={160}
            width={160}
          />
        </View>
      )}
      {token && !isLoading && !error && emailVerifiedRedirect && (
        <EmailVerified firstName={firstName} />
      )}
      {(error || !token) && <ValidateEmailError />}
    </>
  );
};

export default ValidateEmail;
