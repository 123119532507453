import { Path, Svg } from 'svgs';

import { useEmotionTheme } from '../..';

const Email = ({ width = 49, height = 38 }) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'Email envelope';

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 49 38"
      title={titleText}
      aria-label={titleText}
      fill="none"
    >
      <Path
        d="M21.4879 38C15.2915 38 9.08618 37.8246 2.98029 37.3568C1.7999 37.2669 0.871905 36.2215 0.823466 34.9222C0.393886 23.7607 0.392612 13.0313 0.817092 3.03493C0.87318 1.71568 1.81647 0.674549 3.01216 0.6175C14.8632 0.0527192 30.4326 -0.452161 45.7522 0.668844C46.8994 0.75299 47.8312 1.75847 47.9192 3.00783C48.633 13.104 48.6891 23.8548 48.0862 34.9607C48.0161 36.27 47.0689 37.2955 45.8822 37.3454C37.9445 37.6905 29.7251 38 21.4879 38ZM2.77251 3.13762C2.35058 13.0698 2.35313 23.7322 2.77888 34.8295C2.78653 35.0107 2.9293 35.1576 3.11286 35.1718C17.2418 36.2543 31.9533 35.7566 45.807 35.1576C45.9765 35.1504 46.1218 35.0021 46.1307 34.8281C46.7286 23.8234 46.6738 13.1753 45.9663 3.18041C45.9548 3.00783 45.807 2.86807 45.6247 2.8538C30.3982 1.73993 14.8989 2.24338 3.09629 2.80531C2.92293 2.81387 2.78016 2.95934 2.77251 3.13762Z"
        fill={colorRoles.illustrations.trustTeal}
      />
      <Path
        d="M23.8805 22.4172C23.2738 22.4172 22.667 22.1976 22.1622 21.7583L1.75399 3.9463C1.32696 3.57406 1.25175 2.8852 1.58318 2.40884C1.91588 1.93249 2.53029 1.84549 2.95732 2.21773L23.3655 20.0297C23.6677 20.2936 24.0896 20.295 24.3917 20.034L45.7317 1.79842C46.1626 1.42761 46.7757 1.52316 47.1033 2.0038C47.4322 2.48443 47.3493 3.17187 46.9198 3.5384L25.5797 21.7754C25.0788 22.2033 24.4796 22.4172 23.8805 22.4172Z"
        fill={colorRoles.illustrations.trustTeal}
      />
      <Path
        d="M2.67553 36.4768C2.44226 36.4768 2.20771 36.3841 2.02033 36.1959C1.61879 35.7908 1.58565 35.0991 1.94767 34.6484L16.8695 16.0862C17.2328 15.6384 17.851 15.6013 18.2526 16.005C18.6541 16.41 18.6873 17.1017 18.3252 17.5524L3.40339 36.1146C3.20964 36.3542 2.94322 36.4768 2.67553 36.4768Z"
        fill={colorRoles.illustrations.trustTeal}
      />
      <Path
        d="M46.164 36.4782C45.89 36.4782 45.6172 36.3499 45.4247 36.1003L30.4073 16.7081C30.0529 16.2502 30.0975 15.5585 30.5054 15.1635C30.9108 14.767 31.5328 14.8155 31.8859 15.2733L46.9034 34.6655C47.2577 35.1233 47.2131 35.815 46.8052 36.2101C46.6204 36.3898 46.3922 36.4782 46.164 36.4782Z"
        fill={colorRoles.illustrations.trustTeal}
      />
    </Svg>
  );
};

export default Email;
