import { FunctionComponent } from 'react';
import * as React from 'react';
import { View, commonStyles } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const Wrapper = styled(View)<{ removeMargin?: boolean }>(({ removeMargin }) => {
  return {
    ...commonStyles.general.layout.centered,
    alignSelf: 'center',
    flexGrow: 1,
    margin: removeMargin ? 0 : 15,
  };
});

const FullscreenWrapper: FunctionComponent<{
  style?: React.CSSProperties;
  removeMargin?: boolean;
}> = ({ children, style, removeMargin }) => (
  <Wrapper removeMargin={removeMargin} style={style}>
    {children}
  </Wrapper>
);

export default FullscreenWrapper;
