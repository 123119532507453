import { WizardScheme } from 'stepWizard';

const teenEmergencyContactScheme: WizardScheme = {
  wizardModalTitle: 'emergency contact',
  wizardVersion: 2,
  wizardType: 'emergencyContact',
  disablePersist: true,
  steps: [
    {
      title: 'Who would you like to designate as your emergency contact?',
      subtitle:
        'Your emergency contact must be at least 18 years old and, in most cases, should be your parent or guardian.',
      inputType: 'text',
      inputLabel: 'Full name',
      inputTypePlaceholder: 'Full name',
      inputState: 'contactName',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'contactName',
      backOption: 'back',
    },
    {
      title: 'What is their relationship to you?',
      inputType: 'select',
      inputState: 'relationship',
      inputOptions: 'relationshipOptions',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'contactName',
      name: 'relationship',
      backOption: 'back',
    },
    {
      title: 'What is your emergency contact’s phone number?',
      inputType: 'phone',
      inputState: 'contactPhone',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      displayCondition: 'contactName',
      name: 'contactPhone',
      backOption: 'back',
      validationRequired: true,
    },
    {
      title: 'What is your full name?',
      inputType: 'form',
      inputState: 'name',
      inputFields: [
        {
          inputType: 'text',
          inputState: 'firstName',
          inputLabel: 'First name',
          inputTypePlaceholder: 'First Name',
          validationRequired: true,
        },
        {
          inputType: 'text',
          inputState: 'middleName',
          inputLabel: 'Middle name',
          inputTypePlaceholder: 'Middle Name',
          validationRequired: false,
        },
        {
          inputType: 'text',
          inputState: 'lastName',
          inputLabel: 'Last name',
          inputTypePlaceholder: 'Last Name',
          validationRequired: true,
        },
      ],
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      backOption: 'back',
      name: 'name',
    },
    {
      title: 'What is your date of birth?',
      inputType: 'date',
      inputState: 'dateOfBirth',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      validationOptions: {
        minYearsFromNow: -120,
        maxYearsFromNow: -13, // NOTE: minimum age: 13
      },
      name: 'Date of birth',
      backOption: 'back',
    },
    {
      title: 'What is your address?',
      inputType: 'address',
      inputState: 'address',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'address',
      backOption: 'back',
    },
    {
      title: 'What is your phone number?',
      inputType: 'phone',
      inputTypePlaceholder: '(123) 123-1234',
      inputState: 'phone',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        defaultValue: null,
      },
      name: 'phone',
      backOption: 'back',
    },
    {
      title: 'Which race or ethnicity do you identify with?',
      inputType: 'select',
      inputState: 'raceOrEthnicity',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      inputOptions: 'raceOrEthnicityOptions',
      name: 'raceOrEthnicity',
      backOption: 'back',
      inputTypePlaceholder: 'Select',
    },
    {
      title: 'What is your gender identity?',
      inputType: 'select',
      inputOptions: 'genderOptions',
      inputState: 'gender',
      name: 'gender',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      backOption: 'back',
    },
    {
      title: 'What school do you attend?',
      subtitle: 'We’ll never share information about your therapy with your school',
      inputType: 'school',
      inputState: 'school',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: 'next',
        defaultValue: null,
      },
      name: 'school',
      backOption: 'back',
    },
    {
      title: 'What is your graduation year?',
      subtitle: 'We’ll never share information about your therapy with your school',
      inputType: 'year',
      inputTypePlaceholder: 'Graduation year',
      inputState: 'graduationYear',
      name: 'graduationYear',
      validationRequired: true,
      validationOptions: {
        minYearsFromNow: 0,
        maxYearsFromNow: 10,
      },
      nextButton: {
        displayText: 'Continue',
        whenVisible: 'onSelect',
        actionDispatch: (_, wizardContext) => {
          if (wizardContext.source === 'onboarding') {
            return `/room/${wizardContext.roomID}/onboarding/teen-emergency-contact/source/onboarding/submit`;
          }
          return '/teen-emergency-contact-wizard/submit';
        },
      },
      secondaryButton: {
        displayText: 'Skip',
        actionDispatch: (_, wizardContext) => {
          if (wizardContext.source === 'onboarding') {
            return `/room/${wizardContext.roomID}/onboarding/teen-emergency-contact/source/onboarding/submit`;
          }
          return '/teen-emergency-contact-wizard/submit';
        },
        whenVisible: 'hideOnSelect',
        defaultValue: null,
      },
      backOption: 'back',
    },
  ],
};

export default teenEmergencyContactScheme;
