import { Dispatch, createContext, useContext, useReducer } from 'react';
import { MatchResult } from '../matchingTypes';

interface MatchResultsState {
  matchResults?: MatchResult[];
  isLoading: boolean;
  isProviderProfileOpen: boolean;
}

const initialState: MatchResultsState = {
  isLoading: true,
  isProviderProfileOpen: false,
};

const StateContext = createContext<MatchResultsState>(initialState);
const DispatchContext = createContext<Dispatch<Partial<MatchResultsState>>>(() => initialState);

function MatchResultsReducer(currentState, newState) {
  return { ...currentState, ...newState };
}

export function MatchResultsProvider({ children }) {
  const [state, dispatch] = useReducer(MatchResultsReducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default function useMatchResults() {
  return {
    matchResultsState: useContext(StateContext),
    setMatchResultsState: useContext(DispatchContext),
  };
}
