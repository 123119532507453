/* eslint-disable react-hooks/exhaustive-deps */
import {
  LineItem,
  ValidatedCoupon,
  TherapistTimeslot,
  Booking,
  CreditMinutes,
  VideoCreditOffer,
  LiveSessionModality,
  ProviderCancellationReasonEnum,
  ClientCancellationReasonEnum,
  RepeatingPeriodValue,
  ClientUsageStats,
} from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  calculateTotals,
  generateConsumerLineItems,
  generateEligibilityLineItems,
} from 'ts-frontend/helpers/billingUtils';
import { ERoom } from 'ts-frontend/entities/Room';
import { useRef, useCallback, createContext, useContext, useEffect } from 'react';
import moment from 'moment';
import {
  useSimpleReducer,
  receiveActionPayload,
  errorActionPayload,
  requestActionPayload,
} from '@talkspace/react-toolkit';

import getParamByName from '@/utils/queryString';
import { useHistory } from '@/core/routerLib/routerLib';
import API, { ApiResponse } from '../utils/inRoomSchedulingApiHelper';
import {
  InRoomSchedulingState,
  TimeslotByDay,
  InRoomSchedulingAction,
  CancelBookingParams,
  CreateBookingParams,
  CreateRecurringBookingParams,
  TherapistInfo,
  PurchaseParams,
  TimeSlotRange,
  TherapistTimeslotsAPIResponse,
  TimeslotsByDayByBookingDuration,
  TimeslotsByBookingDuration,
  ConfirmAsyncSessionParams,
  BookingAction,
  ProgressNoteDetailsParams,
} from '../types';
import { CONFIRMATION_WINDOW_HOURS } from '../utils/constants';
import { getTentativeRepeatingBookings } from './useChangePrimaryBookingPathParam';
import { InvokeSource, VideoCreditOffersResponse } from './useQueryVideoCreditOffers';

const defaultCreditMinutes = 30;
const sixMonthsInDays = 31 * 6;
const fourMonthsInDays = 31 * 4;
const maxInitialTimeslotsFetchDays = 31;

export interface InRoomSchedulingActions {
  dispatchSetRoomAndTherapistInfo: (room: ERoom, therapistInfo?: TherapistInfo) => void;
  dispatchGetBookings: (roomID: number) => Promise<void>;
  dispatchGetVideoCreditOffers: (
    roomID: number,
    options?: {
      ignoreCredits?: boolean;
      source?: InvokeSource;
      skipModality?: boolean;
    }
  ) => Promise<void>;
  dispatchGetInitialTimeSlots: (
    isTherapist: boolean,
    isBookingAndActivate?: boolean
  ) => Promise<void>;
  dispatchGetRemainingTimeSlots: (
    isTherapist: boolean,
    isBookingAndActivate?: boolean
  ) => Promise<void>;
  dispatchGetCheckoutData: (planID: number, isRecurringBooking?: boolean) => Promise<void>;
  dispatchCreateBooking: (
    bookingData: CreateBookingParams,
    purchaseData: PurchaseParams
  ) => Promise<void>;
  dispatchCreateRecurringBooking: (bookingData: CreateRecurringBookingParams) => Promise<void>;
  dispatchCreateAsyncSession: (data: ConfirmAsyncSessionParams) => void;
  dispatchCancelBooking: (options: {
    bookingID: string;
    data: CancelBookingParams;
    cancelBatch?: boolean;
  }) => Promise<void>;
  dispatchDeclineBooking: (options: { bookingID: string; declineBatch?: boolean }) => Promise<void>;
  dispatchDismissBooking: (bookingID: string) => Promise<void>;
  dispatchRescheduleBooking: (options: {
    bookingID: string;
    cancelData: CancelBookingParams;
    newBookingData: CreateBookingParams;
    isPurchase: boolean;
    rescheduleBatch?: boolean;
    isTherapist: boolean;
  }) => Promise<void>;
  dispatchSetSelectedBookingDuration: (selectedBookingDuration: CreditMinutes) => void;
  dispatchSetSelectedCreditOption: (selectedCreditOption: VideoCreditOffer) => void;
  dispatchSetSelectedTimeslot: (selectedTimeslot: TherapistTimeslot | undefined | null) => void;
  dispatchSetHasBreakAfterSession: (hasBreakAfterSession: boolean) => void;
  dispatchResetError: () => void;
  dispatchSetHasTimeForBreak: () => void;
  dispatchSetIsError: (error: Error, meta?: { errorTitle: string }) => void;
  dispatchSetCancelReason: (cancelReason: string) => void;
  dispatchGetSelectedBooking: (roomID: number, bookingID: string, isCancel: boolean) => void;
  dispatchGetBookingToConfirm: (
    roomID: number,
    bookingID: string,
    ignoreCreditForRecurring?: boolean
  ) => void;
  dispatchGetBookingToReschedule: (roomID: number, bookingID: string) => void;
  dispatchClientConfirmBooking: (options: {
    roomID: number;
    booking: Booking;
    isPurchase: boolean;
    creditCardToken?: string;
    confirmBatch?: boolean;
    isB2BTeen?: boolean;
  }) => void;
  dispatchSetJoinData: (
    params: Pick<InRoomSchedulingState, 'isJoin' | 'isBHAdHoc' | 'videoCallID' | 'adHocDuration'>
  ) => void;
  dispatchApplyCoupon: (couponCode: string) => void;
  dispatchResetShouldShowBookingSuccess: () => void;
  dispatchGetVideoCall: (roomID: number) => void;
  dispatchGetSubscriptionsAndCreditOptions(
    clientUserID: number,
    roomID: number,
    includePaymentDetails?: boolean
  );
  dispatchGetSubscriptions: (
    clientUserID: number,
    roomID: number,
    includePaymentDetails?: boolean
  ) => Promise<void>;
  dispatchModalityType: (type: string) => void;
  dispatchClientJoinLiveChat: (roomID: number, videoCallID: number) => void;
  dispatchGetActiveSession: (roomID: number, modality: LiveSessionModality) => void;
  dispatchReceiveGetVideoCreditOffers: (
    params: VideoCreditOffersResponse['data'],
    skipModality?: boolean
  ) => void;
  dispatchSetProgressNoteDetails: (params: ProgressNoteDetailsParams) => void;
  dispatchSetNavigateToScheduler: (eventDate: Date | null) => void;
  dispatchSetClientUsageStats: (clientUsageStatus: ClientUsageStats | undefined) => void;
  dispatchSetRepeatingPeriod: (repeatingPeriod: RepeatingPeriodValue) => void;
  dispatchSetRepeatingTimeslots: ({
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
  }: {
    recurringAvailableTimeslots?: TherapistTimeslot[] | null;
    recurringConflictingTimeslots?: TherapistTimeslot[] | null;
    recurringRequestedTimeslots?: TherapistTimeslot[] | null;
  }) => void;
  dispatchResetRepeatingTimeslots: (keepInputsState: boolean) => void;
  dispatchSetRepeatingSessions: (repeatingSessions) => void;
  dispatchSetRepeatingMonths: (repeatingMonths) => void;
  dispatchSetTimeslotsForMatch: (
    timeslots: TherapistTimeslot[],
    sessionLength: CreditMinutes
  ) => void;
}

export const StateContext = createContext<InRoomSchedulingState | undefined>(undefined);
export const ActionsContext = createContext<InRoomSchedulingActions | undefined>(undefined);

function getLocalTherapistTimeSlotData(
  timeSlotsByBookingDuration: TimeslotsByBookingDuration
): TimeslotsByBookingDuration {
  return Object.entries(timeSlotsByBookingDuration).reduce(
    (acc, [bookingDuration, timeSlotsObject]) => {
      const timeslots = timeSlotsObject.timeslots.map(({ start, end, therapists, available }) => {
        const localStart = moment(new Date(start)).format();
        const localEnd = moment(new Date(end)).format();
        return { start: localStart, end: localEnd, therapists, available };
      });
      const localTimeSlotsObject = {
        ...timeSlotsObject,
        timeslots,
      };
      acc[bookingDuration] = localTimeSlotsObject;
      return acc;
    },
    {} as TimeslotsByBookingDuration
  );
}

function isTimeslotsByBookingDurationEmpty(
  timeSlotsByBookingDuration: TimeslotsByBookingDuration
): boolean {
  let timeslots = [];
  Object.keys(timeSlotsByBookingDuration).forEach((duration) => {
    timeslots = timeslots.concat(timeSlotsByBookingDuration[duration].timeslots);
  });
  return !timeslots.length;
}

function getMinAndMaxDates(timeSlots: TherapistTimeslot[]) {
  return {
    minDate: moment(timeSlots[0].start).format('YYYY-MM-DD'),
    maxDate: moment(timeSlots[timeSlots.length - 1].start).format('YYYY-MM-DD'),
  };
}

function createEmptyTimeslotsByDay(minDate: string, maxDate: string) {
  const daysDifference = moment(maxDate).diff(minDate, 'days') + 1;
  const daysToDisplay = daysDifference + (daysDifference % 3);
  const timeslotsByDay: TimeslotByDay[] = [];

  for (let i = 0; i < daysToDisplay; i += 1) {
    timeslotsByDay.push({
      date: moment(minDate).add(i, 'days').format('YYYY-MM-DD'),
      timeslots: [],
    });
  }
  return timeslotsByDay;
}

function createTherapistTimeslotDataByDay(
  timeslotsByBookingDuration: TimeslotsByBookingDuration
): TimeslotsByDayByBookingDuration {
  return Object.entries(timeslotsByBookingDuration).reduce(
    (acc, [bookingDuration, { timeslots }]) => {
      if (!timeslots.length) return acc;
      const { minDate, maxDate } = getMinAndMaxDates(timeslots);
      const timeslotsByDay: TimeslotByDay[] = createEmptyTimeslotsByDay(minDate, maxDate);
      timeslots.forEach(({ start, end, therapists, available }) => {
        const startDate = moment(start).format('YYYY-MM-DD');
        const dayObjectWithSameDate = timeslotsByDay.find(
          (dayObject) => dayObject.date === startDate
        );
        if (dayObjectWithSameDate) {
          dayObjectWithSameDate.timeslots.push({ start, end, therapists, available });
        }
      });

      acc[bookingDuration] = timeslotsByDay;
      return acc;
    },
    {}
  );
}

function isTooLateToConfirm(booking: Booking) {
  if (
    booking.status &&
    booking.timekitBookingState &&
    ((booking.status === 'never-confirmed' && booking.timekitBookingState === 'declined') ||
      (booking.status === 'active' && booking.timekitBookingState === 'tentative'))
  ) {
    const startTime = booking.startTime && new Date(booking.startTime);
    const currentTime = new Date();
    return startTime && (currentTime.getTime() - startTime.getTime()) / 1000 > 360;
  }
  return false;
}

function getClientBookingErrorText({
  booking,
  timezone,
  bookingAction,
  isJoin = false,
}: {
  booking: Booking;
  timezone: string;
  bookingAction: BookingAction;
  isJoin?: boolean;
}) {
  const isRecurringBooking = !!booking.repeatingBookingID;

  let errorTitle = 'Something went wrong';
  let errorMessage = 'Please try again later';

  if (booking.scheduledByUserType === 'provider' && isTooLateToConfirm(booking)) {
    const startTimeMoment = moment(booking.startTime);
    const startDateString = startTimeMoment.format('MMMM D');
    const startTimeString = startTimeMoment.format('h:mmA');

    errorTitle = 'Past session start time';
    errorMessage = isRecurringBooking
      ? 'At least one session is already past its start time. If you would like to confirm or decline your other sessions, you can do so per session in the Room Details tab of your room.'
      : `This session was scheduled for ${startDateString} at ${startTimeString} ${timezone} and can no longer be confirmed or declined.`;

    return { errorTitle, errorMessage };
  }

  const hasTentativeBookingsToConfirm = !!getTentativeRepeatingBookings(booking).length;

  if (
    (booking.status === 'active' && booking.timekitBookingState === 'tentative') ||
    ((bookingAction === 'cancel' || isJoin) &&
      booking.timekitBookingState === 'confirmed' &&
      booking.status === 'active') ||
    (booking.scheduledByUserType === 'client' &&
      booking.status === 'completed' &&
      booking.timekitBookingState === 'declined') ||
    // the following case is for when the video credit on the booking has been redeemed for this call already
    (booking.scheduledByUserType === 'provider' &&
      booking.status === 'completed' &&
      booking.timekitBookingState === 'completed') ||
    // booking has been confirmed late AND marked as no-show but we still want the user to be able to join
    (isJoin &&
      booking.scheduledByUserType === 'provider' &&
      booking.status === 'client-no-show' &&
      booking.timekitBookingState === 'confirmed') ||
    // don't show error screen if first booking is canceled and there are other repeating bookings to confirm
    (booking.status === 'client-canceled' &&
      booking.timekitBookingState === 'declined' &&
      hasTentativeBookingsToConfirm)
  ) {
    return null;
  }

  const isAllConfirmed = booking?.repeatingBookings?.every(
    (b) => b.timekitBookingState === 'confirmed'
  );
  const isSomeConfirmed = booking?.repeatingBookings?.some(
    (b) => b.timekitBookingState === 'confirmed'
  );

  if (booking.status === 'active' && booking.timekitBookingState === 'confirmed') {
    if (bookingAction === 'confirm') {
      if (isRecurringBooking) {
        if (isAllConfirmed) {
          errorTitle = 'These sessions were already confirmed';
          errorMessage = 'Please be ready a few minutes before the scheduled time';
        } else {
          errorTitle = 'These sessions have already been confirmed or declined';
          errorMessage =
            'You have already confirmed and/or declined the sessions. You can still cancel any individual confirmed session for free up to 24 hours before each session starts.';
        }
      } else {
        errorTitle = 'Session has already been confirmed';
        errorMessage = "Be ready in the app a few minutes before the session's start time";
      }
    } else {
      return null;
    }
  } else if (booking.status === 'therapist-canceled') {
    if (isRecurringBooking) {
      errorTitle = 'Cancelled bookings';
      errorMessage = 'Your provider has already cancelled or rescheduled these bookings';
    } else {
      errorTitle = 'Cancelled booking';
      errorMessage = 'Your provider has already cancelled or rescheduled this booking';
    }
  } else if (booking.status === 'never-confirmed') {
    if (isRecurringBooking) {
      errorTitle = 'Cancelled booking';
      errorMessage = `These sessions were automatically cancelled because we didn't hear from you earlier. Be sure to confirm sessions within ${CONFIRMATION_WINDOW_HOURS} hours of their bookings.`;
    } else {
      errorTitle = 'Cancelled booking';
      errorMessage = `This session was automatically cancelled because we didn't hear from you earlier. Be sure to confirm sessions within ${CONFIRMATION_WINDOW_HOURS} hours of their bookings.`;
    }
  } else if (
    (booking.status === 'completed' || booking.status === 'client-canceled') &&
    (booking.timekitBookingState === 'declined' ||
      booking.timekitBookingState === 'cancel_by_customer')
  ) {
    if (isSomeConfirmed) {
      errorTitle = 'These sessions have already been confirmed or declined';
      errorMessage =
        'You have already confirmed and/or declined the sessions. You can still cancel any individual confirmed session for free up to 24 hours before each session starts.';
    } else {
      errorTitle = isRecurringBooking
        ? 'These sessions have already been declined or rescheduled'
        : 'You previously declined or rescheduled this session';
      errorMessage = "We've already let your provider know";
    }
  }

  return {
    errorMessage,
    errorTitle,
  };
}

export const REPEATING_PERIOD_VALUES: RepeatingPeriodValue[] = [
  'every-week',
  'every-other-week',
  'no-repeat',
];
const REPEATING_PERIOD_DEFAULT: RepeatingPeriodValue = 'every-week';
export const REPEATING_MONTHS_VALUES: number[] = [1, 2, 3, 4, 5, 6];
export const REPEATING_SESSIONS_VALUES: number[] = [2, 3, 4];
const REPEATING_MONTHS_DEFAULT = 3;
const REPEATING_SESSIONS_DEFAULT = 4;

export const initialState: InRoomSchedulingState = {
  schedulerMode: 'clientScheduled',
  isJoin: false,
  videoCallID: undefined,
  room: undefined,
  bookings: undefined,
  planInfo: undefined,
  lineItems: [],
  total: 0,
  savings: 0,
  therapistInfo: undefined,
  therapistTimeslotsByDay: undefined,
  timeslotsByBookingDuration: undefined,
  selectedBookingDuration: undefined,
  selectedCreditOption: undefined,
  selectedTimeslot: undefined,
  hasBreakAfterSession: false,
  selectedCancelBooking: undefined,
  selectedConfirmBooking: undefined,
  creditOptions: [],
  couponState: {
    errorMessage: undefined,
    status: 'ready',
    validatedCoupon: undefined,
  },
  shouldShowBookingSuccess: false,
  clientUsageStats: undefined,
  localTimezone: Intl
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : `(GMT ${(new Date().getTimezoneOffset() / 60) * -1})`,
  cancelReason: undefined,
  isLoading: false,
  isError: false,
  isClientConfirmError: false,
  isPermanentIneligible: false,
  errorMessage: undefined,
  errorTitle: undefined,
  isInitialTimeSlotsError: false,
  isInitialTimeSlotsLoading: false,
  isInitialTimeSlotsLoaded: false,
  isRemainingTimeSlotsError: false,
  isRemainingTimeSlotsLoading: false,
  isRemainingTimeSlotsLoaded: false,
  isB2B: undefined,
  modality: 'video',
  videoCredits: undefined,
  clientJoinedLiveChat: undefined,
  activeSession: undefined,
  messagingSession: undefined,
  isBookedMessagingSession: false,
  isClientRescheduleError: false,
  isClientSelectedBookingError: false,
  hasTimeForBreak: false,
  liveModalitiesUnavailabilityMessage: undefined,
  roomPlanID: undefined,
  isBHAdHoc: false,
  adHocDuration: undefined,
  progressNoteDetails: {
    isInCreateMode: false,
    eapSessionReport: undefined,
  },
  navigateToSchedulerEventDate: null,
  repeatingPeriod: REPEATING_PERIOD_DEFAULT,
  repeatingSessions: REPEATING_SESSIONS_DEFAULT,
  repeatingMonths: REPEATING_MONTHS_DEFAULT,
  recurringAvailableTimeslots: null,
  recurringConflictingTimeslots: null,
  recurringRequestedTimeslots: null,
  skipCreditCard: undefined,
};

function invariant(condition: unknown, message: string = 'Invariant Violation'): asserts condition {
  if (!condition) throw new Error(`inRoomScheduler - ${message}`);
}

function getSelectedCreditOptionFromLocation(creditOptions: VideoCreditOffer[]) {
  const creditID = getParamByName('creditID');
  return creditID && creditOptions.find((o) => o.id === creditID);
}

function InRoomSchedulingProvider({ children }) {
  const { repeatingSessionsFull2 } = useFlags();
  const { location } = useHistory();
  const locationSearch = location.search;

  const [state, dispatch] = useSimpleReducer<InRoomSchedulingState, InRoomSchedulingAction>(
    repeatingSessionsFull2 ? { ...initialState, repeatingSessions: 0 } : initialState
  );

  const stateRef = useRef(state);
  stateRef.current = state;

  useEffect(() => {
    if (state.isBHAdHoc) {
      dispatch({
        type: 'setSchedulerMode',
        payload: {
          schedulerMode: 'providerScheduled',
        },
      });
    }
  }, [state.isBHAdHoc]);

  useEffect(() => {
    const searchParams = new URLSearchParams(locationSearch);
    const paramPeriod = searchParams.get('repeatingPeriod') as RepeatingPeriodValue;
    const paramSessions = Number(searchParams.get('repeatingSessions'));
    const paramMonths = Number(searchParams.get('repeatingMonths'));

    const paramTimeslot = searchParams.get('timeslot');
    if (paramPeriod && REPEATING_PERIOD_VALUES.includes(paramPeriod)) {
      dispatch({
        type: 'setRepeatingPeriod',
        payload: {
          repeatingPeriod: paramPeriod,
        },
      });
    }
    if (paramSessions) {
      dispatch({
        type: 'setRepeatingSessions',
        payload: {
          repeatingSessions: paramSessions,
        },
      });
    }
    if (paramMonths && REPEATING_MONTHS_VALUES.includes(paramMonths)) {
      dispatch({
        type: 'setRepeatingMonths',
        payload: {
          repeatingMonths: paramMonths,
        },
      });
    }
    if (paramTimeslot) {
      dispatch({
        type: 'setParamStartTime',
        payload: { paramStartTime: paramTimeslot },
      });
    }
  }, [locationSearch]);

  function dispatchSetRoomAndTherapistInfo(room: ERoom, therapistInfo?: TherapistInfo) {
    dispatch({
      type: 'setRoomAndTherapistInfo',
      payload: {
        room,
        therapistInfo,
      },
    });
  }

  async function dispatchGetBookings(roomID: number): Promise<void> {
    dispatch({ type: 'requestGetBookings', payload: requestActionPayload });
    try {
      const { data: bookings } = await API.getClientBookings(roomID);
      dispatch({
        type: 'receiveGetBookings',
        payload: {
          bookings,
          ...receiveActionPayload,
        },
      });
    } catch {
      dispatch({
        type: 'setIsError',
        payload: errorActionPayload,
      });
    }
  }

  function dispatchReceiveGetVideoCreditOffers(
    {
      messagingSession,
      liveSessions,
      defaultModality,
      liveModalitiesUnavailabilityMessage,
      skipCreditCard,
    }: VideoCreditOffersResponse['data'],
    skipModality?: boolean
  ) {
    const { isBHAdHoc, isJoin, videoCallID, adHocDuration, schedulerMode, modality } =
      stateRef.current || {};

    let useDefaultModality = !!defaultModality;

    if (
      isBHAdHoc ||
      isJoin ||
      videoCallID ||
      schedulerMode === 'providerScheduled' ||
      (modality && skipModality)
    ) {
      useDefaultModality = false;
    }

    const selectedCreditOption = isBHAdHoc
      ? liveSessions.find((o) => o.creditMinutes === adHocDuration) ||
        stateRef.current.videoCall?.metadata?.offer
      : stateRef.current.selectedCreditOption ||
        getSelectedCreditOptionFromLocation(liveSessions) ||
        liveSessions?.[0];

    dispatch({
      type: 'receiveGetVideoCreditOffers',
      payload: {
        messagingSession,
        creditOptions: liveSessions,
        liveModalitiesUnavailabilityMessage,
        selectedCreditOption,
        skipCreditCard,
        ...(useDefaultModality ? { modality: defaultModality } : {}),
        ...receiveActionPayload,
      },
    });
  }

  function getErrorMessage(error): string | undefined {
    return error.status === 504 || error.message === 'Failed to fetch'
      ? 'Unable to verify eligibility at this time. Please try again later'
      : error.message;
  }

  async function dispatchGetVideoCreditOffers(
    roomID: number,
    options?: {
      ignoreCredits?: boolean;
      source?: InvokeSource;
      skipModality?: boolean;
    }
  ): Promise<void> {
    const { ignoreCredits, source, skipModality } = options || {};
    dispatch({ type: 'requestGetVideoCreditOffers', payload: requestActionPayload });
    try {
      const { data } = await API.getVideoCreditOffers(roomID, undefined, ignoreCredits, source);

      dispatchReceiveGetVideoCreditOffers(data, skipModality);
    } catch (e) {
      const isPermanentIneligible = e.message === 'user_is_permanent_ineligible';
      dispatch({
        type: 'setIsError',
        payload: {
          ...errorActionPayload,
          errorMessage: isPermanentIneligible ? 'user_ineligible' : getErrorMessage(e),
          isPermanentIneligible,
          isTrizettoError: e.data?.data?.error?.isTrizettoError,
        },
      });
    }
  }

  async function dispatchGetSubscriptionsAndCreditOptions(
    clientUserID: number,
    roomID: number,
    includePaymentDetails?: boolean
  ): Promise<void> {
    dispatch({ type: 'requestGetSubscriptions', payload: requestActionPayload });
    try {
      const { data: subscriptions } = await API.getSubscriptions(
        clientUserID,
        roomID,
        includePaymentDetails
      );
      const {
        data: { liveSessions: creditOptions, skipCreditCard },
      } = await API.getVideoCreditOffers(roomID);
      dispatch({
        type: 'receiveGetSubscriptionsAndCreditOptions',
        payload: {
          isB2B: subscriptions && subscriptions[0].subscription.isB2B,
          videoCredits: subscriptions && subscriptions[0].videoCredits,
          creditOptions,
          selectedCreditOption:
            stateRef.current.selectedCreditOption ||
            getSelectedCreditOptionFromLocation(creditOptions) ||
            (creditOptions && creditOptions[0]),
          roomPlanID: subscriptions && subscriptions[0].subscription.planID,
          skipCreditCard,
          ...receiveActionPayload,
        },
      });
    } catch (e) {
      dispatch({
        type: 'setIsError',
        payload: {
          ...errorActionPayload,
          errorMessage: getErrorMessage(e),
          isTrizettoError: e.data?.data?.error?.isTrizettoError,
        },
      });
    }
  }

  async function dispatchGetSubscriptions(
    clientUserID: number,
    roomID: number,
    includePaymentDetails?: boolean
  ): Promise<void> {
    dispatch({ type: 'requestGetSubscriptions', payload: requestActionPayload });
    try {
      const { data: subscriptions } = await API.getSubscriptions(
        clientUserID,
        roomID,
        includePaymentDetails
      );
      if (subscriptions) {
        dispatch({
          type: 'receiveGetSubscriptions',
          payload: {
            isB2B: subscriptions[0].subscription.isB2B,
            videoCredits: subscriptions[0].videoCredits,
            roomPlanID: subscriptions[0].subscription.planID,
            ...receiveActionPayload,
          },
        });
      }
    } catch (e) {
      dispatch({
        type: 'setIsError',
        payload: {
          ...errorActionPayload,
          errorMessage: Number.isNaN(Number(e.message)) && e.message,
        },
      });
    }
  }

  const getTimeSlotRange = async (
    roomID: number,
    isSessionBased: boolean,
    isTherapist: boolean,
    offsets: { maxDays?: number; getRemaining?: boolean } = {}
  ): Promise<TimeSlotRange> => {
    const numberOfMonthsInDays = repeatingSessionsFull2 ? sixMonthsInDays : fourMonthsInDays;
    let timeSlotRange: TimeSlotRange = {
      from: 2,
      to: numberOfMonthsInDays,
    };

    if (isTherapist) {
      timeSlotRange = {
        from: 0,
        to: numberOfMonthsInDays,
      };
    }

    if (offsets.maxDays && !offsets.getRemaining) {
      return {
        from: timeSlotRange.from,
        // Limit the `to` to up to `offsets.maxDays` from the `from`.
        to: timeSlotRange.from + offsets.maxDays,
      };
    }
    // Just being a bit more explicit here
    if (offsets.getRemaining && offsets.maxDays) {
      // Getting the remaining days
      return {
        // Previously used `timeSlotRange.from + offsets.maxDays`, now get the rest of the bookings with no `to` limit.
        from: timeSlotRange.from + offsets.maxDays + 1,
        to: timeSlotRange.to,
      };
    }

    return timeSlotRange;
  };

  async function dispatchGetRemainingTimeSlots(isTherapist: boolean): Promise<void> {
    // TODO: Use loadingActionPayloadPrefixed when we have template literal types
    dispatch({
      type: 'requestGetRemainingTimeSlots',
      payload: {
        isRemainingTimeSlotsError: false,
        isRemainingTimeSlotsLoading: true,
        isRemainingTimeSlotsLoaded: false,
      },
    });
    const { room, selectedCreditOption, selectedCancelBooking } = stateRef.current;
    invariant(room);
    try {
      const { therapistID, isSessionBased, roomID } = room;
      const timeSlotsRange = await getTimeSlotRange(roomID, isSessionBased, isTherapist, {
        maxDays: maxInitialTimeslotsFetchDays,
        getRemaining: true,
      });
      const creditMinutes =
        selectedCreditOption?.creditMinutes ||
        selectedCancelBooking?.creditMinutes ||
        defaultCreditMinutes;
      const bookingDuration = creditMinutes || defaultCreditMinutes;
      const durations = [bookingDuration];

      const timeSlotResponses: ApiResponse<TherapistTimeslotsAPIResponse>[] = await Promise.all(
        durations.map((duration) =>
          API.getTherapistTimeSlots(therapistID, duration, timeSlotsRange, roomID)
        )
      );

      const timeSlots = timeSlotResponses.map((r) => r.data);

      const hasTimeSlots =
        (stateRef.current.timeslotsByBookingDuration &&
          !isTimeslotsByBookingDurationEmpty(stateRef.current.timeslotsByBookingDuration)) ||
        timeSlots.some((t) => t && t.timeslots.length > 0);

      const timeslotsByBookingDuration = durations.reduce((acc, duration, index) => {
        acc[duration] = {
          ...timeSlots[index],
          timeslots: [
            ...(stateRef.current.timeslotsByBookingDuration?.[duration]?.timeslots ?? []),
            ...(timeSlots[index]?.timeslots || []),
          ],
        } as TimeslotsByBookingDuration[typeof duration];
        return acc;
      }, {} as TimeslotsByBookingDuration);

      const localTherapistTimeSlotData = hasTimeSlots
        ? getLocalTherapistTimeSlotData(timeslotsByBookingDuration)
        : undefined;

      const therapistTimeslotsByDay = localTherapistTimeSlotData
        ? createTherapistTimeslotDataByDay(localTherapistTimeSlotData)
        : {};

      const firstTimeslot = Object.keys(therapistTimeslotsByDay).length
        ? therapistTimeslotsByDay[creditMinutes][0]?.timeslots[0]
        : undefined;

      const selectedBookingDuration = firstTimeslot ? creditMinutes : undefined;

      dispatch({
        type: 'receiveGetRemainingTimeSlots',
        payload: {
          timeslotsByBookingDuration,
          therapistTimeslotsByDay,
          selectedBookingDuration:
            stateRef.current.selectedBookingDuration || selectedBookingDuration,
          isRemainingTimeSlotsLoading: false,
          isRemainingTimeSlotsError: false,
          isRemainingTimeSlotsLoaded: true,
        },
      });
    } catch (err) {
      dispatch({
        type: 'setIsRemainingTimeSlotsError',
        payload: {
          isRemainingTimeSlotsError: true,
          isRemainingTimeSlotsLoading: false,
          isRemainingTimeSlotsLoaded: true,
        },
      });
    }
  }

  async function dispatchGetInitialTimeSlots(
    isTherapist: boolean,
    isBookingAndActivate: boolean = false
  ): Promise<void> {
    dispatch({
      type: 'requestGetInitialTimeSlots',
      payload: {
        isInitialTimeSlotsError: false,
        isInitialTimeSlotsLoading: true,
        isInitialTimeSlotsLoaded: false,
      },
    });
    const { room, selectedCreditOption, selectedCancelBooking } = stateRef.current;
    invariant(room);
    try {
      const { therapistID, isSessionBased, roomID } = room;
      const timeSlotsRange = await getTimeSlotRange(roomID, isSessionBased, isTherapist, {
        maxDays: maxInitialTimeslotsFetchDays,
      });
      const creditMinutes =
        selectedCreditOption?.creditMinutes ||
        selectedCancelBooking?.creditMinutes ||
        defaultCreditMinutes;
      const bookingDuration = creditMinutes;
      const durations = [bookingDuration];

      let timeSlotResponses: ApiResponse<TherapistTimeslotsAPIResponse>[] = [];

      if (isBookingAndActivate) {
        const suggestedTherapistsTimeSlots = await Promise.all(
          durations.map((duration) =>
            API.suggestTherapistBookings({
              roomID,
              sessionLength: duration,
            })
          )
        );
        timeSlotResponses = suggestedTherapistsTimeSlots.map((it) => {
          return { data: { timeslots: it, therapistTimezone: '' } };
        });
      } else {
        timeSlotResponses = await Promise.all(
          durations.map((duration) =>
            API.getTherapistTimeSlots(therapistID, duration, timeSlotsRange, roomID)
          )
        );
      }

      const timeSlots: TherapistTimeslotsAPIResponse[] = timeSlotResponses
        .map((r) => r.data)
        .filter((r) => r !== undefined) as TherapistTimeslotsAPIResponse[];

      const hasTimeSlots = timeSlots.some((t) => t && t.timeslots.length > 0);

      const timeslotsByBookingDuration = durations.reduce((acc, duration, index) => {
        acc[duration] = timeSlots[index] as TimeslotsByBookingDuration[typeof duration];
        return acc;
      }, {} as TimeslotsByBookingDuration);

      const localTherapistTimeSlotData = hasTimeSlots
        ? getLocalTherapistTimeSlotData(timeslotsByBookingDuration)
        : undefined;

      const therapistTimeslotsByDay = localTherapistTimeSlotData
        ? createTherapistTimeslotDataByDay(localTherapistTimeSlotData)
        : {};

      const firstTimeslot = Object.keys(therapistTimeslotsByDay).length
        ? therapistTimeslotsByDay[creditMinutes][0].timeslots[0]
        : undefined;

      const selectedBookingDuration = firstTimeslot ? creditMinutes : undefined;

      dispatch({
        type: 'receiveGetInitialTimeSlots',
        payload: {
          timeslotsByBookingDuration,
          therapistTimeslotsByDay,
          hasBreakAfterSession: false,
          selectedBookingDuration:
            stateRef.current.selectedBookingDuration || selectedBookingDuration,
          isInitialTimeSlotsLoading: false,
          isInitialTimeSlotsError: false,
          isInitialTimeSlotsLoaded: true,
        },
      });
    } catch {
      dispatch({
        type: 'setIsInitialTimeSlotsError',
        payload: {
          isInitialTimeSlotsError: true,
          isInitialTimeSlotsLoading: false,
          isInitialTimeSlotsLoaded: true,
        },
      });
    }
  }

  function dispatchGetCheckoutData(planID: number, isRecurringBooking?: boolean) {
    dispatch({ type: 'requestGetCheckoutData', payload: requestActionPayload });
    const { room, selectedCreditOption, videoCall } = stateRef.current;
    invariant(room);
    invariant(selectedCreditOption);
    return API.getPlanInfo(planID)
      .then((planInfo) => {
        let lineItems: LineItem[];
        const maximumCost =
          selectedCreditOption.maximumCostOfService ||
          videoCall?.metadata?.offer?.maximumCostOfService!;
        if (room.isSessionBased) {
          lineItems = generateEligibilityLineItems({
            maximumCost,
            copayCents: Number(selectedCreditOption.price) * 100, // selectedCreditOption.price is in dollars
            currency: planInfo.billingPrice.currency,
            plural: isRecurringBooking,
          });
        } else {
          lineItems = generateConsumerLineItems(planInfo);
        }
        const { savings, total } = calculateTotals(lineItems, maximumCost);

        dispatch({
          type: 'receiveGetCheckoutData',
          payload: {
            ...receiveActionPayload,
            planInfo,
            lineItems,
            savings,
            total,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        });
      });
  }

  function dispatchApplyCoupon(couponCode: string) {
    const { selectedCreditOption, planInfo } = stateRef.current;
    if (!selectedCreditOption || !selectedCreditOption.planID || !planInfo) {
      dispatch({
        type: 'setIsError',
        payload: errorActionPayload,
      });

      return;
    }

    const { planID } = selectedCreditOption;

    dispatch({
      type: 'requestValidateCoupon',
      payload: {
        couponState: { status: 'validating', errorMessage: undefined },
      },
    });

    API.validateCoupon(couponCode, planID)
      .then((response) => {
        if (!response || !response.validCoupon) {
          const lineItems = generateConsumerLineItems(planInfo);
          dispatch({
            type: 'couponInvalid',
            payload: {
              couponState: {
                status: 'error',
                errorMessage: 'The coupon is invalid.',
              },
              lineItems,
              savings: 0,
            },
          });
          return;
        }

        const validatedCoupon: ValidatedCoupon = {
          amount: response.discountAmount,
          code: response.couponCode,
          isRecurring: false,
        };

        const lineItems = generateConsumerLineItems(planInfo, [validatedCoupon]);

        dispatch({
          type: 'couponValid',
          payload: {
            couponState: {
              status: 'valid',
              validatedCoupon,
            },
            lineItems,
            savings: response.discountAmount,
          },
        });
      })
      .catch((error) => {
        const errorMessage = Number.isInteger(+error.message)
          ? 'There was an error. Please try again.'
          : error.message;

        dispatch({
          type: 'setErrorValidateCoupon',
          payload: {
            couponState: {
              status: 'error',
              errorMessage,
            },
          },
        });
      });
  }

  function dispatchCreateAsyncSession({
    isPurchase,
    creditCardToken,
    funnelName,
  }: ConfirmAsyncSessionParams) {
    dispatch({ type: 'requestCreateAsyncSession', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);
    dispatch({
      type: 'setIsCreateAsyncSessionError',
      payload: {
        isCreateAsyncSessionError: false,
      },
    });
    API.createAsyncSession(room.roomID, { isPurchase, creditCardToken, funnelName })
      .then(() => {
        dispatch({
          type: 'receiveCreateAsyncSession',
          payload: {
            ...receiveActionPayload,
            shouldShowBookingSuccess: true,
            isBookedMessagingSession: true,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: 'setIsError',
          payload: {
            ...errorActionPayload,
            errorMessage: Number.isNaN(Number(e.message)) && e.message,
          },
        });
        dispatch({
          type: 'setIsCreateAsyncSessionError',
          payload: {
            isCreateAsyncSessionError: true,
          },
        });
      });
  }

  function dispatchCreateBooking(bookingData: CreateBookingParams, purchaseData: PurchaseParams) {
    dispatch({ type: 'requestCreateBooking', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);
    let purchaseAction = Promise.resolve();

    if (bookingData.isPurchase) {
      purchaseAction = room.isSessionBased
        ? API.purchaseBHSession(room.roomID, {
            creditToken: purchaseData.creditCardToken,
            allowFuture: true,
            bookingCreditMinutes: bookingData.creditMinutes,
          })
        : API.purchaseVideoCredit(room.roomID, {
            cardToken: purchaseData.creditCardToken,
            planID: purchaseData.planID,
            couponCode: purchaseData.couponCode,
          });
    }

    return purchaseAction
      .then(() => API.createBooking(room.roomID, { ...bookingData, isPurchase: false }))
      .then(() => {
        dispatch({
          type: 'receiveCreateBooking',
          payload: {
            ...receiveActionPayload,
            shouldShowBookingSuccess: true,
            isInitialTimeSlotsLoaded: false,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: 'setIsError',
          payload: {
            ...errorActionPayload,
            errorMessage: Number.isNaN(Number(e.message)) && e.message,
          },
        });
      });
  }

  function dispatchCreateRecurringBooking(bookingData: CreateRecurringBookingParams) {
    dispatch({ type: 'requestCreateBooking', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);

    return API.createRecurringBooking(room.roomID, bookingData)
      .then(() => {
        dispatch({
          type: 'receiveCreateBooking',
          payload: {
            ...receiveActionPayload,
            shouldShowBookingSuccess: true,
            isInitialTimeSlotsLoaded: false,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: 'setIsError',
          payload: {
            ...errorActionPayload,
            errorMessage: Number.isNaN(Number(e.message)) && e.message,
          },
        });
      });
  }

  function dispatchCancelBooking({
    bookingID,
    data,
    cancelBatch,
  }: {
    bookingID: string;
    data: CancelBookingParams;
    cancelBatch?: boolean;
  }) {
    dispatch({ type: 'requestCancelBooking', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);
    return API.cancelBooking({
      roomID: `${room.roomID}`,
      bookingID,
      data,
      isBatchMode: !!cancelBatch,
    })
      .then(() => {
        dispatch({
          type: 'receiveCancelBooking',
          payload: {
            ...receiveActionPayload,
            shouldShowBookingSuccess: false,
          },
        });
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchDeclineBooking({
    bookingID,
    declineBatch,
  }: {
    bookingID: string;
    declineBatch?: boolean;
  }) {
    dispatch({ type: 'requestDeclineBooking', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);
    return API.declineBooking({
      roomID: `${room.roomID}`,
      bookingID,
      isBatchMode: !!declineBatch,
    })
      .then(() => {
        dispatch({
          type: 'receiveDeclineBooking',
          payload: {
            ...receiveActionPayload,
          },
        });
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchDismissBooking(bookingID: string) {
    dispatch({ type: 'requestDismissBooking', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);
    return API.dismissBooking(`${room.roomID}`, bookingID)
      .then(() => {
        dispatch({
          type: 'receiveDismissBooking',
          payload: {
            ...receiveActionPayload,
          },
        });
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchRescheduleBooking({
    bookingID,
    cancelData,
    newBookingData,
    isPurchase,
    rescheduleBatch,
    isTherapist,
  }: {
    bookingID: string;
    cancelData: CancelBookingParams;
    newBookingData: CreateBookingParams;
    isPurchase: boolean;
    rescheduleBatch?: boolean;
    isTherapist: boolean;
  }) {
    dispatch({ type: 'requestCancelBooking', payload: requestActionPayload });
    const { room } = stateRef.current;
    invariant(room);
    return API.cancelBooking({
      roomID: `${room.roomID}`,
      bookingID,
      data: {
        ...cancelData,
        reason: isTherapist
          ? ProviderCancellationReasonEnum.RESCHEDULE
          : ClientCancellationReasonEnum.RESCHEDULE,
        metadata: { cancellationReason: cancelData.reason },
      },
      isBatchMode: !!rescheduleBatch,
    })
      .then(() => {
        dispatch({
          type: 'receiveCancelBooking',
          payload: {
            ...receiveActionPayload,
            shouldShowBookingSuccess: false,
          },
        });
        dispatch({
          type: 'requestCreateBooking',
          payload: requestActionPayload,
        });
        return API.createBooking(room.roomID, { ...newBookingData, isPurchase });
      })
      .then(() => {
        dispatch({
          type: 'receiveCreateBooking',
          payload: {
            ...receiveActionPayload,
            shouldShowBookingSuccess: true,
            isInitialTimeSlotsLoaded: false,
          },
        });
      })
      .catch((e) =>
        dispatch({
          type: 'setIsError',
          payload: {
            ...errorActionPayload,
            errorMessage: e.message,
          },
        })
      );
  }

  function dispatchSetSelectedBookingDuration(selectedBookingDuration) {
    dispatch({
      type: 'setSelectedBookingDuration',
      payload: { selectedBookingDuration },
    });
  }

  function dispatchSetSelectedCreditOption(selectedCreditOption: VideoCreditOffer) {
    dispatch({
      type: 'setSelectedCreditOption',
      payload: {
        selectedCreditOption,
        total: selectedCreditOption.price,
        isInitialTimeSlotsLoaded: false,
      },
    });
  }

  function dispatchSetSelectedTimeslot(selectedTimeslot) {
    if (selectedTimeslot) {
      dispatch({
        type: 'setSelectedTimeslot',
        payload: { selectedTimeslot },
      });
    } else {
      // if we set selection to null, effectively reset state
      dispatch({
        type: 'setSelectedTimeslot',
        payload: { selectedTimeslot, isInitialTimeSlotsLoaded: false },
      });
    }
  }

  function dispatchSetRepeatingPeriod(repeatingPeriod: RepeatingPeriodValue) {
    if (REPEATING_PERIOD_VALUES.includes(repeatingPeriod)) {
      dispatch({
        type: 'setRepeatingPeriod',
        payload: { repeatingPeriod },
      });
    }
  }

  function dispatchSetRepeatingTimeslots({
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
  }: {
    recurringAvailableTimeslots?: TherapistTimeslot[] | null;
    recurringConflictingTimeslots?: TherapistTimeslot[] | null;
    recurringRequestedTimeslots?: TherapistTimeslot[] | null;
  }) {
    dispatch({
      type: 'setRepeatingTimeslots',
      payload: {
        recurringAvailableTimeslots: recurringAvailableTimeslots || null,
        recurringConflictingTimeslots: recurringConflictingTimeslots || null,
        recurringRequestedTimeslots: recurringRequestedTimeslots || null,
      },
    });
  }

  function dispatchResetRepeatingTimeslots(keepInputsState: boolean) {
    dispatch({
      type: 'resetRepeatingTimeslotData',
      payload: keepInputsState
        ? {
            recurringAvailableTimeslots: null,
            recurringConflictingTimeslots: null,
            recurringRequestedTimeslots: null,
          }
        : {
            recurringAvailableTimeslots: null,
            recurringConflictingTimeslots: null,
            recurringRequestedTimeslots: null,
            repeatingPeriod: REPEATING_PERIOD_DEFAULT,
            repeatingSessions: repeatingSessionsFull2 ? 0 : REPEATING_SESSIONS_DEFAULT,
            repeatingMonths: REPEATING_MONTHS_DEFAULT,
          },
    });
  }

  function dispatchSetRepeatingSessions(repeatingSessions: number) {
    dispatch({
      type: 'setRepeatingSessions',
      payload: { repeatingSessions },
    });
  }

  function dispatchSetRepeatingMonths(repeatingMonths: number) {
    if (REPEATING_MONTHS_VALUES.includes(+repeatingMonths)) {
      dispatch({
        type: 'setRepeatingMonths',
        payload: { repeatingMonths },
      });
    }
  }

  function dispatchSetHasBreakAfterSession(hasBreakAfterSession: boolean) {
    dispatch({
      type: 'setHasBreakAfterSession',
      payload: { hasBreakAfterSession },
    });
  }

  function dispatchResetError() {
    dispatch({
      type: 'resetError',
      payload: { isError: false, isLoading: false, errorMessage: undefined },
    });
  }

  function dispatchSetIsError(error: Error, meta?: { errorTitle: string }) {
    dispatch({
      type: 'setIsError',
      payload: {
        ...errorActionPayload,
        errorMessage: error.message,
        ...meta,
      },
    });
  }

  function dispatchResetShouldShowBookingSuccess() {
    dispatch({
      type: 'resetShouldShowBookingSuccess',
      payload: { shouldShowBookingSuccess: false },
    });
  }

  function dispatchSetCancelReason(cancelReason) {
    dispatch({
      type: 'setCancelReason',
      payload: { cancelReason },
    });
  }

  function dispatchModalityType(modality) {
    dispatch({ type: 'setModalityType', payload: { modality } });
  }

  function dispatchGetSelectedBooking(roomID: number, bookingID: string, isCancel: boolean) {
    dispatch({
      type: 'requestGetSelectedBooking',
      payload: { ...requestActionPayload, isClientSelectedBookingError: false },
    });
    const { localTimezone } = stateRef.current;
    return API.getClientBooking(roomID, bookingID)
      .then(({ data: booking }) => {
        const error = getClientBookingErrorText({
          booking,
          timezone: localTimezone,
          bookingAction: isCancel ? 'cancel' : 'decline',
        });
        if (error && booking.startTime) {
          dispatch({
            type: 'setClientSelectedBookingError',
            payload: {
              ...errorActionPayload,
              isClientSelectedBookingError: true,
              selectedTimeslot: {
                start: booking.startTime,
                end: moment(booking.startTime).add(booking.creditMinutes, 'minute').toISOString(),
              },
              errorMessage: error.errorMessage,
              errorTitle: error.errorTitle,
              selectedCancelBooking: booking,
            },
          });
          return;
        }
        API.getVideoCreditOffers(roomID, bookingID)
          .then(({ data: { liveSessions: creditOptions } }) => {
            dispatch({
              type: 'receiveGetSelectedBooking',
              payload: {
                selectedCancelBooking: booking,
                schedulerMode:
                  booking.scheduledByUserType === 'provider'
                    ? 'providerScheduled'
                    : 'clientScheduled',
                selectedCreditOption: creditOptions[0],
                ...receiveActionPayload,
              },
            });
          })
          .catch((e) => {
            dispatch({
              type: 'setIsError',
              payload: {
                ...errorActionPayload,
                errorMessage: getErrorMessage(e),
                selectedCancelBooking:
                  e.message === 'user_ineligible' ||
                  e.message.includes('We are not able to locate your record with the health plan.')
                    ? booking
                    : undefined,
                isTrizettoError: e.data?.data?.error?.isTrizettoError,
              },
            });
          });
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchGetVideoCall(roomID: number) {
    const { isJoin, videoCallID, selectedConfirmBooking, isBHAdHoc, selectedCreditOption } =
      stateRef.current;
    if (isJoin && videoCallID && (selectedConfirmBooking?.videoCreditID || isBHAdHoc)) {
      dispatch({ type: 'requestGetVideoCall', payload: requestActionPayload });
      API.getVideoCall(roomID, videoCallID)
        .then((videoCall) => {
          dispatch({
            type: 'receiveVideoCall',
            payload: {
              ...receiveActionPayload,
              isClientConfirmError: false,
              videoCall,
              ...(!selectedCreditOption && videoCall.metadata?.offer
                ? { selectedCreditOption: videoCall.metadata.offer }
                : {}),
            },
          });
          dispatch({
            type: 'setModalityType',
            payload: {
              ...receiveActionPayload,
              modality: videoCall.modality,
            },
          });
        })
        .catch(() => {
          dispatch({
            type: 'setIsError',
            payload: errorActionPayload,
          });
        });
    }
  }

  function dispatchClientJoinLiveChat(roomID: number, videoCallID: number) {
    dispatch({ type: 'requestClientJoinLiveChat', payload: requestActionPayload });
    API.clientJoinLiveChat(roomID, videoCallID)
      .then(() => {
        dispatch({
          type: 'receiveClientJoinLiveChat',
          payload: {
            ...receiveActionPayload,
            clientJoinedLiveChat: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        });
      });
  }

  function dispatchGetActiveSession(roomID: number, modality: LiveSessionModality) {
    dispatch({ type: 'requestActiveSession', payload: requestActionPayload });
    API.getActiveSession(roomID, modality)
      .then((activeSession) => {
        dispatch({
          type: 'receiveActiveSession',
          payload: {
            ...receiveActionPayload,
            activeSession,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        });
      });
  }

  function dispatchGetBookingToConfirm(
    roomID: number,
    bookingID: string,
    ignoreCreditForRecurring?: boolean
  ) {
    dispatch({ type: 'requestGetBookingToConfirm', payload: requestActionPayload });
    const { isJoin, localTimezone } = stateRef.current;
    return API.getClientBooking(roomID, bookingID)
      .then(({ data: booking }) => {
        const error = getClientBookingErrorText({
          booking,
          timezone: localTimezone,
          bookingAction: 'confirm',
          isJoin,
        });
        if (error && booking.startTime) {
          dispatch({
            type: 'setClientConfirmBookingError',
            payload: {
              ...errorActionPayload,
              selectedConfirmBooking: booking,
              selectedTimeslot: {
                start: booking.startTime,
                end: moment(booking.startTime).add(booking.creditMinutes, 'minute').toISOString(),
              },
              errorMessage: error.errorMessage,
              errorTitle: error.errorTitle,
              isClientConfirmError: true,
            },
          });
          return Promise.resolve();
        }

        const ignoreCredits = ignoreCreditForRecurring && booking.repeatingBookingID;
        return API.getVideoCreditOffers(roomID, bookingID, !!ignoreCredits).then(
          ({ data: { liveSessions: creditOptions, skipCreditCard } }) => {
            if (
              creditOptions &&
              creditOptions.length > 0 &&
              booking.startTime &&
              booking.creditMinutes &&
              creditOptions.find(
                (offer) =>
                  offer.creditMinutes === booking.creditMinutes && offer.type === booking.type
              )
            ) {
              dispatch({
                type: 'receiveGetBookingToConfirm',
                payload: {
                  selectedConfirmBooking: booking,
                  selectedCreditOption: creditOptions.find(
                    (offer) =>
                      offer.creditMinutes === booking.creditMinutes && offer.type === booking.type
                  ),
                  selectedBookingDuration: booking.creditMinutes,
                  selectedTimeslot: {
                    start: booking.startTime,
                    end: moment(booking.startTime)
                      .add(booking.creditMinutes, 'minute')
                      .toISOString(),
                  },
                  schedulerMode:
                    booking.scheduledByUserType === 'provider'
                      ? 'providerScheduled'
                      : 'clientScheduled',
                  isClientConfirmError: false,
                  skipCreditCard,
                  ...receiveActionPayload,
                },
              });
            } else {
              dispatch({
                type: 'setClientConfirmBookingError',
                payload: { ...errorActionPayload, isClientConfirmError: true },
              });
            }
          }
        );
      })
      .catch((e) =>
        dispatch({
          type: 'setIsError',
          payload: { ...errorActionPayload, isTrizettoError: e.data?.data?.error?.isTrizettoError },
        })
      );
  }

  function dispatchGetBookingToReschedule(roomID: number, bookingID: string) {
    dispatch({ type: 'requestGetBookingToReschedule', payload: requestActionPayload });
    const { localTimezone } = stateRef.current;
    return API.getClientBooking(roomID, bookingID)
      .then(({ data: booking }) => {
        const error = getClientBookingErrorText({
          booking,
          timezone: localTimezone,
          bookingAction: 'reschedule',
        });
        if (error && booking.startTime) {
          dispatch({
            type: 'setClientRescheduleBookingError',
            payload: {
              ...errorActionPayload,
              isClientRescheduleError: true,
              errorMessage: error.errorMessage,
              errorTitle: error.errorTitle,
              selectedBookingToReschedule: booking,
              selectedTimeslot: {
                start: booking.startTime,
                end: moment(booking.startTime).add(booking.creditMinutes, 'minute').toISOString(),
              },
            },
          });
          return;
        }
        dispatch({
          type: 'receiveGetBookingToReschedule',
          payload: {
            selectedBookingToReschedule: booking,
            ...receiveActionPayload,
          },
        });
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchClientConfirmBooking({
    roomID,
    booking,
    isPurchase,
    creditCardToken,
    confirmBatch,
    isB2BTeen,
  }: {
    roomID: number;
    booking: Booking;
    isPurchase: boolean;
    creditCardToken?: string;
    confirmBatch?: boolean;
    isB2BTeen?: boolean;
  }) {
    const { couponState, isJoin, videoCallID } = stateRef.current;
    dispatch({ type: 'requestClientConfirmBooking', payload: requestActionPayload });
    return API.confirmBooking({
      roomID,
      bookingID: booking.id,
      isPurchase,
      couponCode: couponState.validatedCoupon?.code,
      creditCardToken,
      isBatchMode: !!confirmBatch,
    })
      .then(() => {
        if (isJoin && videoCallID) {
          API.getVideoCall(roomID, videoCallID).then((videoCall) => {
            dispatch({
              type: 'receiveVideoCall',
              payload: {
                ...receiveActionPayload,
                isClientConfirmError: false,
                videoCall,
              },
            });
            dispatch({
              type: 'setModalityType',
              payload: {
                ...receiveActionPayload,
                modality: videoCall.modality,
              },
            });
          });
        } else {
          dispatch({
            type: 'receiveClientConfirmBooking',
            payload: {
              ...receiveActionPayload,
              isClientConfirmError: false,
              shouldShowBookingSuccess: true,
            },
          });
        }
      })
      .catch((err) => {
        let errorTitle = 'Something went wrong';
        let errorMessage = 'Please try again later';

        if (err.message === '404') {
          errorTitle = 'Invalid booking';
          errorMessage =
            "Our system doesn't recognize this booking. Double check you're using the right link.";
        } else if (err.message === '400') {
          if (isB2BTeen) {
            errorTitle = 'Your monthly session is already scheduled';
            errorMessage = 'You receive one live session credit every month as part of your plan';
          }
        } else if (Number.isNaN(Number(err.message))) {
          errorMessage = err.message;
        }

        dispatch({
          type: 'setClientConfirmBookingError',
          payload: {
            ...errorActionPayload,
            errorMessage,
            errorTitle,
            isClientConfirmError: true,
          },
        });
      });
  }

  function dispatchSetJoinData({
    isJoin,
    videoCallID,
    isBHAdHoc,
    adHocDuration,
  }: Pick<InRoomSchedulingState, 'isJoin' | 'isBHAdHoc' | 'videoCallID' | 'adHocDuration'>) {
    dispatch({ type: 'setIsJoin', payload: { isJoin, videoCallID, isBHAdHoc, adHocDuration } });
  }

  function dispatchSetHasTimeForBreak() {
    const { therapistTimeslotsByDay, selectedTimeslot, selectedBookingDuration } = stateRef.current;
    if (selectedTimeslot && therapistTimeslotsByDay && selectedBookingDuration) {
      const startString = moment(selectedTimeslot.start).format('YYYY-MM-DD');
      const day = therapistTimeslotsByDay[selectedBookingDuration].find(
        (slotsByDay) => slotsByDay.date === startString
      );
      const startTimeslotToFind = moment(selectedTimeslot.start).add(15, 'minutes');
      const hasTimeForBreak = !!day?.timeslots.find((timeslot) =>
        startTimeslotToFind.isSame(timeslot.start)
      );
      dispatch({ type: 'setHasTimeForBreak', payload: { hasTimeForBreak } });
    }
  }

  function dispatchSetProgressNoteDetails({ isInCreateMode, eapSessionReport }) {
    const progressNoteDetails = {
      isInCreateMode,
      eapSessionReport,
    };
    dispatch({
      type: 'setProgressNoteDetails',
      payload: {
        progressNoteDetails,
      },
    });
  }

  function dispatchSetNavigateToScheduler(eventDate) {
    dispatch({
      type: 'setNavigateToScheduler',
      payload: {
        navigateToSchedulerEventDate: eventDate,
      },
    });
  }

  function dispatchSetClientUsageStats(clientUsageStats) {
    dispatch({
      type: 'setClientUsageStats',
      payload: {
        clientUsageStats,
      },
    });
  }

  function dispatchSetTimeslotsForMatch(
    timeslots: TherapistTimeslot[],
    sessionLength: CreditMinutes
  ) {
    const timeslotsByBookingDuration = {
      [sessionLength]: {
        timeslots,
        therapistTimezone: '',
      },
    } as TimeslotsByBookingDuration;
    const localTherapistTimeSlotData = getLocalTherapistTimeSlotData(timeslotsByBookingDuration);

    const therapistTimeslotsByDay = createTherapistTimeslotDataByDay(localTherapistTimeSlotData);
    dispatch({
      type: 'setTimeslotsForMatch',
      payload: {
        timeslotsByBookingDuration,
        therapistTimeslotsByDay,
        useMatchTimeslots: true,
      },
    });
  }
  const actions: InRoomSchedulingActions = {
    dispatchSetRoomAndTherapistInfo: useCallback(dispatchSetRoomAndTherapistInfo, []),
    dispatchGetBookings: useCallback(dispatchGetBookings, []),
    dispatchGetVideoCreditOffers: useCallback(dispatchGetVideoCreditOffers, []),
    dispatchGetInitialTimeSlots: useCallback(dispatchGetInitialTimeSlots, []),
    dispatchGetRemainingTimeSlots: useCallback(dispatchGetRemainingTimeSlots, []),
    dispatchGetCheckoutData: useCallback(dispatchGetCheckoutData, []),
    dispatchCreateBooking: useCallback(dispatchCreateBooking, []),
    dispatchCreateRecurringBooking: useCallback(dispatchCreateRecurringBooking, []),
    dispatchCreateAsyncSession: useCallback(dispatchCreateAsyncSession, []),
    dispatchCancelBooking: useCallback(dispatchCancelBooking, []),
    dispatchDismissBooking: useCallback(dispatchDismissBooking, []),
    dispatchRescheduleBooking: useCallback(dispatchRescheduleBooking, []),
    dispatchSetSelectedBookingDuration: useCallback(dispatchSetSelectedBookingDuration, []),
    dispatchSetSelectedCreditOption: useCallback(dispatchSetSelectedCreditOption, []),
    dispatchSetSelectedTimeslot: useCallback(dispatchSetSelectedTimeslot, []),
    dispatchSetRepeatingPeriod: useCallback(dispatchSetRepeatingPeriod, []),
    dispatchSetRepeatingTimeslots: useCallback(dispatchSetRepeatingTimeslots, []),
    dispatchResetRepeatingTimeslots: useCallback(dispatchResetRepeatingTimeslots, []),
    dispatchSetRepeatingSessions: useCallback(dispatchSetRepeatingSessions, []),
    dispatchSetRepeatingMonths: useCallback(dispatchSetRepeatingMonths, []),
    dispatchSetHasBreakAfterSession: useCallback(dispatchSetHasBreakAfterSession, []),
    dispatchResetError: useCallback(dispatchResetError, []),
    dispatchSetIsError: useCallback(dispatchSetIsError, []),
    dispatchSetCancelReason: useCallback(dispatchSetCancelReason, []),
    dispatchGetSelectedBooking: useCallback(dispatchGetSelectedBooking, []),
    dispatchGetBookingToConfirm: useCallback(dispatchGetBookingToConfirm, []),
    dispatchGetBookingToReschedule: useCallback(dispatchGetBookingToReschedule, []),
    dispatchClientConfirmBooking: useCallback(dispatchClientConfirmBooking, []),
    dispatchSetJoinData: useCallback(dispatchSetJoinData, []),
    dispatchApplyCoupon: useCallback(dispatchApplyCoupon, [state.selectedConfirmBooking]),
    dispatchResetShouldShowBookingSuccess: useCallback(dispatchResetShouldShowBookingSuccess, []),
    dispatchDeclineBooking: useCallback(dispatchDeclineBooking, []),
    dispatchGetVideoCall: useCallback(dispatchGetVideoCall, []),
    dispatchGetSubscriptionsAndCreditOptions: useCallback(
      dispatchGetSubscriptionsAndCreditOptions,
      []
    ),
    dispatchGetSubscriptions: useCallback(dispatchGetSubscriptions, []),
    dispatchSetHasTimeForBreak: useCallback(dispatchSetHasTimeForBreak, []),
    dispatchModalityType: useCallback(dispatchModalityType, []),
    dispatchClientJoinLiveChat: useCallback(dispatchClientJoinLiveChat, []),

    dispatchGetActiveSession: useCallback(dispatchGetActiveSession, []),

    dispatchReceiveGetVideoCreditOffers: useCallback(dispatchReceiveGetVideoCreditOffers, []),
    dispatchSetProgressNoteDetails: useCallback(dispatchSetProgressNoteDetails, []),
    dispatchSetNavigateToScheduler: useCallback(dispatchSetNavigateToScheduler, []),
    dispatchSetClientUsageStats: useCallback(dispatchSetClientUsageStats, []),
    dispatchSetTimeslotsForMatch: useCallback(dispatchSetTimeslotsForMatch, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

function useInRoomSchedulingState(): InRoomSchedulingState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('scheduling state context must be used within InRoomSchedulingProvider');
  }
  return context;
}

function useInRoomSchedulingActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('scheduling action context must be used within InRoomSchedulingProvider');
  }
  return context;
}

export { InRoomSchedulingProvider, useInRoomSchedulingState, useInRoomSchedulingActions };
