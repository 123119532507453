import { useEffect, useMemo } from 'react';
import { isApple, isAndroid } from 'ts-frontend/utils/device';
import { useHistory, useLocation } from '@/core/routerLib/routerLib';
import sessionStorage from '@/core/storage/sessionStorage';
import {
  getIsOnboardingDismissed,
  getIsOnboardingDismissedTestAccount,
} from '../onboarding/util/onboardingStorage';
import { useOpenModal, useOuterModalState } from '../utils/ModalsContextProvider';
import useNavShellData, { AggregatedRoomDataByID } from './useNavShellData';
import { DeepLinkAction } from './useDeepLink';

const ISRAEL_PLAN_ID = 1146;

const SWITCH_APP_STORAGE_KEY = 'switchAppModalViewed';

const ONBOARDING_SOURCE_PARAMS = [
  'eligibility',
  'reactivation',
  'updateCoverage',
  'mbhIneligibility',
];

type IsDismissedPerRoom = Record<
  number,
  { isOnboardingDismissedTestAccount: boolean; isOnboardingDismissed: boolean }
>;

const getOnboardingRoomID = (
  dataByRoomID: AggregatedRoomDataByID,
  isDismissedPerRoom: IsDismissedPerRoom,
  roomIDParam?: number
) =>
  Object.keys(dataByRoomID).find((roomID) => {
    if (roomIDParam && roomIDParam !== +roomID) {
      return false;
    }
    const { onboarding: onboardingForRoom } = dataByRoomID[roomID];
    if (!onboardingForRoom?.length) {
      return false;
    }
    const { isOnboardingDismissed, isOnboardingDismissedTestAccount } = isDismissedPerRoom[roomID];
    // onboarding will show up if both true:
    // 1. QA account did not dismiss it
    // 2. User did not dismiss it OR user dismissed it and there are required onboarding steps
    const showOnboarding =
      !isOnboardingDismissedTestAccount &&
      (!isOnboardingDismissed || onboardingForRoom.some(({ isRequired }) => isRequired));
    return showOnboarding;
  });

interface UseRedirectOnboardingOptions {
  roomID?: number;
  disabled?: boolean;
}

const useRedirectOnboarding = (props: UseRedirectOnboardingOptions = {}) => {
  const { roomID, disabled } = props;
  const history = useHistory();
  const location = useLocation();
  const openModal = useOpenModal();
  const { isModalOpen } = useOuterModalState();
  const {
    dataByRoomID,
    queries: { isLoadingOnboarding, isFetchingOnboarding },
  } = useNavShellData();

  const hasIsraelPlan = useMemo(() => {
    if (!dataByRoomID) return false;
    if (roomID) {
      return dataByRoomID[roomID].subscription?.subscription.planID === ISRAEL_PLAN_ID;
    }
    return Object.keys(dataByRoomID).find(
      (eachRoomID) => dataByRoomID[eachRoomID].subscription?.subscription.planID === ISRAEL_PLAN_ID
    );
  }, [dataByRoomID, roomID]);

  const isDismissedPerRoom = useMemo(
    () =>
      Object.keys(dataByRoomID || {}).reduce<
        Record<
          number,
          { isOnboardingDismissedTestAccount: boolean; isOnboardingDismissed: boolean }
        >
      >((prev, onboardingRoomID) => {
        return {
          ...prev,
          [onboardingRoomID]: {
            isOnboardingDismissedTestAccount: getIsOnboardingDismissedTestAccount(onboardingRoomID),
            isOnboardingDismissed: getIsOnboardingDismissed(onboardingRoomID),
          },
        };
      }, {}),
    [dataByRoomID]
  );

  useEffect(() => {
    if (isLoadingOnboarding || isFetchingOnboarding || !dataByRoomID || disabled || isModalOpen) {
      return;
    }
    const roomIDWithOnboarding = getOnboardingRoomID(dataByRoomID, isDismissedPerRoom, roomID);
    if (!roomIDWithOnboarding) {
      const shouldShowSwitchApp = sessionStorage.getItem(SWITCH_APP_STORAGE_KEY) !== 'true';
      if (shouldShowSwitchApp && dataByRoomID && (isAndroid || isApple) && !hasIsraelPlan) {
        const firstRoomID = Object.keys(dataByRoomID)[0];
        const planID = dataByRoomID[firstRoomID].subscription?.subscription.planID;
        sessionStorage.setItem(SWITCH_APP_STORAGE_KEY, 'true');
        openModal('/switch-app', { planID }, true, false);
      }
      return;
    }

    const { onboarding: onboardingForRoom } = dataByRoomID[roomIDWithOnboarding];
    if (!onboardingForRoom) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const sourceParam = queryParams.get('source');
    const waitForAfterLogin = queryParams.get('action') === DeepLinkAction.AFTER_LOGIN;
    const redirectToRoom = sourceParam && ONBOARDING_SOURCE_PARAMS.includes(sourceParam);

    if (waitForAfterLogin) {
      return;
    }

    history.push(
      `/room/${roomIDWithOnboarding}/onboarding${redirectToRoom ? '?redirectToRoom=true' : ''}`
    );
  }, [
    dataByRoomID,
    history,
    isLoadingOnboarding,
    isFetchingOnboarding,
    disabled,
    roomID,
    location,
    isDismissedPerRoom,
    hasIsraelPlan,
    openModal,
    isModalOpen,
  ]);
};
export default useRedirectOnboarding;
