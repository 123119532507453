import Svg, { Circle } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';
import Award from '../../designSystems/icons/Award';

export interface RecommendedBadgeProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const RecommendedBadge = ({
  color,
  width = 40,
  height = 40,
  ...otherProps
}: RecommendedBadgeProps) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <>
      <Svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        title="recommended-badge"
        aria-label="recommended-badge"
        fill="none"
        {...otherProps}
      >
        <Circle
          cx={20}
          cy={20}
          r={19}
          fill={colorRoles.surfaces.decorativeGratitudeGreen}
          stroke={color || colorRoles.icons.iconInverseDefault}
          strokeWidth={2}
        />
      </Svg>
      <div style={{ position: 'absolute', top: '64px', left: '54px' }}>
        <Award size="huge" />
      </div>
    </>
  );
};

export default RecommendedBadge;
