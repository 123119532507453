import { FunctionComponent, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  View,
  Button,
  Large,
  Spinner,
  useEmotionTheme,
  TimeslotsCalendar,
  TimeslotCarousel,
  Huge,
  BaseButton,
  Standard,
  TouchableView,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import moment, { Moment } from 'moment';
import { Location } from 'history';
import { tz as momentTZ } from 'moment-timezone';
import { useChatMessageActions } from 'chat/hooks/chatMessageContext';
import { useTSAdminConfig } from 'ts-frontend/hooks/useTSAdminConfig';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
// import useQueryClientUsageStats from 'ts-frontend/hooks/useQueryClientUsageStats';
import {
  AdminConfigName,
  FlowVariant,
  TherapistTimeslot,
  VideoCreditOffer,
  RepeatingPeriodValue,
} from 'ts-frontend/types';
import { ERoom } from 'ts-frontend/entities/Room';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { isDummyNoMatchesProvider } from 'ts-frontend/entities/Therapist';
import RecurringBookingInput from '../RecurringBookingInput';
import { trackVisitTherapistScheduler } from '@/utils/analytics/events';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import SelectTimeslotNoAvailability from './SelectTimeslotNoAvailability';
import SelectTimeslotPreferredTimes from './SelectTimeslotPreferredTimes';
import KeepUpTheGreatWorkHeading from '../KeepUpTheGreatWorkHeading';
import BookingTimeZone from '../BookingTimeZone';
import { deleteLastElementInPath } from '../../utils/url';
import getFunnelName from '../../utils/getFunnelName';
import { TimeslotByDay, TherapistInfo } from '../../types';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../hooks/inRoomSchedulingContext';
import SelectTimeslotActionButtons from './SelectTimeslotActionButtons';
import { getUserData } from '@/auth/helpers/token';
import useTimeslots from '../../hooks/useTimeslots';
import useSelectTimeslotContinuePress from '../../hooks/useSelectTimeslotContinuePress';
import getFirstAvailableDate from '../../utils/getFirstAvailableDate';
import BottomButtonContainer from '../BottomButtonContainer';
import useTherapistOpenTimes from '../../hooks/useTherapistOpenTImes';

const MAX_CALENDAR_WIDTH = 375;
const MAX_TIMESLOTS_WIDTH = 338;
const MAX_SEPARATION = 100;
const BLUR_HEIGHT = 35;
const BUTTON_HEIGHT = 50;
const MAX_SPACE_BETWEEN_BUTTON_AND_TIMESLOTS = BLUR_HEIGHT + 15;

const FooterWrapper = styled(View)({
  position: 'relative',
  maxHeight: '100%',
  margin: 'auto',
});

const FooterBorder = styled(View)(({ theme: { colors } }) => {
  return {
    borderTop: `1px solid ${colors.permaCornflowerBlueSolid}`,
    width: '375px',
    marginLeft: -18,
    paddingBottom: 21,
  };
});

const ButtonContainer = styled(View)<{
  isMobile: boolean;
  bookWithIntroSession: boolean;
  isTherapist: boolean;
  noTimesTopPadding: number;
  containerPaddingBottom: number;
}>(({ isMobile, bookWithIntroSession, isTherapist, noTimesTopPadding, containerPaddingBottom }) => {
  if (!isMobile)
    return {
      width: '100vw',
      position: 'relative',
      paddingTop: 0,
      height: 'auto',
      align: 'flex-start',
      justify: 'center',
      backgroundColor: 'white',
      zIndex: 10,
    };
  return {
    position: 'absolute',
    width: '100%',
    maxWidth: MAX_TIMESLOTS_WIDTH,
    paddingTop: 0,
    height: 'auto',
    paddingBottom: bookWithIntroSession ? containerPaddingBottom : containerPaddingBottom - 10,
    bottom: 0,
    align: 'flex-start',
    justify: 'center',
    backgroundColor: 'white',
    zIndex: 10,
  };
});
const OpenTimeslotButton = styled(TouchableView)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    return {
      marginTop: !isDesktop ? 20 : -20,
      marginBottom: 40,
      backgroundColor: colors.iceBlue,
      height: 36,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 5,
    };
  }
);

const ButtonBlueDot = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.purple,
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 8,
  };
});

export const DayAndTimezone = ({ day, timezone }: { day: moment.Moment; timezone: string }) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      justify="start"
      align="stretch"
      style={{
        top: 0,
        zIndex: 1,
        width: '100%',
        height: '4em',
        position: 'sticky',
        backgroundColor: colors.white,
      }}
    >
      <Huge>{day.format('dddd, MMM D')}</Huge>
      <BookingTimeZone timezone={momentTZ.guess()} style={{ margin: '5px 0 0 0' }} />
    </View>
  );
};

const SkipButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
    backgroundColor: colors.white,
    marginBottom: 16,
    marginTop: 21,
  };
});

interface ActionsViewProps {
  isClosingModal: boolean;
  repeatingPeriod: RepeatingPeriodValue;
  repeatingMonths: number;
  repeatingSessions: number;
  room: ERoom | undefined;
  selectedTimeslot: TherapistTimeslot | null | undefined;
  flowVariant: FlowVariant | undefined;
  handleBookLater: (optionType: string) => void;
  handleContinuePress: () => void;
  onPressTimesNotCompatible: () => void;
  onClosePress: () => void;
  isNoAvailabilityFFOn: boolean | undefined;
  dispatchSetRepeatingMonths: (repeatingMonths: number) => void;
  dispatchSetRepeatingPeriod: (repeatingPeriod: RepeatingPeriodValue) => void;
  dispatchSetRepeatingSessions: (repeatingSessions: number) => void;
  isTherapist: boolean;
  allowRecurringSessions: boolean;
  bookWithIntroSession: boolean;
  noTimesTopPadding: number;
}

const ActionsView = ({
  isTherapist,
  isClosingModal,
  allowRecurringSessions,
  repeatingPeriod,
  repeatingMonths,
  repeatingSessions,
  room,
  selectedTimeslot,
  flowVariant,
  handleBookLater,
  handleContinuePress,
  onPressTimesNotCompatible,
  onClosePress,
  isNoAvailabilityFFOn,
  dispatchSetRepeatingMonths,
  dispatchSetRepeatingPeriod,
  dispatchSetRepeatingSessions,
  bookWithIntroSession,
  noTimesTopPadding,
}: ActionsViewProps) => (
  <View>
    {allowRecurringSessions && isTherapist && (
      <RecurringBookingInput
        repeatingPeriod={repeatingPeriod}
        setRepeatingPeriod={dispatchSetRepeatingPeriod}
        repeatingMonths={repeatingMonths}
        setRepeatingMonths={dispatchSetRepeatingMonths}
        repeatingSessions={repeatingSessions}
        setRepeatingSessions={dispatchSetRepeatingSessions}
      />
    )}
    <SelectTimeslotActionButtons
      flowVariant={flowVariant}
      room={room}
      selectedTimeslot={selectedTimeslot}
      handleBookLater={handleBookLater}
      handleContinuePress={handleContinuePress}
      onPressTimesNotCompatible={onPressTimesNotCompatible}
      onClosePress={onClosePress}
      isNoAvailabilityFFOn={isNoAvailabilityFFOn}
      isClosingModal={isClosingModal}
      isTherapist={isTherapist}
      bookWithIntroSession={bookWithIntroSession}
      noTimesTopPadding={noTimesTopPadding}
    />
  </View>
);

interface SkipButtonViewProps {
  isFromCheckInWizard: boolean;
  onSkipBookNextSession: () => void;
}
const SkipButtonView = ({ isFromCheckInWizard, onSkipBookNextSession }: SkipButtonViewProps) =>
  isFromCheckInWizard ? (
    <SkipButton onPress={onSkipBookNextSession}>
      <Standard variant="standardDarkGrey">Skip</Standard>
    </SkipButton>
  ) : null;

const ButtonContainerV0 = ({
  isTherapist,
  isMobile,
  isFromCheckInWizard,
  allowRecurringSessions,
  bookWithIntroSession,
  noTimesTopPadding,
  containerPaddingBottom,
  isNoAvailabilityFFOn,
  selectedTimeslot,
  room,
  handleBookLater,
  repeatingSessions,
  repeatingPeriod,
  repeatingMonths,
  isClosingModal,
  handleContinuePress,
  onClosePress,
  onPressTimesNotCompatible,
  dispatchSetRepeatingSessions,
  dispatchSetRepeatingPeriod,
  dispatchSetRepeatingMonths,
  onSkipBookNextSession,
  flowVariant,
}: ActionsViewProps &
  SkipButtonViewProps & { isMobile: boolean; containerPaddingBottom: number }) => (
  <ButtonContainer
    isTherapist={isTherapist}
    isMobile={isMobile}
    bookWithIntroSession={bookWithIntroSession}
    noTimesTopPadding={noTimesTopPadding}
    containerPaddingBottom={containerPaddingBottom}
  >
    <FooterWrapper>
      {(isTherapist || isMobile) && <FooterBorder />}
      <ActionsView
        isTherapist={isTherapist}
        isClosingModal={isClosingModal}
        allowRecurringSessions={allowRecurringSessions}
        repeatingPeriod={repeatingPeriod}
        repeatingMonths={repeatingMonths}
        repeatingSessions={repeatingSessions}
        room={room}
        selectedTimeslot={selectedTimeslot}
        flowVariant={flowVariant}
        handleBookLater={handleBookLater}
        handleContinuePress={handleContinuePress}
        onPressTimesNotCompatible={onPressTimesNotCompatible}
        onClosePress={onClosePress}
        isNoAvailabilityFFOn={isNoAvailabilityFFOn}
        dispatchSetRepeatingMonths={dispatchSetRepeatingMonths}
        dispatchSetRepeatingPeriod={dispatchSetRepeatingPeriod}
        dispatchSetRepeatingSessions={dispatchSetRepeatingSessions}
        bookWithIntroSession={bookWithIntroSession}
        noTimesTopPadding={noTimesTopPadding}
      />
    </FooterWrapper>
    <SkipButtonView
      isFromCheckInWizard={isFromCheckInWizard}
      onSkipBookNextSession={onSkipBookNextSession}
    />
  </ButtonContainer>
);
const NextAvailableTimeslot = ({
  nextAvailableDate,
  isMobile,
  onPress,
}: {
  nextAvailableDate: moment.Moment | null;
  onPress: () => void;
  isMobile: boolean;
}) => {
  const { colors } = useEmotionTheme();
  if (!nextAvailableDate) return null;

  return (
    <View style={{ marginTop: 46 }} flex={isMobile ? undefined : 1}>
      <Button
        stretch
        size="medium"
        onPress={onPress}
        style={{
          width: 335,
          borderWidth: 1,
          borderColor: colors.periwinkleGrey,
          backgroundColor: colors.white,
        }}
      >
        <Large>
          Next available:{' '}
          <Large inline variant="largeBoldWideGreen">
            {nextAvailableDate.format('ddd, MMM D')}
          </Large>{' '}
        </Large>
      </Button>
    </View>
  );
};

const isNetworkLiveInNextDays = (timeslotsByDay: TimeslotByDay[]) => {
  // check within 3 to 10 days
  const startDate = moment().add(3, 'days');
  const endDate = moment().add(10, 'days');

  return timeslotsByDay.some((day) =>
    moment(day.date).isBetween(startDate.startOf('day'), endDate.endOf('day'))
  );
};

const getSelectedCreditFromLocation = (
  creditOptions: VideoCreditOffer[],
  location: Location
): VideoCreditOffer | undefined => {
  const creditID = new URLSearchParams(location.search).get('creditID');
  return creditOptions.find((o) => o.id === creditID);
};

const generateRandomString = () => Math.random().toString(36).substring(7);

const SelectTimeslot: FunctionComponent<
  {
    isMobile: boolean;
    isTherapist: boolean;
    therapist: TherapistInfo;
    isFromCheckInWizard: boolean;
    isFromPostLVSCheckInWizard: boolean;
    bookWithIntroSession?: boolean;
    flowVariant?: FlowVariant;
    isCreateBookingActivationError?: boolean;
    clientUserID?: number;
    onClosePress: () => void;
    onSkipBookNextSession: () => void;
    handleChooseCredit: (selectedCredit: VideoCreditOffer) => void;
    dismissOnboarding?: () => void;
  } & RouteComponentProps<
    { roomID: number },
    {},
    { roomID: number; isReschedule?: boolean; isBookingSwitch?: boolean }
  >
> = ({
  history,
  match,
  location,
  therapist,
  isMobile,
  isTherapist,
  clientUserID,
  isFromCheckInWizard,
  isFromPostLVSCheckInWizard,
  flowVariant,
  isCreateBookingActivationError,
  bookWithIntroSession = false,
  onClosePress,
  onSkipBookNextSession,
  handleChooseCredit,
  dismissOnboarding,
}) => {
  const { repeatingSessionsFull, repeatingSessionsFull2 } = useFlags();

  const isOnboarding = location.pathname.includes('/onboarding');
  const isBookingAndActivate = flowVariant === 'bookAndActivate';
  const isSwitchWizard = flowVariant === 'switchWizard';
  const noTimesTopPadding = isTherapist ? 0 : 10;
  const containerPaddingBottom = isTherapist ? 34 : 20;
  const { roomID: stateRoomID, isReschedule } = location.state || {};
  const roomID = Number(match.params.roomID) || stateRoomID;
  const { id: userID } = getUserData();
  const inRoomSchedulingState = useInRoomSchedulingState();
  const {
    creditOptions,
    selectedCreditOption,
    selectedCancelBooking,
    selectedTimeslot,
    isLoading,
    isInitialTimeSlotsLoading,
    isInitialTimeSlotsLoaded,
    isRemainingTimeSlotsLoading,
    bookings,
    modality,
    room,
    repeatingPeriod,
    repeatingMonths,
    repeatingSessions,
    room: stateRoom,
    paramStartTime,
    useMatchTimeslots,
  } = inRoomSchedulingState;
  const {
    dispatchSetSelectedTimeslot,
    dispatchGetBookings,
    dispatchGetInitialTimeSlots,
    dispatchGetRemainingTimeSlots,
    dispatchGetVideoCreditOffers,
    dispatchSetRepeatingPeriod,
    dispatchSetRepeatingSessions,
    dispatchSetRepeatingMonths,
    dispatchResetRepeatingTimeslots,
  } = useInRoomSchedulingActions();
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);
  const { data: { bookingsCount } = {} } = useQueryRoomDetails(roomID);
  const [isClosingModal, setIsClosingModal] = useState<boolean>(false);
  const [creditMinutes, setCreditMinutes] = useState<number>(0);
  const { data: isNoAvailabilityFFOn, isFetching: isNoAvailabilityFFFetching } = useTSAdminConfig(
    AdminConfigName.NO_AVAILABILITY_PROMPT
  );
  const [shouldRefetchTimeSlots, setShouldRefetchTimeSlots] = useState<boolean>(
    Boolean(isCreateBookingActivationError)
  );
  const [handledChooseCredit, setHandledChooseCredit] = useState(false);

  const isTherapyRoom =
    stateRoom?.roomType &&
    ['couples_room', 'couplesRoom', 'private_room', 'privateRoom'].includes(stateRoom?.roomType);
  const isBH = stateRoom?.accountType || businessLineData?.isBH;
  const allowRecurringSessions = !!(repeatingSessionsFull && isBH && isTherapist && isTherapyRoom);

  const funnelName = getFunnelName({
    isFromCheckInWizard,
    isFromPostLVSCheckInWizard,
    isSwitchWizard,
  });

  useEffect(() => {
    // if no creditOptions state on mount, fetch again
    if (creditOptions?.length === 0) {
      dispatchGetVideoCreditOffers(match.params.roomID, { skipModality: !!isSwitchWizard });
    }
  }, [creditOptions?.length, dispatchGetVideoCreditOffers, match.params.roomID, isSwitchWizard]);

  useEffect(() => {
    // when creditOptions fetched, update all necessary state for credit option selection
    if (creditOptions && creditOptions.length > 0) {
      const creditToSelect = getSelectedCreditFromLocation(creditOptions, location);
      if (creditToSelect && !handledChooseCredit) {
        handleChooseCredit(creditToSelect);
        setHandledChooseCredit(true);
      }
    }
  }, [creditOptions, handleChooseCredit, handledChooseCredit, location]);

  useEffect(() => {
    // Request timeslots from api in two requests
    // Initialize credit minutes
    if (
      // Never fetch for useMatchTimeslots
      !useMatchTimeslots &&
      ((selectedCreditOption &&
        (selectedCreditOption.creditMinutes !== creditMinutes || !isInitialTimeSlotsLoaded) &&
        !isInitialTimeSlotsLoading &&
        !(isReschedule && !selectedCancelBooking)) ||
        shouldRefetchTimeSlots)
    ) {
      if (selectedCreditOption) {
        setCreditMinutes(selectedCreditOption.creditMinutes);
      }
      dispatchGetBookings(roomID);
      dispatchGetInitialTimeSlots(isTherapist, isBookingAndActivate).then(() => {
        if (!isBookingAndActivate) {
          // Guarantees API order
          dispatchGetRemainingTimeSlots(isTherapist);
        }
      });
      setShouldRefetchTimeSlots(false);
    }
  }, [
    stateRoomID,
    dispatchGetBookings,
    dispatchGetInitialTimeSlots,
    dispatchGetRemainingTimeSlots,
    isTherapist,
    match.params.roomID,
    selectedCreditOption,
    selectedCancelBooking,
    roomID,
    isBookingAndActivate,
    isInitialTimeSlotsLoading,
    isInitialTimeSlotsLoaded,
    isReschedule,
    shouldRefetchTimeSlots,
    creditMinutes,
    setCreditMinutes,
    useMatchTimeslots,
  ]);

  const {
    availableTimeslots,
    timeslotDaysStrings,
    firstAvailableDate,
    nextAvailableDate,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    calendarDate,
    setCalendarDate,
    getFirstTimeslotByDate,
    getAllTimeslotsByDate,
    isTimeslotAvailable,
  } = useTimeslots({
    allowRecurringSessions: !!allowRecurringSessions,
    schedulingState: inRoomSchedulingState,
  });

  const calendarDateMonth = calendarDate?.month();

  // TODO this requirement may come back
  // for now the user will always need to choose to share chat history.
  // The original requirement was to skip the share chat history screen
  // if the user had 1 session, but we need to change it to be
  // if the user has any chat history. We'll need to add chat stats
  // to the usage-stats endpoint
  //
  // let hasCompletedSession = false;
  // const { data: clientUsageStats } = useQueryClientUsageStats({
  //   clientID: userID,
  //   therapistID: therapist.id,
  // });

  const { handleContinuePress } = useSelectTimeslotContinuePress({
    isTherapist,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    timeslotCount: availableTimeslots.length,
    hasCompletedSession: true,
    funnelName,
  });

  const [calendarKey, setCalendarKey] = useState(generateRandomString);
  const [prevCalendarDate, setPrevCalendarDate] = useState<Moment | null>(null);

  const { setFirstAvailability, hasTherapistAvailableSlot } = useTherapistOpenTimes({
    prevCalendarDate,
    setPrevCalendarDate,
    calendarDate,
    setCalendarDate,
    setCalendarKey,
    generateRandomString,
    setSelectedTimeslot: dispatchSetSelectedTimeslot,
    timeslotsByDay: availableTimeslots,
  });

  const changeCalendarKey = () => {
    setCalendarKey(generateRandomString);
  };

  const hasLiveAvailability = useMemo(
    () => isNetworkLiveInNextDays(availableTimeslots),
    [availableTimeslots]
  );

  const onNextAvailableTimeslotPress = useCallback(() => {
    // If the date is changed to a different month than displayed on the calendar through JS, the Calendar won't update
    // This makes it remount with the new props so that it shows the correct month when month changes. NYC-6021
    if (nextAvailableDate?.month() !== calendarDateMonth) changeCalendarKey();
    setCalendarDate(nextAvailableDate?.startOf('day') || null);
    const newTimeslot = nextAvailableDate && getFirstTimeslotByDate(nextAvailableDate);
    if (newTimeslot) {
      dispatchSetSelectedTimeslot(newTimeslot);
    }
  }, [
    calendarDateMonth,
    nextAvailableDate,
    dispatchSetSelectedTimeslot,
    setCalendarDate,
    getFirstTimeslotByDate,
  ]);

  useEffect(() => {
    // Keep calendar date and selected timeslot in sync
    //  - in case one is pre-selected and the other isn't
    // Check that the selected date/timeslot is still available
    if (isInitialTimeSlotsLoaded && (calendarDate || firstAvailableDate)) {
      const date = calendarDate || firstAvailableDate;
      const newTimeslot = date && getFirstTimeslotByDate(date);

      const selectedTimeslotAvailable = selectedTimeslot && isTimeslotAvailable(selectedTimeslot);

      if (!calendarDate) {
        if (selectedTimeslotAvailable) {
          setCalendarDate(moment(selectedTimeslot.start).startOf('day'));
        } else {
          setCalendarDate(firstAvailableDate);
        }
        changeCalendarKey();
      }
      if (newTimeslot && selectedTimeslot && !selectedTimeslotAvailable) {
        // If we show the select timeslot view, but selectedTimeslot is already set
        // it might be leftover state from the previous booking.
        // If so, it won't be available anymore and we should replace it with the next available slot
        dispatchSetSelectedTimeslot(newTimeslot);
        dispatchResetRepeatingTimeslots(false);
      }
    }
  }, [
    calendarDate,
    dispatchSetSelectedTimeslot,
    selectedTimeslot,
    getFirstTimeslotByDate,
    isInitialTimeSlotsLoaded,
    firstAvailableDate,
    setCalendarDate,
    isTimeslotAvailable,
    dispatchResetRepeatingTimeslots,
  ]);

  useEffect(() => {
    // Watches for changes to repeatingPeriod, repeatingMonths, and the selectedTimeslot (Date/time)
    // Recalculates our number of sessions any time these change
    if (repeatingSessionsFull2) {
      if (repeatingPeriod === 'no-repeat') {
        dispatchSetRepeatingSessions(0);
        return;
      }

      if (selectedTimeslot && repeatingMonths && repeatingPeriod) {
        const startDate = moment(selectedTimeslot?.start);
        const endDate = moment(startDate).add(repeatingMonths, 'months');

        const numDays = endDate.diff(startDate, 'days');

        let sessionCount = Math.ceil((numDays + 1) / 7);
        if (repeatingPeriod === 'every-other-week') {
          sessionCount = Math.ceil(sessionCount / 2);
        }

        dispatchSetRepeatingSessions(sessionCount);
      }
    }
  }, [
    repeatingPeriod,
    repeatingMonths,
    selectedTimeslot,
    dispatchSetRepeatingSessions,
    repeatingSessionsFull2,
  ]);

  useEffect(() => {
    // Update calendar date and month if selected timeslot changes
    if (selectedTimeslot) {
      const newDateMoment = moment(selectedTimeslot.start)?.startOf('day');
      setCalendarDate(newDateMoment || null);
      if (newDateMoment?.month() !== calendarDateMonth) changeCalendarKey();
    }
  }, [selectedTimeslot, setCalendarDate, calendarDateMonth]);

  useEffect(() => {
    // intro sessions should automatically select next available timeslot
    if (bookWithIntroSession && !calendarDate) {
      onNextAvailableTimeslotPress();
    }
  }, [calendarDate, nextAvailableDate, bookWithIntroSession, onNextAvailableTimeslotPress]);

  useEffect(() => {
    // Auto select first timeslot in the current month
    // Allow initializing via query param for timeslot start time
    if (!selectedTimeslot) {
      const firstTimeslot = firstAvailableDate && getFirstTimeslotByDate(firstAvailableDate);
      const paramStartTimeMoment = moment(paramStartTime);
      const timeslotsForParamDay = getAllTimeslotsByDate(paramStartTimeMoment);
      const paramTimeslot = timeslotsForParamDay?.find((timeslot) =>
        paramStartTimeMoment.isSame(timeslot.start)
      );

      if (paramTimeslot) {
        dispatchSetSelectedTimeslot(paramTimeslot);
        setCalendarDate(paramStartTimeMoment);
        dispatchResetRepeatingTimeslots(true);
        if (paramStartTimeMoment?.month() !== calendarDateMonth) changeCalendarKey();
      } else if (firstTimeslot) {
        dispatchSetSelectedTimeslot(firstTimeslot);
        setCalendarDate(firstAvailableDate);
        dispatchResetRepeatingTimeslots(true);
        if (firstAvailableDate?.month() !== calendarDateMonth) changeCalendarKey();
      }
    }
  }, [
    selectedTimeslot,
    firstAvailableDate,
    availableTimeslots,
    paramStartTime,
    calendarDateMonth,
    dispatchSetSelectedTimeslot,
    getFirstTimeslotByDate,
    getAllTimeslotsByDate,
    setCalendarDate,
    dispatchResetRepeatingTimeslots,
  ]);

  const timeslots = useMemo(
    () => (calendarDate ? getAllTimeslotsByDate(calendarDate) : null),
    [calendarDate, getAllTimeslotsByDate]
  );

  const initialVisibleMonth = useCallback(() => {
    const result =
      calendarDate?.clone() ||
      (selectedTimeslot && moment(selectedTimeslot?.start)) ||
      firstAvailableDate ||
      moment();
    return result;
  }, [calendarDate, firstAvailableDate, selectedTimeslot]);

  const hasTrackedVisitTherapistScheduler = useRef(false);
  const handleTrackVisitTherapistScheduler = (noAvailability: boolean) => {
    if (hasTrackedVisitTherapistScheduler.current) {
      return;
    }

    hasTrackedVisitTherapistScheduler.current = true;

    const providerData = {
      therapistName: therapist.firstName,
      licenseText: (therapist as unknown as { lastName: string })?.lastName || '',
    };
    const isDummyProvider = isDummyNoMatchesProvider(providerData);

    trackVisitTherapistScheduler(
      funnelName,
      therapist?.id,
      roomID,
      selectedCreditOption?.type,
      modality,
      isTherapist,
      noAvailability,
      availableTimeslots,
      room?.status,
      !isDummyProvider
    );
  };

  const { setPrefilledChatMessage } = useChatMessageActions();
  const closeModal = useCloseModal();

  const onPressTimesNotCompatible = () => {
    if (isOnboarding && dismissOnboarding) dismissOnboarding();

    if (flowVariant === 'switchWizard') {
      history.push(`/switch-provider/sharing-preferences`, { roomID, isBookingSwitch: false });
      return;
    }

    trackEvent(
      'Request Alternative Times',
      {
        therapistID: therapist.id,
        roomID,
        sessionLength: selectedCreditOption?.creditMinutes,
        creditType: selectedCreditOption?.type,
      },
      ['mixpanel']
    );
    const message = `Hi ${therapist.firstName}, I’d like to book my ${selectedCreditOption?.displayName}, but cannot meet during the times you have on your calendar. Are there any other times that work for you? Some times that might work for me are:`;
    setPrefilledChatMessage(message);
    setIsClosingModal(true);
    setTimeout(() => {
      closeModal({
        navigateTo: 'room',
        metadata: { roomID, additionalMetaData: { actionType: 'prefillChatMessage', message } },
      });
    }, 2000);
  };

  if (
    isClosingModal ||
    isLoading ||
    (!useMatchTimeslots && (isInitialTimeSlotsLoading || !isInitialTimeSlotsLoaded)) ||
    isNoAvailabilityFFFetching ||
    // If no availability in the first month, wait for the remaining timeslots to complete fetching
    (!firstAvailableDate && !nextAvailableDate && isRemainingTimeSlotsLoading)
  ) {
    return (
      <View flex={1} align="center" justify="center">
        <Spinner isLoading />
      </View>
    );
  }

  if (!firstAvailableDate && !nextAvailableDate && !isBookingAndActivate) {
    const selectedCredit = creditOptions && getSelectedCreditFromLocation(creditOptions, location);
    const isFirstBooking = bookingsCount === 0;
    if (isFirstBooking && selectedCredit?.type !== 'introduction') {
      return <SelectTimeslotPreferredTimes dismissOnboarding={dismissOnboarding} />;
    }

    handleTrackVisitTherapistScheduler(true);
    return (
      <SelectTimeslotNoAvailability
        roomID={roomID}
        therapist={therapist}
        selectedCredit={selectedCredit}
        dismissOnboarding={dismissOnboarding}
      />
    );
  }

  handleTrackVisitTherapistScheduler(false);

  const handleBookLater = (optionType: string) => {
    if (roomID && userID) {
      trackEvent(
        'Book Later',
        {
          actionName: 'onboardingStep',
          roomID,
          optionType,
          funnelName,
          Page: 'therapist-scheduler',
        },
        ['tsAnalytics']
      );
    }
    const baseURL = deleteLastElementInPath(match.url);
    history.push(`${baseURL}/book-later`);
  };

  const onCalendarMonthChange = (monthMoment: moment.Moment) => {
    if (selectedTimeslot) return;
    const firstTimeslot = getFirstAvailableDate(
      availableTimeslots,
      monthMoment.clone().endOf('month'),
      monthMoment.clone().startOf('month')
    );
    if (firstTimeslot) setCalendarDate(moment(firstTimeslot)?.startOf('day') || null);
  };

  const onDateChange = (d: moment.Moment | null) => {
    const newDate = d && moment(d.toISOString());
    setCalendarDate(newDate?.startOf('day') || null);
    const newTimeslot = newDate && getFirstTimeslotByDate(newDate);
    if (newTimeslot) {
      dispatchSetSelectedTimeslot(newTimeslot);
    }
  };

  if (flowVariant === 'bookAndActivate' && !hasLiveAvailability) {
    const baseURL = deleteLastElementInPath(match.url);
    history.replace(`${baseURL}/match-queue-confirmation`);
  }
  const monthCalendarDate =
    calendarDate || (selectedTimeslot ? moment(selectedTimeslot.start) : null);

  return (
    <View
      align="center"
      justify="center"
      style={{
        // Centers the content on larger screens
        height: isMobile ? undefined : '100%',
        overflowX: 'visible',
      }}
    >
      {isFromCheckInWizard && (
        <View style={{ marginBottom: isMobile ? 24 : 40 }} align="center">
          <KeepUpTheGreatWorkHeading />
        </View>
      )}
      <View
        align="start"
        row={!isMobile}
        style={{
          minWidth: isMobile ? '100%' : undefined,
          width: isMobile ? undefined : '100vw',
          maxWidth: isMobile
            ? undefined
            : MAX_CALENDAR_WIDTH + MAX_TIMESLOTS_WIDTH + MAX_SEPARATION,
          height: isMobile || bookWithIntroSession ? 'unset' : 534,
          paddingBottom: 80,
        }}
        justify="space-between"
      >
        {hasTherapistAvailableSlot && (
          <View style={{ width: '100%', alignItems: 'center' }}>
            <OpenTimeslotButton
              data-qa="growCaseloadSelectTimeslotOpenTimes"
              onPress={() => {
                setFirstAvailability();
              }}
            >
              <ButtonBlueDot />
              <View style={{ marginRight: 8, fontSize: 16 }}>open times on your calendar</View>
            </OpenTimeslotButton>
          </View>
        )}
        <View
          style={{
            maxWidth: MAX_CALENDAR_WIDTH,
            overflowX: 'hidden',
          }}
        >
          <TimeslotsCalendar
            timeslots={availableTimeslots}
            key={calendarKey}
            onNextMonthClick={onCalendarMonthChange}
            onPrevMonthClick={onCalendarMonthChange}
            containerStyle={{
              marginBottom: 34,
              overflowX: 'hidden',
            }}
            isDayHighlighted={(day) => timeslotDaysStrings.includes(day.format('MM/DD/YYYY'))}
            date={monthCalendarDate}
            // There is no way to clear date in the TimeslotsCalendar
            onDateChange={onDateChange}
            initialVisibleMonth={initialVisibleMonth}
          />
        </View>
        <View
          flex={1}
          style={{
            // Add container padding bottom again for Safari (NYC-6006)
            paddingBottom: isMobile
              ? BUTTON_HEIGHT + MAX_SPACE_BETWEEN_BUTTON_AND_TIMESLOTS + containerPaddingBottom
              : 0,
            // Remove the added padding bottom as negative margin to fix on non-safari browsers
            // This negative margin does not affect Safari, only other browsers
            marginBottom: isMobile ? -containerPaddingBottom : 0,
            alignSelf: isMobile ? 'center' : 'start',
            maxWidth: isMobile ? '90%' : MAX_TIMESLOTS_WIDTH,
            height: isMobile ? undefined : 550,
          }}
        >
          {calendarDate && (
            <>
              <DayAndTimezone day={calendarDate} timezone={momentTZ.guess()} />
              {timeslots?.length && (
                <TimeslotCarousel
                  day={calendarDate}
                  isMobile={isMobile}
                  timeslots={timeslots}
                  isTherapist={isTherapist}
                  selectedTimeslot={selectedTimeslot}
                  setSelectedTimeslot={dispatchSetSelectedTimeslot}
                  carouselContainerStyle={{
                    // Set width of carousel same as button to prevent flicker on changes
                    width: isMobile ? 335 : undefined,
                    justifyContent: isMobile ? 'center' : undefined,
                    paddingBottom: isMobile ? 0 : BLUR_HEIGHT,
                  }}
                  bookings={bookings}
                />
              )}
            </>
          )}
          {!timeslots?.length && nextAvailableDate && (
            <NextAvailableTimeslot
              isMobile={isMobile}
              nextAvailableDate={nextAvailableDate}
              onPress={onNextAvailableTimeslotPress}
            />
          )}
        </View>
      </View>
      <BottomButtonContainer
        customV0={
          <ButtonContainerV0
            room={room}
            repeatingMonths={repeatingMonths}
            repeatingPeriod={repeatingPeriod}
            repeatingSessions={repeatingSessions}
            selectedTimeslot={selectedTimeslot}
            flowVariant={flowVariant}
            handleBookLater={handleBookLater}
            handleContinuePress={handleContinuePress}
            onPressTimesNotCompatible={onPressTimesNotCompatible}
            onClosePress={onClosePress}
            isNoAvailabilityFFOn={isNoAvailabilityFFOn}
            dispatchSetRepeatingMonths={dispatchSetRepeatingMonths}
            dispatchSetRepeatingPeriod={dispatchSetRepeatingPeriod}
            dispatchSetRepeatingSessions={dispatchSetRepeatingSessions}
            isClosingModal={isClosingModal}
            isMobile={isMobile}
            isTherapist={isTherapist}
            isFromCheckInWizard={isFromCheckInWizard}
            allowRecurringSessions={allowRecurringSessions}
            bookWithIntroSession={bookWithIntroSession}
            noTimesTopPadding={noTimesTopPadding}
            containerPaddingBottom={containerPaddingBottom}
            onSkipBookNextSession={onSkipBookNextSession}
          />
        }
      >
        <ActionsView
          isTherapist={isTherapist}
          isClosingModal={isClosingModal}
          allowRecurringSessions={allowRecurringSessions}
          repeatingPeriod={repeatingPeriod}
          repeatingMonths={repeatingMonths}
          repeatingSessions={repeatingSessions}
          room={room}
          selectedTimeslot={selectedTimeslot}
          flowVariant={flowVariant}
          handleBookLater={handleBookLater}
          handleContinuePress={handleContinuePress}
          onPressTimesNotCompatible={onPressTimesNotCompatible}
          onClosePress={onClosePress}
          isNoAvailabilityFFOn={isNoAvailabilityFFOn}
          dispatchSetRepeatingMonths={dispatchSetRepeatingMonths}
          dispatchSetRepeatingPeriod={dispatchSetRepeatingPeriod}
          dispatchSetRepeatingSessions={dispatchSetRepeatingSessions}
          bookWithIntroSession={bookWithIntroSession}
          noTimesTopPadding={noTimesTopPadding}
        />
        <SkipButtonView
          isFromCheckInWizard={isFromCheckInWizard}
          onSkipBookNextSession={onSkipBookNextSession}
        />
      </BottomButtonContainer>
    </View>
  );
};

export default withRouter(SelectTimeslot);
